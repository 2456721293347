import React, { useEffect } from "react";
import { Form, Row, Col, Input, Tooltip, Button } from "antd";
import { InfoCircleOutlined, PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const ipPattern = /^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)){3}$/;

const NetworkAccessFilterFields = ({ form, filters, setFilters }) => {
  // Synchronize form values with filters state on mount and when filters change
  useEffect(() => {
    form.setFieldsValue(filters);
  }, [filters, form]);

  const handleValuesChange = (changedValues, allValues) => {
    setFilters(allValues);
  };

  return (
    <Form form={form} onValuesChange={handleValuesChange} initialValues={filters} layout="vertical">
      <Form.Item label="Network Ranges">
        <Form.List
          name={["geolocation", "ip_ranges"]}
          rules={[
            {
              validator: async (_, ip_ranges) => {
                if (!ip_ranges || ip_ranges.length === 0) {
                  return Promise.reject(new Error("At least one IP range is required."));
                }

                ip_ranges.forEach((range, index) => {
                  if (!range.start || !range.end) {
                    throw new Error(
                      `Both Start IP and End IP are required for range ${index + 1}.`
                    );
                  }

                  if (!ipPattern.test(range.start)) {
                    throw new Error(`Start IP for range ${index + 1} is invalid.`);
                  }

                  if (!ipPattern.test(range.end)) {
                    throw new Error(`End IP for range ${index + 1} is invalid.`);
                  }

                  const ipToNumber = (ip) =>
                    ip.split(".").reduce((acc, octet) => acc * 256 + parseInt(octet, 10), 0);

                  if (ipToNumber(range.end) < ipToNumber(range.start)) {
                    throw new Error(
                      `End IP must be greater than or equal to Start IP for range ${index + 1}.`
                    );
                  }
                });

                return Promise.resolve();
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Row gutter={8} key={field.key} align="middle">
                  {/* Start IP */}
                  <Col span={10}>
                    <Form.Item
                      {...field}
                      name={[field.name, "start"]}
                      fieldKey={[field.fieldKey, "start"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Start IP address.",
                        },
                        {
                          pattern: ipPattern,
                          message: "Please enter a valid IP address.",
                        },
                      ]}
                    >
                      <Input placeholder="Start IP (e.g., 192.168.1.1)" />
                    </Form.Item>
                  </Col>

                  {/* End IP */}
                  <Col span={10}>
                    <Form.Item
                      {...field}
                      name={[field.name, "end"]}
                      fieldKey={[field.fieldKey, "end"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the End IP address.",
                        },
                        {
                          pattern: ipPattern,
                          message: "Please enter a valid IP address.",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const start = getFieldValue([
                              "geolocation",
                              "ip_ranges",
                              field.name,
                              "start",
                            ]);
                            if (!value || !start) {
                              return Promise.resolve();
                            }
                            const ipToNumber = (ip) =>
                              ip
                                .split(".")
                                .reduce((acc, octet) => acc * 256 + parseInt(octet, 10), 0);
                            if (ipToNumber(value) >= ipToNumber(start)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("End IP must be greater than or equal to Start IP.")
                            );
                          },
                        }),
                      ]}
                    >
                      <Input placeholder="End IP (e.g., 192.168.1.255)" />
                    </Form.Item>
                  </Col>

                  {/* Remove Button */}
                  <Col span={4}>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        onClick={() => remove(field.name)}
                        style={{ color: "red", fontSize: "20px" }}
                      />
                    ) : null}
                  </Col>
                </Row>
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "100%" }}
                  icon={<PlusOutlined />}
                >
                  Add IP Range
                </Button>
                {/* <Form.ErrorList errors={errors} /> */}
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </Form>
  );
};

export default NetworkAccessFilterFields;
