import React, { useState, useRef, useEffect } from "react";
import { Drawer, Button, List, Avatar, Typography, Dropdown, Menu } from "antd";
import { CloseOutlined, RobotOutlined, UserOutlined } from "@ant-design/icons";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";

import {
  ChatContainer,
  MessagesContainer,
  MessageWrapper,
  MessageBubble,
  AvatarWrapper,
  TypingIndicator,
  Dot,
  Timestamp,
  OptionButton,
  OptionsContainer,
  HeaderDropdown,
} from "./AIAgent.styles";

import { DOMAIN_ID, ORG_ID, REACT_APP_AI_API_URL } from "../../../constants";

const { Text } = Typography;

const messageVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};

const AIAgent = ({ fetchPolicyData, applyFilter, setShowPolicyImpact, setImpactTable }) => {
  const [visible, setVisible] = useState(false);
  const [messages, setMessages] = useState([
    {
      key: 1,
      role: "bot",
      content: "Hi. What do you want me to do?",
      timestamp: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
    },
    {
      key: 2,
      role: "options",
      content: [
        { key: "recommend_ai", label: "Recommend AI Policies" },
        { key: "view_prev_policies", label: "View Previously Recommended Policies" },
        { key: "end_chat", label: "End Chat" },
      ],
      timestamp: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
    },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const messageId = useRef(3);
  const messagesEndRef = useRef(null);

  // Auto-scroll to bottom on new messages
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);

  const handleClearChat = () => {
    setMessages([
      {
        key: 1,
        role: "bot",
        content: "Hi, User. What do you want me to do?",
        timestamp: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      },
      {
        key: 2,
        role: "options",
        content: [
          { key: "recommend_ai", label: "Recommend AI Policies" },
          { key: "view_prev_policies", label: "View Previously Recommended Policies" },
          { key: "end_chat", label: "End Chat" },
        ],
        timestamp: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      },
    ]);
    messageId.current = 3;
  };

  // Remove the options message by key
  const removeOptionsMessage = (messageKey) => {
    setMessages((prev) => prev.filter((m) => m.key !== messageKey));
  };

  // Main user selection handler. Each "optionKey" is a branch in the conversation flow.
  const handleUserSelection = async (optionKey) => {
    const userMsg = {
      key: messageId.current++,
      role: "user",
      content: "",
      timestamp: new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
    };

    let botText = "";
    let nextOptions = [];

    switch (optionKey) {
      case "recommend_ai":
        userMsg.content = "Recommend AI Policies";
        botText = "Sure, let me fetch new AI policy recommendations for you...";
        break;

      case "view_prev_policies":
        userMsg.content = "Show me previously recommended AI Policies.";
        botText = "Sure, filtering your existing AI policies...";
        break;

      case "end_chat":
        userMsg.content = "Thanks, I'm done!";
        botText = "Happy to help! Let me know if you need anything else.";
        nextOptions = [
          { key: "recommend_ai", label: "Recommend AI Policies" },
          { key: "view_prev_policies", label: "View Previously Recommended Policies" },
          { key: "end_chat", label: "End Chat" },
        ];
        break;

      case "AD":
      case "GroupAD":
      case "endpoints":
      case "radius":
      case "database":
        userMsg.content = `Show me ${optionKey} policies.`;
        botText = "Sure, redirecting you to the policies screen...";
        break;

      default:
        userMsg.content = "I need some help, not sure what though.";
        botText = "I can recommend AI policies or show previous recommendations.";
        nextOptions = [
          { key: "recommend_ai", label: "Recommend AI Policies" },
          { key: "view_prev_policies", label: "View Previously Recommended Policies" },
          { key: "end_chat", label: "End Chat" },
        ];
        break;
    }

    // Insert the user's message first
    setMessages((prev) => [...prev, userMsg]);

    // Type out the botText
    if (botText) {
      await simulateTyping(botText);
    }

    // If user chose to "recommend_ai," call the /generate-policy endpoint
    if (optionKey === "recommend_ai") {
      setIsLoading(true);
      const policyData = await callGeneratePolicy();
      setIsLoading(false);

      if (policyData) {
        const { authlogCount, policyCount } = policyData;
        const summary = `Since your last visit, the Co-Pilot has processed ${authlogCount} authentication logs and generated ${policyCount} unique polic${
          policyCount === 1 ? "y" : "ies"
        }.\n\nWhat type of policies would you like me to review/show?`;
        await simulateTyping(summary);

        nextOptions = [
          { key: "AD", label: "Active Directory" },
          { key: "GroupAD", label: "AD Groups" },
          { key: "endpoints", label: "Endpoints" },
          { key: "radius", label: "RADIUS" },
          { key: "database", label: "Database" },
          { key: "view_prev_policies", label: "View Previously Recommended Policies" },
          { key: "end_chat", label: "End Chat" },
        ];
      } else {
        await simulateTyping("Sorry, I couldn't fetch policies right now.");
        nextOptions = [
          { key: "recommend_ai", label: "Recommend AI Policies" },
          { key: "view_prev_policies", label: "View Previously Recommended Policies" },
          { key: "end_chat", label: "End Chat" },
        ];
      }
    }

    // If user wants to "view_prev_policies," apply filter & then close drawer
    if (optionKey === "view_prev_policies") {
      if (applyFilter) {
        applyFilter("generatedBy", "AI");
        onClose();
      }
      await simulateTyping("Let me show you your previously recommended policies...");
      nextOptions = [
        { key: "recommend_ai", label: "Recommend AI Policies" },
        { key: "end_chat", label: "End Chat" },
      ];
    }

    // If user picks one of the policy types
    if (["AD", "GroupAD", "endpoints", "radius", "database"].includes(optionKey)) {
      if (applyFilter) {
        // Use the optionKey value directly for AD and GroupAD; others are transformed if needed.
        const filterKey =
          optionKey === "AD" || optionKey === "GroupAD" ? optionKey : optionKey.toLowerCase();
        applyFilter("policyType", filterKey);
        applyFilter("generatedBy", "AI");
        onClose();
      }
      nextOptions = [
        { key: "recommend_ai", label: "Recommend AI Policies" },
        { key: "view_prev_policies", label: "View Previously Recommended Policies" },
        { key: "end_chat", label: "End Chat" },
      ];
    }

    // Insert the next set of options, if any
    if (nextOptions.length > 0) {
      const optionsMsg = {
        key: messageId.current++,
        role: "options",
        content: nextOptions,
        timestamp: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      };
      setMessages((prev) => [...prev, optionsMsg]);
    }
  };

  const callGeneratePolicy = async () => {
    try {
      const payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
      };
      const url = `${REACT_APP_AI_API_URL}/api/v1/ai/generate-policy`;
      const res = await axios.post(url, payload);

      if (fetchPolicyData) fetchPolicyData();
      return res.data;
    } catch (err) {
      console.error("Error calling generate-policy:", err);
      return null;
    }
  };

  const simulateTyping = (fullText) => {
    return new Promise((resolve) => {
      if (typeof fullText === "string") {
        let currentText = "";
        const letters = fullText.split("");
        let index = 0;

        const updateBotMessage = (text) => {
          setMessages((prev) => {
            const lastMsg = prev[prev.length - 1];
            if (lastMsg && lastMsg.role === "bot") {
              return [
                ...prev.slice(0, -1),
                {
                  ...lastMsg,
                  content: text,
                },
              ];
            } else {
              return [
                ...prev,
                {
                  key: messageId.current++,
                  role: "bot",
                  content: text,
                  timestamp: new Date().toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  }),
                },
              ];
            }
          });
        };

        const typeNextLetter = () => {
          if (index < letters.length) {
            currentText += letters[index];
            updateBotMessage(currentText);
            index++;
            const delay = Math.random() * (50 - 25) + 25;
            setTimeout(typeNextLetter, delay);
          } else {
            resolve();
          }
        };
        typeNextLetter();
      } else {
        setMessages((prev) => [
          ...prev,
          {
            key: messageId.current++,
            role: "bot",
            content: fullText,
            timestamp: new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
          },
        ]);
        resolve();
      }
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key="clear_chat" onClick={handleClearChat}>
        Clear Chat
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Button
        onClick={showDrawer}
        style={{
          background: "linear-gradient(90deg, #379fff, #645cff)",
          color: "#fff",
          marginRight: "1rem",
        }}
      >
        ✨Policy Co-Pilot
      </Button>

      <Drawer
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span style={{ color: "#fff" }}>Policy Co-Pilot</span>
            <Dropdown overlay={menu} trigger={["click"]}>
              <HeaderDropdown aria-label="More options" />
            </Dropdown>
          </div>
        }
        placement="right"
        width={600}
        onClose={onClose}
        visible={visible}
        closeIcon={<CloseOutlined style={{ color: "#ffffff" }} />}
        bodyStyle={{ padding: 0, backgroundColor: "#f5f5f5" }}
        headerStyle={{
          background: "linear-gradient(135deg, #4b6cb7, #182848)",
          color: "#ffffff",
          textAlign: "center",
          fontSize: "18px",
          padding: "20px",
        }}
      >
        <ChatContainer>
          <MessagesContainer>
            <List
              dataSource={messages}
              renderItem={(msg, index) => {
                const isBot = msg.role === "bot";
                const isOptions = msg.role === "options";
                const isSameSender = index > 0 && messages[index - 1].role === msg.role;

                if (isOptions) {
                  return (
                    <AnimatePresence key={msg.key}>
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={messageVariants}
                        transition={{ duration: 0.1, ease: "easeOut" }}
                      >
                        <MessageWrapper isBot={false} isSameSender={isSameSender}>
                          <OptionsContainer>
                            {msg.content.map((option) => (
                              <OptionButton
                                key={option.key}
                                onClick={() => {
                                  removeOptionsMessage(msg.key);
                                  handleUserSelection(option.key);
                                }}
                              >
                                {option.label}
                              </OptionButton>
                            ))}
                            <Timestamp isBot={false}>{msg.timestamp}</Timestamp>
                          </OptionsContainer>
                          <AvatarWrapper isBot={false}>
                            <Avatar
                              style={{ backgroundColor: "#1890ff" }}
                              icon={<UserOutlined />}
                            />
                          </AvatarWrapper>
                        </MessageWrapper>
                      </motion.div>
                    </AnimatePresence>
                  );
                }

                return (
                  <AnimatePresence key={msg.key}>
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      variants={messageVariants}
                      transition={{ duration: 0.3, ease: "easeOut" }}
                    >
                      <MessageWrapper isBot={isBot} isSameSender={isSameSender}>
                        {isBot && (
                          <AvatarWrapper isBot={true}>
                            <Avatar
                              style={{ backgroundColor: "#4b6cb7" }}
                              icon={<RobotOutlined />}
                            />
                          </AvatarWrapper>
                        )}

                        <MessageBubble isBot={isBot}>
                          {typeof msg.content === "string" ? (
                            <Text style={{ whiteSpace: "pre-wrap" }}>{msg.content}</Text>
                          ) : (
                            msg.content
                          )}
                          <Timestamp isBot={isBot}>{msg.timestamp}</Timestamp>
                        </MessageBubble>

                        {!isBot && (
                          <AvatarWrapper isBot={false}>
                            <Avatar
                              style={{ backgroundColor: "#379fff" }}
                              icon={<UserOutlined />}
                            />
                          </AvatarWrapper>
                        )}
                      </MessageWrapper>
                    </motion.div>
                  </AnimatePresence>
                );
              }}
            />
            {isLoading && (
              <TypingIndicator>
                <AvatarWrapper isBot={true}>
                  <Avatar style={{ backgroundColor: "#4b6cb7" }} icon={<RobotOutlined />} />
                </AvatarWrapper>
                <MessageBubble isBot={true}>
                  <Dot />
                  <Dot />
                  <Dot />
                </MessageBubble>
              </TypingIndicator>
            )}
            <div ref={messagesEndRef} />
          </MessagesContainer>
        </ChatContainer>
      </Drawer>
    </div>
  );
};

export default AIAgent;
