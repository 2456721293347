import React, { useEffect, useState } from "react";
import { Row, Col, Steps, Typography, Input, Button, Space, message } from "antd";
import { DownloadOutlined, FileTextOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { ReactComponent as MicrosoftNPS } from "./../../assets/microsoftNPS.svg";
import { ReactComponent as CiscoISE } from "./../../assets/ciscoISE.svg";
import { ReactComponent as ClearPass } from "./../../assets/clearpass.svg";
// import { ReactComponent as OpenSourceRadius } from "./../../assets/opensourceradius.svg"; // Skipped as requested
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;
const { Step } = Steps;

export default function OnboardRadiusDevices() {
  const navigate = useNavigate();
  const [orgId, setOrgId] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [notification, contextHolder] = message.useMessage();

  useEffect(() => {
    const storedOrg = localStorage.getItem("OrgId") || "105";
    const storedTenant = localStorage.getItem("DomainId") || "1";
    setOrgId(storedOrg);
    setTenantId(storedTenant);
  }, []);

  const buildEnvContent = () => {
    return `ORG_ID=${orgId}
TENANT_ID=${tenantId}
`;
  };

  const downloadEnvFile = () => {
    const content = buildEnvContent();
    const blob = new Blob([content], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "app.env";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    notification.success({ content: "app.env downloaded successfully." });
  };

  const steps = [
    {
      title: "Step 1: Download the script",
      icon: <DownloadOutlined />,
      description: (
        <div style={{ marginTop: "0.5rem" }}>
          <Text>Run the following command to download the installation script:</Text>
          <Input
            style={{ border: "none", backgroundColor: "#fafafa", marginTop: "1rem" }}
            size="large"
            readOnly
            value="sudo wget https://github.com/authnull0/windows-endpoint/raw/main/agent/radius-agent/install_agent.sh -O install_agent.sh"
            suffix={
              <Text
                copyable={{
                  text: "sudo wget https://github.com/authnull0/windows-endpoint/raw/main/agent/radius-agent/install_agent.sh -O install_agent.sh",
                  tooltips: ["Copy Command", "Copied"],
                }}
                style={{ color: "GrayText" }}
              />
            }
          />
        </div>
      ),
    },
    {
      title: "Step 2: Make the script Executable",
      icon: <FileTextOutlined />,
      description: (
        <div style={{ marginTop: "0.5rem" }}>
          <Text>Use the following command:</Text>
          <Input
            style={{ border: "none", backgroundColor: "#fafafa", marginTop: "1rem" }}
            size="large"
            readOnly
            value="sudo chmod +x install_agent.sh"
            suffix={
              <Text
                copyable={{
                  text: "sudo chmod +x install_agent.sh",
                  tooltips: ["Copy Command", "Copied"],
                }}
                style={{ color: "GrayText" }}
              />
            }
          />
        </div>
      ),
    },
    {
      title: "Step 3: Run the script",
      icon: <FileTextOutlined />,
      description: (
        <div style={{ marginTop: "0.5rem" }}>
          <Text>Install the agent using:</Text>
          <Input
            style={{ border: "none", backgroundColor: "#fafafa", marginTop: "1rem" }}
            size="large"
            readOnly
            value="./install_agent.sh"
            suffix={
              <Text
                copyable={{
                  text: "./install_agent.sh",
                  tooltips: ["Copy Command", "Copied"],
                }}
                style={{ color: "GrayText" }}
              />
            }
          />
        </div>
      ),
    },
    {
      title: "Step 4: Download app.env",
      icon: <DownloadOutlined />,
      description: (
        <div style={{ marginTop: "0.5rem" }}>
          <Space>
            <Button type="primary" onClick={downloadEnvFile} icon={<DownloadOutlined />}>
              Download app.env
            </Button>
          </Space>
          <p style={{ marginTop: "0.5rem" }}>
            This file includes only <b>orgId</b> and <b>tenantId</b>.
          </p>
        </div>
      ),
    },
    {
      title: "Step 5: Configure Radius Server",
      icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
      description: (
        <div style={{ marginTop: "0.5rem" }}>
          <Button
            type="link"
            href="https://help.authnull.com/radius%20bridge/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: "16px", padding: 0 }}
          >
            Configure Radius Server
          </Button>
        </div>
      ),
    },
  ];

  const devices = [
    {
      name: "Microsoft NPS",
      logo: <MicrosoftNPS style={{ width: "50px", height: "50px" }} />,
      description:
        "Microsoft Network Policy Server (NPS) allows you to create and enforce organization-wide network access policies for connection request authentication.",
      link: "https://help.authnull.com/NPS",
    },
    {
      name: "Cisco ISE",
      logo: <CiscoISE style={{ width: "50px", height: "50px" }} />,
      description:
        "Cisco Identity Services Engine (ISE) is a security policy management platform that provides secure access to network resources.",
      link: "https://help.authnull.com/NPS",
    },
    {
      name: "Clearpass",
      logo: <ClearPass style={{ width: "80px", height: "40px" }} />,
      description:
        "ClearPass provides role- and device-based network access control for employees, contractors, and guests across any multivendor wired, wireless, and VPN infrastructure.",
      link: "https://help.authnull.com/NPS",
    },
  ];

  return (
    <>
      {contextHolder}
      <div style={{ width: "75%" }}>
        <h2 className="title">Onboarding Network Devices</h2>
        <Steps direction="vertical" size="small" current={-1} style={{ marginTop: "50px" }}>
          {steps.map((item, index) => (
            <Step
              key={index}
              title={<Text strong>{item.title}</Text>}
              icon={item.icon}
              description={item.description}
            />
          ))}
        </Steps>

        {/* Device Selection */}
        <Row style={{ marginTop: "3rem" }}>
          <Col span={24}>
            <h2>Select the device you want to connect to?</h2>
          </Col>
        </Row>

        <Row gutter={[30]} justify="center" style={{ marginTop: "3rem", marginBottom: "3rem" }}>
          {devices.map((device, idx) => (
            <Col key={idx} xs={24} sm={12} md={8} lg={7}>
              <div
                style={{
                  backgroundColor: "#dbeeff",
                  height: "350px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  padding: "30px",
                  borderRadius: "10px",
                  textAlign: "center",
                }}
              >
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>{device.logo}</div>
                  <h1 style={{ fontSize: "18px", marginTop: "30px" }}>{device.name}</h1>
                  <p>{device.description}</p>
                </div>
                <div style={{ marginBottom: "1rem" }}>
                  <Link to={device.link}>Proceed to Setup</Link>
                </div>
              </div>
            </Col>
          ))}
        </Row>

        <Row justify="end" style={{ marginTop: 25 }}>
          <Space size="middle">
            <Button type="primary" onClick={() => navigate("/pam/radius")}>
              Finish
            </Button>
          </Space>
        </Row>
      </div>
    </>
  );
}
