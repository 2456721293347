import React, { useEffect, useState } from "react";
import { Col, Row, Select, Tag, message, Dropdown, Menu, Button } from "antd";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined, MoreOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_API, ORG_ID, DOMAIN_ID } from "../../constants";
import { formatTimeByTimezone } from "../../common/formatTimebyTimezone";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: 24, color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const UserScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);

  const [selectedDatabase, setSelectedDatabase] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [appliedRole, setAppliedRole] = useState(null);

  const [databaseList, setDatabaseList] = useState([]);
  const [userList, setUserList] = useState([]);

  const [userData, setUserData] = useState([]);
  const [notification, contextHolder] = message.useMessage();

  const [paginationParams, setPaginationParams] = useState({ current: 1, pageSize: 10 });
  const [currentPage, setCurrentPage] = useState(0);
  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({ pageSize: PAGESIZE, page: 0 });

  useEffect(() => {
    fetchFilterLists();
    if (location.state && location.state.databaseFilter) {
      setSelectedUser(location.state.userFilter);
      setSelectedDatabase(location.state.databaseFilter);
    }
  }, [location.state]);

  useEffect(() => {
    fetchUserData();
  }, [selectedDatabase, selectedUser, appliedRole, paginationParams]);

  const fetchFilterLists = async () => {
    try {
      const payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
      };

      const dbRes = await axios.post(
        `${REACT_APP_API}/api/v1/databaseService/getUniqueDatabasenames`,
        payload,
        {
          headers: { "X-Authorization": AUTH_TOKEN(), withCredentials: true },
        }
      );

      // Fetch unique user names
      const userRes = await axios.post(
        `${REACT_APP_API}/api/v1/databaseService/getUniqueUsernames`,
        payload,
        {
          headers: { "X-Authorization": AUTH_TOKEN(), withCredentials: true },
        }
      );

      setDatabaseList(dbRes.data?.db_names || []);
      setUserList(userRes.data?.usernames || []);
    } catch (error) {
      console.error("Error fetching filter lists:", error);
      notification.error({
        content: "Unable to fetch database/user lists",
      });
    }
  };

  const fetchUserData = () => {
    const filters = [];
    if (selectedDatabase)
      filters.push({ filterParameter: "Database", filterValue: selectedDatabase });
    if (selectedUser) filters.push({ filterParameter: "User", filterValue: selectedUser });
    if (appliedRole) filters.push({ filterParameter: "Role", filterValue: appliedRole });

    const payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      filters,
      requestId: "",
      limit: paginationParams.pageSize,
      page_id: paginationParams.current,
    };

    setIsLoading(true);

    axios
      .post(`${REACT_APP_API}/api/v1/databaseService/listUser`, payload, {
        headers: { "X-Authorization": AUTH_TOKEN(), withCredentials: true },
      })
      .then((res) => {
        setIsLoading(false);
        setUserData(
          (res?.data?.data || []).map((item) => ({
            ...item,
            id: item.id,
          }))
        );
        setTotalCount(res?.data?.total_count || 0);
        setCurrentPageModel({ pageSize: PAGESIZE, page: currentPage });
      })
      .catch((err) => {
        setIsLoading(false);
        notification.error({ content: "Unable to fetch users" });
        console.error(err);
      });
  };

  const columns = [
    {
      headerName: "Username",
      field: "username",
      flex: 1.5,
    },

    {
      headerName: "Host",
      field: "host",
      flex: 1,
    },
    {
      headerName: "Role",
      field: "role",
      flex: 1,
      renderCell: (params) => (
        <Tag color={params.value === "Admin" ? "volcano" : "blue"}>{params.value}</Tag>
      ),
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      renderCell: (params) => (
        <Tag color={params.value === "Active" ? "green" : "red"}>{params.value}</Tag>
      ),
    },
    {
      headerName: "Created At",
      field: "created_at",
      flex: 1.5,
      renderCell: (val) => {
        const timeStamp = val?.row?.created_at;
        const timezone = localStorage.getItem("timezone");
        return formatTimeByTimezone(timeStamp, timezone);
      },
      // renderCell: (params) =>
      //   moment.unix(params.value).tz("America/Los_Angeles").format("DD-MM-YYYY HH:mm:ss [PST]"),
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      renderCell: (params) => {
        const handleMenuClick = ({ key }) => {
          if (key === "viewPrivileges") {
            navigate("/pam/databases/userPrivileges", {
              state: { userFilter: params.row.username },
            });
          }
        };

        const menu = (
          <Menu onClick={handleMenuClick}>
            <Menu.Item key="viewPrivileges">View Privileges</Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button icon={<MoreOutlined />} type="text" />
          </Dropdown>
        );
      },
    },
  ];

  const combinedFilters = [];
  if (selectedDatabase) {
    combinedFilters.push({
      key: "Database",
      display: `Database: ${selectedDatabase}`,
    });
  }
  if (selectedUser) {
    combinedFilters.push({
      key: "User",
      display: `User: ${selectedUser}`,
    });
  }
  if (appliedRole) {
    combinedFilters.push({
      key: "Role",
      display: `Role: ${appliedRole}`,
    });
  }

  return (
    <>
      {contextHolder}
      <Row className="content-container">
        <Col span={24}>
          <Row justify="space-between" style={{ marginBottom: 16 }}>
            <Col>
              <h2 className="title">Database Users</h2>
            </Col>
          </Row>

          <Row style={{ marginBottom: "1rem", marginTop: "1rem" }} gutter={20}>
            <Col span={4}>
              <p className="search-label">Database</p>
              <Select
                showSearch
                allowClear
                placeholder="Select Database"
                style={{ width: "100%" }}
                value={selectedDatabase}
                onChange={(value) => setSelectedDatabase(value)}
                filterOption={(input, option) =>
                  (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
                }
              >
                {databaseList.map((db) => (
                  <Select.Option key={db} value={db}>
                    {db}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            <Col span={4}>
              <p className="search-label">User</p>
              <Select
                showSearch
                allowClear
                placeholder="Select User"
                style={{ width: "100%" }}
                value={selectedUser}
                onChange={(value) => setSelectedUser(value)}
                filterOption={(input, option) =>
                  (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
                }
              >
                {userList.map((user) => (
                  <Select.Option key={user} value={user}>
                    {user}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            <Col span={4}>
              <p className="search-label">Role</p>
              <Select
                showSearch
                allowClear
                placeholder="Select Role"
                style={{ width: "100%" }}
                value={appliedRole}
                onChange={(value) => setAppliedRole(value)}
                filterOption={(input, option) =>
                  (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
                }
              >
                <Select.Option value="Admin">Admin</Select.Option>
                <Select.Option value="User">User</Select.Option>
              </Select>
            </Col>
          </Row>

          {combinedFilters.length > 0 && (
            <Row style={{ marginBottom: 16 }}>
              <Col>
                <p>Applied Filters</p>
                {combinedFilters.map(({ key, display }) => (
                  <Tag
                    key={key}
                    closable
                    onClose={() => {
                      if (key === "Database") {
                        setSelectedDatabase(null);
                      } else if (key === "User") {
                        setSelectedUser(null);
                      } else if (key === "Role") {
                        setAppliedRole(null);
                      }
                    }}
                    style={{ marginBottom: 8, marginRight: 8 }}
                  >
                    {display}
                  </Tag>
                ))}
              </Col>
            </Row>
          )}

          <Row>
            <Col span={24}>
              <DataGrid
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={userData}
                columns={columns}
                paginationMode="server"
                rowCount={totalCount}
                loading={isLoading}
                style={{ border: "none" }}
                paginationModel={currentPageModel}
                page={currentPage}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: PAGESIZE, page: 0 },
                  },
                }}
                onPaginationModelChange={(params) => {
                  setCurrentPage(params.page);
                  setPaginationParams({
                    current: params.page + 1,
                    pageSize: params.pageSize,
                  });
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default UserScreen;
