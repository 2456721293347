import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Select,
  InputNumber,
  Button,
  message,
  Modal,
  Typography,
  Table,
  Tooltip,
  Card,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;
const { Option } = Select;

// Utility for localStorage
const getLocalStorageData = (key) => {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error(`Error parsing localStorage key "${key}":`, error);
    return null;
  }
};

const setLocalStorageData = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error setting localStorage key "${key}":`, error);
  }
};

// Comparators
const comparatorOptions = [
  { label: "Greater Than", value: ">" },
  { label: "Less Than", value: "<" },
  { label: "Equals", value: "==" },
  { label: "Greater or Equals", value: ">=" },
  { label: "Less or Equals", value: "<=" },
];

const IdentityRisk = ({ onNavigateNext, onCreatePolicy, loadingPolicy }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const [form] = Form.useForm();
  const [personaRisk, setPersonaRisk] = useState(null); // Single object with persona/confidence/risk
  const [editing, setEditing] = useState(false);
  const [notification, notificationHolder] = message.useMessage();
  const navigate = useNavigate();
  const [policySaved, setPolicySaved] = useState(false);
  const [payload, setPayload] = useState();
  const isEditMode = searchParams.get("edit") === "true";

  useEffect(() => {
    const basicPolicyDraft = getLocalStorageData("policyDraft.basicPolicyInformation");
    if (!basicPolicyDraft) {
      message.error("Primary Information is missing. Please complete it first!");
      return;
    }
    if (basicPolicyDraft.policyJson?.policyType === "GroupAD") {
      if (basicPolicyDraft.policyJson?.dit?.schedule && !basicPolicyDraft.policyJson?.dit?.jit) {
        message.error("For GroupAD policy, if schedule is present, JIT must also be present.");
        return;
      }
    }

    if (basicPolicyDraft) {
      const existingScores = basicPolicyDraft.policyJson?.dit?.scores || {};

      // Extract operator/value
      const extractOperatorAndValue = (scoreObj) => {
        const { operator, value } = scoreObj;
        return {
          operator: operator || "",
          value: value ?? 0,
        };
      };

      // Persona, Confidence, Risk
      const persona = existingScores.persona
        ? extractOperatorAndValue(existingScores.persona)
        : null;
      const confidence = existingScores.confidence
        ? extractOperatorAndValue(existingScores.confidence)
        : null;
      const risk = existingScores.risk ? extractOperatorAndValue(existingScores.risk) : null;

      if (persona && confidence && risk) {
        const riskEntry = {
          personaComparator: persona.operator,
          personaValue: persona.value,
          confidenceComparator: confidence.operator,
          confidenceValue: confidence.value,
          riskComparator: risk.operator,
          riskValue: risk.value,
        };
        setPersonaRisk(riskEntry);
        form.setFieldsValue(riskEntry);
      } else {
        console.warn("Persona, confidence, or risk fields are missing or incomplete.");
      }
    } else {
      notification.error({
        content: "Primary Information is missing. Please complete it first!",
      });
    }
  }, [notification, form]);

  const handleAddOrEditRisk = (values) => {
    const {
      personaComparator,
      personaValue,
      confidenceComparator,
      confidenceValue,
      riskComparator,
      riskValue,
    } = values;

    const isValidRange = (val) => Number.isInteger(val) && val >= 0 && val <= 10;
    const numericFields = [personaValue, confidenceValue, riskValue];
    const invalidField = numericFields.find((val) => !isValidRange(val));
    if (invalidField !== undefined) {
      message.error("Persona/Confidence/Risk values must be integers between 0 and 10.");
      return;
    }

    const newRisk = {
      personaComparator,
      personaValue,
      confidenceComparator,
      confidenceValue,
      riskComparator,
      riskValue,
    };

    setPersonaRisk(newRisk);
    notification.success({
      content: editing
        ? "Identity risk updated successfully!"
        : "Identity risk added successfully!",
    });

    form.resetFields();
    setEditing(false);
  };

  const handleEditRisk = () => {
    setEditing(true);
  };

  const handleRemoveRisk = () => {
    Modal.confirm({
      title: "Are you sure you want to remove this identity risk?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        setPersonaRisk(null);
        notification.info({ content: "Identity risk removed." });
      },
    });
  };

  const handleSavePolicy = async () => {
    if (!personaRisk) {
      message.error("No identity risks to save.");
      return null;
    }

    const scores = {
      persona: {
        operator: personaRisk.personaComparator,
        value: personaRisk.personaValue,
      },
      confidence: {
        operator: personaRisk.confidenceComparator,
        value: personaRisk.confidenceValue,
      },
      risk: {
        operator: personaRisk.riskComparator,
        value: personaRisk.riskValue,
      },
    };

    const basicPolicyDraft = getLocalStorageData("policyDraft.basicPolicyInformation");
    if (!basicPolicyDraft) {
      message.error("Primary Information is missing. Please complete it first!");
      return null;
    }

    const updatedData = { ...basicPolicyDraft };
    const policyJson = { ...updatedData.policyJson };
    const dit = { ...policyJson.dit };

    // Merge scores
    dit.scores = {
      ...dit.scores,
      ...scores,
    };
    policyJson.dit = dit;
    updatedData.policyJson = policyJson;

    setLocalStorageData("policyDraft.basicPolicyInformation", updatedData);
    setPayload(updatedData);
    setPolicySaved(true);

    return updatedData;
  };

  const handleSaveAndNext = async () => {
    const updatedData = await handleSavePolicy();
    if (updatedData) {
      onNavigateNext();
    }
  };

  const handleSaveAndCreate = async () => {
    const updatedData = await handleSavePolicy();
    if (updatedData) {
      onCreatePolicy({
        orgId: updatedData.orgId,
        tenantId: updatedData.tenantId,
        policyName: updatedData.policyName,
        policyType: updatedData.policyType,
        policyJson: updatedData.policyJson,
      });
    }
  };

  const handleCancel = () => {
    Modal.confirm({
      title: "Are you sure you want to cancel?",
      content: "All unsaved changes will be lost.",
      onOk: () => {
        localStorage.removeItem("policyDraft.basicPolicyInformation");
        localStorage.removeItem("formSessionData");
        localStorage.removeItem("editPolicyData");
        navigate("/pam/policy/v2");
      },
    });
  };

  // Table columns (ONLY persona/confidence/risk)
  const columns = [
    {
      title: "Persona Value",
      dataIndex: "personaValue",
      key: "personaValue",
      render: (value, record) => (
        <>
          <strong>{record.personaComparator}</strong> {value}
        </>
      ),
    },
    {
      title: "Confidence Score",
      dataIndex: "confidenceValue",
      key: "confidenceValue",
      render: (value, record) => (
        <>
          <strong>{record.confidenceComparator}</strong> {value}
        </>
      ),
    },
    {
      title: "Risk Score",
      dataIndex: "riskValue",
      key: "riskValue",
      render: (value, record) => (
        <>
          <strong>{record.riskComparator}</strong> {value}
        </>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Tooltip title="Edit Identity Risk">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={handleEditRisk}
              aria-label="Edit Identity Risk"
            />
          </Tooltip>
          <Tooltip title="Remove Identity Risk">
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={handleRemoveRisk}
              aria-label="Remove Identity Risk"
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const dataSource = personaRisk
    ? [
        {
          key: 1,
          personaValue: personaRisk.personaValue,
          personaComparator: personaRisk.personaComparator,
          confidenceValue: personaRisk.confidenceValue,
          confidenceComparator: personaRisk.confidenceComparator,
          riskValue: personaRisk.riskValue,
          riskComparator: personaRisk.riskComparator,
        },
      ]
    : [];

  return (
    <div>
      {notificationHolder}
      <Card>
        <Form
          form={form}
          layout="horizontal"
          onFinish={handleAddOrEditRisk}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          {/* Persona */}
          <Form.Item
            label={
              <span>
                Persona Value{" "}
                <Tooltip title="Specify the persona value with a comparator and value.">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="personaComparator"
                  noStyle
                  rules={[{ required: true, message: "Select comparator." }]}
                >
                  <Select placeholder="Comparator" allowClear style={{ width: "100%" }}>
                    {comparatorOptions.map((comp) => (
                      <Option key={comp.value} value={comp.value}>
                        {comp.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="personaValue"
                  noStyle
                  rules={[{ required: true, message: "Enter persona value." }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Enter persona value"
                    min={0}
                    max={10}
                    step={1}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          {/* Confidence */}
          <Form.Item
            label={
              <span>
                Confidence Score{" "}
                <Tooltip title="Specify the confidence score with a comparator and value.">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="confidenceComparator"
                  noStyle
                  rules={[{ required: true, message: "Select comparator." }]}
                >
                  <Select placeholder="Comparator" allowClear style={{ width: "100%" }}>
                    {comparatorOptions.map((comp) => (
                      <Option key={comp.value} value={comp.value}>
                        {comp.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="confidenceValue"
                  noStyle
                  rules={[{ required: true, message: "Enter confidence score." }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Enter confidence"
                    min={0}
                    max={10}
                    step={1}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          {/* Risk */}
          <Form.Item
            label={
              <span>
                Risk Score{" "}
                <Tooltip title="Specify the risk score with a comparator and value.">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="riskComparator"
                  noStyle
                  rules={[{ required: true, message: "Select comparator." }]}
                >
                  <Select placeholder="Comparator" allowClear style={{ width: "100%" }}>
                    {comparatorOptions.map((comp) => (
                      <Option key={comp.value} value={comp.value}>
                        {comp.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="riskValue"
                  noStyle
                  rules={[{ required: true, message: "Enter risk score." }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Enter risk score"
                    min={0}
                    max={10}
                    step={1}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          <Row justify="end">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                icon={editing ? <EditOutlined /> : <PlusOutlined />}
                disabled={personaRisk && !editing}
              >
                {editing ? "Update Risk" : "Add Risk"}
              </Button>
            </Form.Item>
          </Row>
        </Form>

        <Row>
          <Col>
            <Text type="secondary">
              "All these fields are optional and managed by Authnull agent."
            </Text>
          </Col>
        </Row>
      </Card>

      {personaRisk && (
        <>
          <Card
            type="inner"
            title="Saved Identity Risk"
            bordered={false}
            style={{ marginBottom: "24px", marginTop: "24px" }}
          >
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              rowKey="key"
              bordered
            />
            {isEditMode && (
              <Row>
                <Col style={{ marginTop: "10px" }}>
                  <Text type="secondary">"Dont keep this policy field empty"</Text>
                </Col>
              </Row>
            )}
          </Card>

          <Row justify="end" style={{ marginTop: "24px" }}>
            <Button onClick={handleCancel} style={{ marginRight: "16px" }}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSaveAndNext} style={{ marginRight: "16px" }}>
              {isEditMode ? "Update and Next" : "Save and Next"}
            </Button>
            <Button type="primary" onClick={handleSaveAndCreate} loading={loadingPolicy}>
              {isEditMode ? "Update Policy" : "Create Policy"}
            </Button>
          </Row>
        </>
      )}
    </div>
  );
};

export default IdentityRisk;
