import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Select,
  Dropdown,
  Modal,
  message,
  Tag,
  Cascader,
  notification,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import { AUTH_TOKEN, DOMAIN_ID, ORG_ID, REACT_APP_API_DID_URL } from "../../constants";
import axios from "axios";
import { logOutUser } from "../../common";
import { DataGrid, GridOverlay, useGridApiRef } from "@mui/x-data-grid";
import { InboxOutlined, MoreOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { formatTimeByTimezone } from "../../common/formatTimebyTimezone";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const PopupModal = (isModalOpen, handleCancel, content, notification) => {
  const handleCopy = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      handleCancel();
      notification.open({
        type: "success",
        content: "Copied to clipboard",
      });
    } catch (err) {
      notification.open({
        type: "error",
        content: "Failed to copy",
      });
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <Modal
      title="Password/SSH"
      open={isModalOpen}
      okText="Copy"
      onOk={() => handleCopy(content)}
      onCancel={handleCancel}
      centered={true}
    >
      <span>{content ? content : "No Password/SSH"}</span>
    </Modal>
  );
};

const PAGESIZE = 10;

export default function SecretListV2() {
  const apiRef = useGridApiRef();
  const [secretsList, setSecretsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [filteredSecretList, setFilteredSecretList] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [toastNotification, setToastNotification] = message.useMessage();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectedRowPayloadData, setSelectedRowPayloadData] = useState();
  const [notificationApi, setNotifcation] = notification.useNotification();

  // pagination
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });

  const { Text } = Typography;

  useEffect(() => {
    fetchSecretsList();
  }, [paginationParams, currentPage]);

  // table columns
  const columns = [
    {
      headerName: "Credential ID",
      field: "secretName",
      minWidth: 130,
    },
    {
      headerName: "Secret Data Type",
      field: "secretType",
      minWidth: 130,
    },
    {
      headerName: "Storage Type",
      field: "storedAt",
    },
    {
      headerName: "Username",
      field: "userName",
      sortable: true,
    },
    {
      headerName: "User Type",
      field: "userType",
      renderCell: (val) => {
        if (!val?.row?.userType) {
          return <span>-</span>;
        }
      },
    },
    {
      headerName: "Endpoint",
      field: "hostname",
      sortable: true,
      renderCell: (val) => {
        if (!val?.row?.hostname) {
          return <span>-</span>;
        }
      },
    },
    {
      headerName: "Public IP Address",
      field: "publicIPAddress",
      sortable: true,
      width: 130,
      renderCell: (val) => {
        if (!val?.row?.publicIPAddress) {
          return <span>-</span>;
        }
      },
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (val) => {
        let statusVal = val.row.status.toLowerCase() === "inactive" ? "Inactive" : "Active";
        return (
          <>
            <Text type={statusVal !== "Inactive" ? "success" : "danger"}> {statusVal}</Text>
          </>
        );
      },
    },

    {
      headerName: "Created At",
      field: "createdAt",
      // renderCell: (record) => {
      //   if (!record.row.createdAt) {
      //     return <span>-</span>;
      //   }
      //   return moment
      //     .tz(record.row.createdAt, "America/Los_Angeles")
      //     .format("DD-MM-YYYY HH:mm:ss [PST]");
      // },
      renderCell: (val) => {
        const createdAt = val?.row?.createdAt;
        const timezone = localStorage.getItem("timezone");
        return formatTimeByTimezone(createdAt, timezone);
      },
    },
    {
      headerName: "Action",
      field: "action",
      width: 160,

      renderCell: (record) => {
        const items = [
          {
            key: "1",
            label: (
              <Link
                onClick={() => {
                  setModalContent(record.row.password);
                  setIsModalOpen(true);
                }}
              >
                View Password/SSH
              </Link>
            ),
          },
          {
            key: "2",
            label: <Link onClick={() => {}}>Assign</Link>,
          },
        ];

        return (
          <Dropdown
            placement="bottomLeft"
            menu={{
              items: items,
            }}
          >
            <Button type="text" shape="circle" icon={<MoreOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  const calculateColumnWidths = (data, columnsToAutoSize, includeHeaders = false) => {
    const columnWidths = {};

    columnsToAutoSize.forEach((col) => {
      let maxWidth = includeHeaders ? col.length * 13 : 0;

      data.forEach((row) => {
        const value = row[col];
        const width = value ? value.toString().length * 9.5 : 0;
        if (width > maxWidth) {
          maxWidth = width;
        }
      });

      columnWidths[col] = maxWidth;
    });

    return columnWidths;
  };

  // Calculate column widths
  const columnWidths = calculateColumnWidths(
    filteredSecretList,
    [
      "secretName",
      "secretType",
      "storedAt",
      "userName",
      "userType",
      "hostname",
      "publicIPAddress",
      "status",
      "createdAt",
      "action",
    ],
    true
  );

  // Apply the calculated widths to the columns
  const adjustedColumns = columns.map((col) => ({
    ...col,
    width: columnWidths[col.field] || col.width,
  }));

  const handleFilter = (filters, data) => {
    const result = data.filter((item) => {
      return filters.every((filter) => {
        return item[filter.filterParameter] === filter.filterValue;
      });
    });
    setFilteredSecretList(result);
  };

  const handleApplyFilter = (filter, value, label) => {
    console.log("label", label);
    let filterParameter = filter;
    let filterValue = value;
    let filterLabel = label;
    let obj = [
      {
        filterParameter: filterParameter,
        filterValue: filterValue,
        filterLabel: filterLabel,
      },
    ];
    let tempAppliedFilter = appliedFilters;
    let tempfilter = tempAppliedFilter.concat(obj);
    const uniqueFilters = tempfilter.reduceRight((acc, current) => {
      const existingFilter = acc.find(
        (filter) => filter.filterParameter === current.filterParameter
      );

      if (!existingFilter) {
        acc.push(current);
      }

      return acc;
    }, []);

    console.log(uniqueFilters);

    setAppliedFilters(uniqueFilters);
    handleFilter(uniqueFilters, secretsList);
  };

  const getCurrentOption = (type) => {
    const currentOption = appliedFilters.filter((opt) => opt.filterParameter === type);
    return currentOption[0]?.filterLabel ?? null;
  };

  const handleRemoveFilter = (filter, value) => {
    const newFilters = appliedFilters.filter(
      (filterItem) => filterItem.filterParameter !== filter || filterItem.filterValue !== value
    );

    setAppliedFilters(newFilters);

    if (newFilters.length === 0) {
      setFilteredSecretList(secretsList);
    } else {
      handleFilter(newFilters, secretsList);
    }
  };

  const handleRowSelectionModelChange = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel);

    const selectedRowData = newRowSelectionModel.map((id) =>
      filteredSecretList.find((row) => row.uniqueId === id)
    );

    let selectedRowObj = {
      secretName: [],
      storedAt: [],
      credentialId: [],
    };

    selectedRowData.forEach((row) => {
      selectedRowObj.secretName.push(row.secretKey);
      selectedRowObj.storedAt.push(row.storedAt);
      selectedRowObj.credentialId.push(row.credentialId);
    });

    setSelectedRowPayloadData(selectedRowObj);
  };

  const handleDelete = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      secretName: selectedRowPayloadData.secretName,
      storedAt: selectedRowPayloadData.storedAt,
      credentialId: selectedRowPayloadData.credentialId,
    };
    try {
      setIsLoading(true);
      const res = axios.post(`${REACT_APP_API_DID_URL}/secretservice/deleteSecret`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      console.log(res);
      toastNotification.open({
        type: "success",
        content: "Secret deleted successfully.",
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toastNotification.open({
        type: "error",
        content: "Failed to delete.",
      });
    }
  };

  const fetchSecretsList = async () => {
    const userId = localStorage.getItem("userId");
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      userId: Number(userId),
    };

    notificationApi.info({
      message: "Approve Secret Permission",
      description: "Pinging AuthNull Wallet please approve to share the secrets.",
      duration: 0,
    });

    setIsLoading(true);

    axios
      .post(`${REACT_APP_API_DID_URL}/secretservice/listAllSecrets`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        notificationApi.destroy();

        setSecretsList(res.data.vault_secrets ? res.data.vault_secrets : []);
        setFilteredSecretList(res.data.vault_secrets ? res.data.vault_secrets : []);

        if (res.data.message === "User has not approved to fetch the secrets from the wallet") {
          toastNotification.open({
            type: "success",
            content: "User has not approved to fetch the secrets from the wallet.",
          });
        }

        setTotalCount(res.data.total_count);
        setCurrentPageModel({
          pageSize: 10,
          page: currentPage,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        notificationApi.destroy();

        if (error.response?.status === 401) {
          logOutUser();
          toastNotification.open({
            type: "error",
            content: "Invalid session. Please login again.",
          });
        }
        toastNotification.open({
          type: "error",
          content: "Failed to fetch the secret list",
        });
        setIsLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      {setToastNotification}
      {setNotifcation}
      {PopupModal(isModalOpen, () => setIsModalOpen(false), modalContent, toastNotification)}
      <Row>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Secret Management</h2>
            </Col>
            <Row gutter={10}>
              <Col>
                <Button
                  type="primary"
                  disabled={
                    !selectedRowPayloadData ||
                    Object.values(selectedRowPayloadData).every((arr) => arr.length === 0)
                  }
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Col>
              <Col>
                <Link to="/pam/secrets/add">
                  <Button type="primary">Add Secrets</Button>
                </Link>
              </Col>
            </Row>
          </Row>

          <Row gutter={30} style={{ marginBottom: "2rem" }}>
            <Col span={6}>
              <p className="search-label">Secrets Type</p>
              <Cascader
                options={[
                  {
                    value: "Endpoints",
                    label: "Endpoints",
                    children: [
                      {
                        value: "Password",
                        label: "Windows",
                      },
                      {
                        value: "SSH",
                        label: "Linux",
                      },
                    ],
                  },
                  {
                    value: "Aduser",
                    label: "Active Directory",
                  },
                ]}
                expandTrigger="hover"
                onChange={(e, selectedOptions) => {
                  const label = selectedOptions[selectedOptions.length - 1].label;
                  if (e?.length > 1) {
                    console.log(e[1]);
                    handleApplyFilter("secretType", e[1], label);
                  } else {
                    console.log(e[0]);
                    handleApplyFilter("secretType", e[0], label);
                  }
                }}
                value={getCurrentOption("secretType")}
                placeholder="Search secret type"
              />
            </Col>

            <Col span={6}>
              <p className="search-label">Select Storage Type</p>
              <Select
                placeholder="Select Storage Type"
                value={getCurrentOption("storedAt")}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => {
                  const options = [
                    {
                      value: "Decentralized Wallet",
                      label: "Decentralized Wallet",
                    },
                    { value: "Centralized Vault", label: "Centralized Vault" },
                    { value: "Custom Vault", label: "Custom Vault" },
                  ];
                  const option = options.find((option) => option.value === value);
                  handleApplyFilter("storedAt", value, option ? option.label : "");
                }}
                options={[
                  {
                    value: "Decentralized Wallet",
                    label: "Decentralized Wallet",
                  },
                  { value: "Centralized Vault", label: "Centralized Vault" },
                  { value: "Custom Vault", label: "Custom Vault" },
                ]}
              />
            </Col>
          </Row>

          <Row justify="space-between">
            <Col>
              {appliedFilters.length > 0 && (
                <div className="filter-tags">
                  <h4
                    style={{
                      marginTop: "0rem",
                      marginBottom: "0.5rem",
                      fontWeight: "400",
                    }}
                  >
                    Applied Filters:
                  </h4>
                  {appliedFilters.map(({ filterParameter, filterValue, filterLabel }) => (
                    <>
                      <Tag
                        key={`${filterParameter}:${filterValue}`}
                        closable
                        onClose={() => handleRemoveFilter(filterParameter, filterValue)}
                      >
                        {`${filterParameter}: ${filterLabel}`}
                      </Tag>
                    </>
                  ))}
                </div>
              )}
            </Col>
          </Row>

          <Row style={{ marginTop: "2rem" }}>
            <Col span={24}>
              <DataGrid
                apiRef={apiRef}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={handleRowSelectionModelChange}
                rowSelectionModel={rowSelectionModel}
                loading={isLoading}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={filteredSecretList}
                getRowId={(row) => row.uniqueId}
                paginationMode="server"
                columns={adjustedColumns}
                rowCount={totalCount}
                style={{ border: "none" }}
                page={currentPage}
                paginationModel={currentPageModel}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
                onPaginationModelChange={(params) => {
                  setCurrentPage(params.page);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
