import React from "react";
import { Form, Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const workflowOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const PolicyWorkflowFields = ({ form, filters, setFilters }) => {
  return (
    <>
      {/* Request to Approve Filter */}
      <Form.Item
        label="Request to Approve"
        // Instead of forcing a rule, we just let it be optional:
        name={["workflow", "request_to_approve"]}
      >
        <Select
          placeholder="Select an option (optional)"
          allowClear
          onChange={(value) => {
            setFilters({
              ...filters,
              workflow: {
                ...filters.workflow,
                request_to_approve: value,
              },
            });
          }}
        >
          {workflowOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {/* Require Justification Filter */}
      <Form.Item label="Need Justification" name={["workflow", "need_justification"]}>
        <Select
          placeholder="Select an option (optional)"
          allowClear
          onChange={(value) => {
            setFilters({
              ...filters,
              workflow: {
                ...filters.workflow,
                need_justification: value,
              },
            });
          }}
        >
          {workflowOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {/* Require Ticket Filter */}
      <Form.Item label="Require Ticket" name={["workflow", "required_ticket"]}>
        <Select
          placeholder="Select an option (optional)"
          allowClear
          onChange={(value) => {
            setFilters({
              ...filters,
              workflow: {
                ...filters.workflow,
                required_ticket: value,
              },
            });
          }}
        >
          {workflowOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default PolicyWorkflowFields;
