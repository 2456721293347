import React from "react";
import { Form, Row, Col, Select, InputNumber } from "antd";

const { Option } = Select;

const comparatorOptions = [
  { label: "Greater Than", value: ">" },
  { label: "Less Than", value: "<" },
  { label: "Equals", value: "==" },
  { label: "Greater or Equals", value: ">=" },
  { label: "Less or Equals", value: "<=" },
];

const IdentityRiskFilterFields = ({ form, filters, setFilters }) => {
  return (
    <>
      {/* Persona Value */}
      <Form.Item
        label="Persona Value"
        name={["scores", "persona"]}
        rules={[
          {
            validator: (_, value) => {
              if (!value) return Promise.resolve();
              const { comparator, value: val } = value;
              if (!comparator || val === undefined) {
                return Promise.reject(
                  new Error("Both comparator and value are required for Persona Value.")
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name={["scores", "persona", "comparator"]} noStyle>
              <Select
                placeholder="Comparator"
                allowClear
                style={{ width: "100%" }}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    scores: {
                      ...filters.identityRisk,
                      persona: {
                        ...filters.identityRisk?.persona,
                        comparator: value,
                        // Reset value if comparator changes
                        value:
                          value !== undefined ? filters.identityRisk?.persona?.value : undefined,
                      },
                    },
                  });
                }}
              >
                {comparatorOptions.map((comp) => (
                  <Option key={comp.value} value={comp.value}>
                    {comp.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["scores", "persona", "value"]} noStyle>
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Value"
                min={0}
                max={10}
                step={1}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    scores: {
                      ...filters.scores,
                      persona: {
                        ...filters.scores?.persona,
                        value: value,
                      },
                    },
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      {/* Confidence Score */}
      <Form.Item
        label="Confidence Score"
        name={["scores", "confidence"]}
        rules={[
          {
            validator: (_, value) => {
              if (!value) return Promise.resolve();
              const { comparator, value: val } = value;
              if (!comparator || val === undefined) {
                return Promise.reject(
                  new Error("Both comparator and value are required for Confidence Score.")
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name={["scores", "confidence", "comparator"]} noStyle>
              <Select
                placeholder="Comparator"
                allowClear
                style={{ width: "100%" }}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    scores: {
                      ...filters.scores,
                      confidence: {
                        ...filters.scores?.confidence,
                        comparator: value,
                        // Reset value if comparator changes
                        value: value !== undefined ? filters.scores?.confidence?.value : undefined,
                      },
                    },
                  });
                }}
              >
                {comparatorOptions.map((comp) => (
                  <Option key={comp.value} value={comp.value}>
                    {comp.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["scores", "confidence", "value"]} noStyle>
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Value"
                min={0}
                max={10}
                step={1}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    scores: {
                      ...filters.scores,
                      confidence: {
                        ...filters.scores?.confidence,
                        value: value,
                      },
                    },
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      {/* Risk Score */}
      <Form.Item
        label="Risk Score"
        name={["scores", "risk"]}
        rules={[
          {
            validator: (_, value) => {
              if (!value) return Promise.resolve();
              const { comparator, value: val } = value;
              if (!comparator || val === undefined) {
                return Promise.reject(
                  new Error("Both comparator and value are required for Risk Score.")
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name={["scores", "risk", "comparator"]} noStyle>
              <Select
                placeholder="Comparator"
                allowClear
                style={{ width: "100%" }}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    scores: {
                      ...filters.scores,
                      risk: {
                        ...filters.scores?.risk,
                        comparator: value,
                        // Reset value if comparator changes
                        value: value !== undefined ? filters.scores?.risk?.value : undefined,
                      },
                    },
                  });
                }}
              >
                {comparatorOptions.map((comp) => (
                  <Option key={comp.value} value={comp.value}>
                    {comp.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["scores", "risk", "value"]} noStyle>
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Value"
                min={0}
                max={10}
                step={1}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    scores: {
                      ...filters.scores,
                      risk: {
                        ...filters.scores?.risk,
                        value: value,
                      },
                    },
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};

export default IdentityRiskFilterFields;
