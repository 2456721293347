import React, { useEffect } from "react";
import { Form, Select, Radio } from "antd";

const { Option } = Select;

const TimeBoundAccessFields = ({ form, filters, setFilters }) => {
  // Ensure filters.schedule is defined on mount or rely on the existing filters if present
  useEffect(() => {
    // If filters.schedule is undefined, set it to null
    if (filters.schedule === undefined) {
      setFilters((prev) => ({ ...prev, schedule: null }));
    }

    form.setFieldsValue({
      isTimeBound: !!filters.schedule, // Convert presence of `schedule` to boolean
      schedule: filters.schedule || { type: "" },
    });
  }, [filters, form, setFilters]);

  const handleIsTimeBoundChange = (e) => {
    const value = e.target.value;

    form.setFieldsValue({ isTimeBound: value });

    if (!value) {
      setFilters((prev) => ({ ...prev, schedule: null }));
      form.setFieldsValue({ schedule: null });
    } else {
      setFilters((prev) => ({ ...prev, schedule: { type: "" } }));
      form.setFieldsValue({ schedule: { type: "" } });
    }
  };

  const handleAccessTypeChange = (selectedValue) => {
    const scheduleType = selectedValue === "jit" ? "jit" : "recurring";
    form.setFieldsValue({ schedule: { type: scheduleType } });
    setFilters((prev) => ({ ...prev, schedule: { type: scheduleType } }));
  };

  return (
    <>
      <Form.Item
        name="isTimeBound"
        label="Is this policy Timebound?"
        rules={[
          {
            required: true,
            message: "Please select if policy is timebound",
          },
        ]}
      >
        <Radio.Group onChange={handleIsTimeBoundChange}>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>

      {filters.schedule && (
        <Form.Item
          name={["schedule", "type"]}
          label="What type of access do they need?"
          rules={[
            {
              required: true,
              message: "Please select an access type",
            },
          ]}
        >
          <Select placeholder="Select Access Type" onChange={handleAccessTypeChange}>
            <Option value="jit">One Time</Option>
            <Option value="Recurring">Recurring</Option>
          </Select>
        </Form.Item>
      )}
    </>
  );
};

export default TimeBoundAccessFields;
