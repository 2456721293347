import React from "react";
import { Route, Routes } from "react-router-dom";
import Signup from "./Signup";
import Login from "./Login";
import TenantLogin from "./tenant/Login";
import TenantSignup from "./tenant/Signup";
import Logout from "./Logout";
import MfaCacheNotification, { ErrorSSoMFA } from "./tenant/MfaCacheNotification";
import CreateApp from "./OIDC/CreateApp";
import SingleFactorLogin from "./OIDC/SingleFactorLogin";
import TwoFactorLogin from "./OIDC/TwoFactorLogin";
import { TenantForgotPassword } from "./tenant/ForgotPassword";
import OnSuccess from "./OnSuccess";
import VerifyEmailSuccess from "./VerifyEmailSuccess";

const UnAuthorizeRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="logout" element={<Logout />} />
        <Route path="org/*">
          <Route path="signup" element={<Signup />} />
          <Route path="login" element={<Login />} />
          <Route path="success-signup" element={<OnSuccess />} />
          <Route path="verify-email" element={<VerifyEmailSuccess />} />
        </Route>
        <Route path="register/*">
          <Route path="app" element={<CreateApp />} />
        </Route>
        <Route path="tenant/*">
          <Route path="login" element={<TenantLogin />} />
          <Route path="signup" element={<TenantSignup />} />
          <Route path="forgot-password" element={<TenantForgotPassword />} />
        </Route>
        <Route path="oidc/*">
          <Route path="1fa" element={<SingleFactorLogin />} />
          <Route path="2fa" element={<TwoFactorLogin />} />
        </Route>

        <Route path="sso-mfa" element={<MfaCacheNotification />} />
        <Route path="error-sso-mfa" element={<ErrorSSoMFA />} />
      </Routes>
    </>
  );
};

export default UnAuthorizeRoutes;
