import { Col, Input, Row, Table, Tag, message, notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AUTH_TOKEN, REACT_APP_API_PAM_URL, ORG_ID, DOMAIN_ID } from "../../constants";
import { logOutUser } from "../../common";
import { Link } from "react-router-dom";
import { useDebounce } from "../../common/debounce";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const MyConnections = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [isNotification, setIsNotification] = message.useMessage();
  const [connectionData, setConnectionData] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  let userEmail = localStorage.getItem("UserName");
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });
  const debouncedValue = useDebounce(search, 500);
  const [sortModel, setSortModel] = useState([]);

  useEffect(() => {
    fetchMyConnection();
  }, [debouncedValue, paginationParams]);

  const columns = [
    {
      headerName: "Endpoint Username",
      field: "user",
      key: "user",
      flex: 1.8,
    },
    {
      headerName: "Jump Server Url",
      field: "publicIpAddress",
      key: "publicIpAddress",
      flex: 1.7,
    },
    {
      field: "endpoints",
      headerName: "Endpoints",
      key: "endpoints",
      flex: 1.5,
      renderCell: (tags) => (
        <span>
          {tags?.row?.endpoints?.map((tag) => {
            return (
              <Tag color="blue" key={tag}>
                {tag.hostname}
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      headerName: "Protocol",
      field: "protocol",
      key: "protocol",
      flex: 1,
    },
    { field: "status", headerName: "Status", key: "status", flex: 1 },
    {
      field: "connect",
      headerName: "Connect",
      key: "connect",
      flex: 1.5,
      renderCell: (record) => (
        <span>
          <Link
            disabled={record?.row?.status === "false" ? true : false}
            onClick={() => {
              api["info"]({
                message: "Entering Servlet:",
                description: "Please approve credentials to proceed and Session will be recorded.",
                placement: "topLeft",
              });
              return window.open(
                `http://${record?.row?.publicIpAddress}:8080/guacamole-servlet/?orgId=${ORG_ID()}&tenantId=${DOMAIN_ID()}&hostName=${
                  record?.row?.endpoints[0]?.publicIpAddress
                }&userName=${
                  record.row.user
                }&ipAddress=localhost&bearerToken=${AUTH_TOKEN()}&loggedInUser=${userEmail}&protocol=${
                  record.row.protocol
                }&hostlabel=${record.row.endpoints[0].hostname}`,
                `width=1024`,
                `height=768`
              );
            }}
          >
            Connect Now
          </Link>
        </span>
      ),
    },
  ];

  const fetchMyConnection = () => {
    const username = localStorage.getItem("UserName");

    let data = {
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      search,
      filter: {
        filterBy: "walletuser",
        value: username,
      },
      endpoints: [],
      token: AUTH_TOKEN(),
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_PAM_URL}/connections/listConnections`, data, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setConnectionData(
          res?.data?.jumpServerConnections.map((data, i) => ({
            ...data,
            status: data?.endpoints?.map((i) => (i.status === false ? "Inactive" : "Active")),
            id: i + 1,
          }))
        );
        setTotalCount(res?.data?.total);
        setCurrentPageModel({
          pageSize: 10,
          page: currentPage,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
          isNotification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          isNotification.open({
            type: "error",
            content: "Unable to fetch connections",
          });
        }
      });
  };

  return (
    <>
      {setIsNotification}
      {contextHolder}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">My Connections</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (paginationParams.current !== 1) {
                          setPaginationParams({ current: 1, pageSize: 10 });
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              {/* <Table
                loading={isLoading}
                columns={columns}
                dataSource={connectionData}
                pagination={{
                  showTotal: (total) => `Total ${total} items`,
                  ...paginationParams,
                  total: totalCount,
                  onChange: (page, pageSize) => {
                    setPaginationParams({
                      current: page,
                      pageSize: pageSize,
                    });
                  },
                }}
              /> */}
              <DataGrid
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={connectionData}
                columns={columns}
                paginationMode="server"
                rowCount={totalCount}
                loading={isLoading}
                sortModel={sortModel}
                onSortModelChange={(val) => setSortModel(val)}
                style={{ border: "none" }}
                paginationModel={currentPageModel}
                page={currentPage}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
                onPaginationModelChange={(params) => {
                  setCurrentPage(params.page);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default MyConnections;
