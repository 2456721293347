import { Col, Row, Select, Input, message, Collapse, Modal, Form, Button, Spin } from "antd";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactJoyride from "react-joyride";
import {
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API_TENANTS_URL,
  REACT_APP_API_DID_URL,
  AUTH_TOKEN,
} from "../../constants";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment-timezone";

const { Option } = Select;
const { Panel } = Collapse;

const TIME_ZONES = moment.tz.names().map((tz) => {
  const offset = moment.tz(tz).format("Z"); // Get the offset in the format "+05:30"
  return {
    label: `${tz} (GMT${offset})`, // Include offset in the label
    value: tz,
  };
});

const CustomVaultModal = (isModalOpen, handleCancel, handleOk) => {
  return (
    <Modal
      title="Enter the Custom Vault Configurations"
      open={isModalOpen}
      onCancel={handleCancel}
      centered={true}
      // onOk={handleOk}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={handleOk}
        style={{ paddingTop: "15px" }}
        initialValues={{
          port: 8200,
        }}
      >
        <Form.Item
          label="Root Token"
          name="rootToken"
          rules={[
            {
              required: true,
              message: "Enter root token",
            },
          ]}
        >
          <Input placeholder="Enter root token" />
        </Form.Item>

        <Form.Item
          label="IP Address"
          name="ipAddress"
          rules={[
            {
              required: true,
              message: "Enter IP address",
            },
          ]}
        >
          <Input placeholder="Enter IP address" />
        </Form.Item>
        <Form.Item label="Port" name="port">
          <Input disabled />
        </Form.Item>
        <Form.Item style={{ textAlign: "end" }}>
          <Button type="primary" htmlType="submit">
            Add
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const TenantConfig = () => {
  const [tenantData, setTenantData] = useState([]);
  const [activeKey, setActiveKey] = useState([]);
  const [notification, setNotification] = message.useMessage();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mfa = queryParams.get("mfa");
  const type = queryParams.get("type");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vaultDetails, setVaultDetails] = useState({
    rootToken: "",
    ipAddress: "",
    port: 8200,
    vaultType: "",
  });
  const [issuer, setIssuer] = useState([]);
  const [defaultIssuerId, setDefaultIssuerId] = useState();
  const [defaultIssuerName, setDefaultIssuerName] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [ditStatus, setDitStatus] = useState(null);
  const [tenantTimezone, setTenantTimezone] = useState("");

  const handleOk = async (e) => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: `${DOMAIN_ID()}`,
      vaulttype: "3",
      port: `${e.port}`,
      ipAddress: e.ipAddress,
      rootToken: e.rootToken,
    };

    try {
      const res = await axios.post(
        `${REACT_APP_API_DID_URL}/secretservice/createTenantVaultConfig`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      notification.open({
        type: "success",
        content: "Vault configuration added successfully",
      });

      if (res.data.code === 200) {
        setVaultDetails((prev) => ({
          ...prev,
          rootToken: e.rootToken,
          ipAddress: e.ipAddress,
          port: e.port,
        }));
      }

      setIsModalOpen(false);
    } catch (error) {
      console.log(error);
      notification.open({
        type: "error",
        content: "Vault configuration failed",
      });
    }
  };

  useEffect(() => {
    fetchTenantDetails();
    fetchIssuer();
  }, []);

  const [isOnBoarding, setIsOnboarding] = useState(false);
  const [run, setRun] = useState(true);

  useEffect(() => {
    //TODO : Onboarding api call done here
    setIsOnboarding(mfa ? true : false);
  }, []);

  const startTour = () => {
    const tourStep = [
      {
        // target: "#onboarding_step",
        content: (
          <span>
            <h4 style={{ marginBottom: "0" }}>What is a tenant config screen?</h4>
            <p>Tenant config screen allows you to:</p>
            <ol>
              <li>configure tenant mfa</li>
              <li>configure authentication mfa</li>
              <li>Decide how policies are enforced and more</li>
            </ol>
          </span>
        ),
        disableBeacon: true,
        placement: "center",
        target: "body",
        styles: {
          options: {
            width: 500,
          },
        },
        // title: "MFA Device",
      },
      {
        target: "#onboarding_step1",
        content: (
          <span>
            <h4 style={{ marginBottom: "0" }}>Select any MFA Device </h4>
            <br />
            Multi-Factor Authentication (MFA) adds an extra layer of security by requiring users to
            provide two or more verification factors before gaining access.
          </span>
        ),
        disableBeacon: true,

        // title: "MFA Device",
      },
      {
        target: "#onboarding_step2",
        content: (
          <span>
            {" "}
            <h4 style={{ marginBottom: "0" }}>Select any Endpoint Authentication MFA method </h4>
            <br />
            Selecting an Endpoint Authentication Multi-Factor Authentication (MFA) method involves
            choosing a specific approach to verify the identity of users during the authentication
            process. This step is crucial for enhancing security.{" "}
          </span>
        ),
        disableBeacon: true,
        placement: "right",

        // title: "Endpoint Authentication MFA",
      },
      {
        target: "#onboarding_step3",
        content: (
          <span>
            <h4 style={{ marginBottom: "0" }}> Select any Authentication Policy</h4>
            <br />
            Selecting an Authentication Policy involves defining rules and guidelines that dictate
            how user authentication is managed within a system. This policy helps shape the security
            framework and user access controls.{" "}
          </span>
        ),
        disableBeacon: true,
        // title: "Authentication Policy",
      },
    ];
    const tourPamLocal = [
      {
        target: "#onboarding_step4",
        content: (
          <span>
            <h4 style={{ marginBottom: "0" }}> Select any SSO MFA </h4>
            <br />
            Viewing audited policies involves examining a record of the authentication policies that
            have been audited within a system. This process provides insights into the historical
            performance, effectiveness, and outcomes of implemented security measures.
          </span>
        ),
        disableBeacon: true,
        // title: "MFA Device",
      },
    ];
    const handleTourCallback = ({ status }) => {
      if (status === "finished" || status === "skipped") {
        setRun(false);
      }
    };
    return (
      <ReactJoyride
        className="tour-container"
        steps={mfa ? tourStep : type === "pamLocal" ? tourPamLocal : tourStep}
        run={run}
        floaterProps={{ disableAnimation: true }}
        callback={handleTourCallback}
        continuous
        scrollToFirstStep
        disableScrollParentFix
        scrollOffset={100}
        showSkipButton
        showProgress
        hideCloseButton={true}
        styles={{
          buttonNext: {
            backgroundColor: "blue",
            color: "#ffffff",
          },
          buttonBack: {
            color: "#1677ff",
          },
        }}
      />
    );
  };

  const fetchTenantDetails = () => {
    let tenantPayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/getTenantDetail`, tenantPayload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res && res.data && res.data.data) {
          setTenantData(res.data?.data);
          setDitStatus(res?.data?.data?.is_dit_enabled === "0" ? "Disabled" : "Enabled");
          setTenantTimezone(res?.data?.data?.timezone);
          setVaultDetails((prev) => ({
            ...prev,
            vaultType:
              res?.data?.data?.vaultFlag === "1"
                ? "Decentralized Wallet"
                : res?.data?.data?.vaultFlag === "2"
                ? "Centralized Vault"
                : "Custom Vault",
          }));
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        notification.open({
          type: "error",
          content: `Unable to fetch tenant details`,
        });
      });
  };

  const updateVaultType = (e, val) => {
    let vaultTypePayload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      vault_type: e,
    };

    e === "Custom Vault" ? setIsModalOpen(true) : setIsModalOpen(false);

    setVaultDetails((prevState) => ({
      ...prevState,
      vaultType: e,
    }));

    axios
      .post(`${REACT_APP_API_DID_URL}/secretservice/createVaultFlag`, vaultTypePayload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `Vault type updated successful to ${val.children}`,
        });
      })
      .catch((err) => {
        console.log(err);
        notification.open({
          type: "error",
          content: `Vault type update failed.`,
        });
      });
  };

  const fetchIssuer = async () => {
    let payload = {
      orgId: ORG_ID(),
      domainId: DOMAIN_ID(),
      filter: "Issuer",
      pageNumber: 1,
      pageSize: 50,
    };
    try {
      const res = await axios.post(`${REACT_APP_API_DID_URL}/did/DIDList`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      });

      // null check
      if (res && res.data && Array.isArray(res.data.didList)) {
        setIssuer(res?.data?.didList);
        setDefaultIssuerName(res?.data?.didList[0]?.issuerName);
      } else {
        console.error("Response or didList is null or undefined.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetIssuer = (e) => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      issuerId: `${e}`,
    };

    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setDefaultIssuer`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `Default issuer set successfully`,
        });
      })
      .catch((err) => {
        console.log(err);
        notification.open({
          type: "error",
          content: `Couldn't set default issuer. Please try again.`,
        });
      });
  };

  // const updatePlatformMfa = (e) => {
  //   let platformmfaPayload = {
  //     email: localStorage.getItem("UserName"),
  //     // tenantId: localStorage.getItem("TenantId")
  //     tenantId: DOMAIN_ID(),
  //     orgId: ORG_ID(),
  //     platformmfa: e,
  //   };
  //   axios
  //     .post(`${REACT_APP_API_TENANTS_URL}/setPlatformMfa`, platformmfaPayload, {
  //       headers: {
  //         withCredentials: true,
  //         "X-Authorization": AUTH_TOKEN(),
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       notification.open({
  //         type: "success",
  //         content: "",
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const updateMfaDevices = (e, val) => {
    console.log({ e, val });
    let mfaDevicePayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      mfadevices: e,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setMfaDevices`, mfaDevicePayload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `MFA Device updated successful to ${val.children}`,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateSsoMfa = (e, val) => {
    let SsoMfaPayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      ssomfa: e,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setSsoMfa`, SsoMfaPayload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `Admin SSO MFA ${val.children} successful`,
        });
      })
      .catch((err) => {
        console.log(err);
        notification.open({
          type: "error",
          content: `Unable to update Admin SSO MFA`,
        });
      });
  };

  // const updateSsoMfaUser = (e, val) => {
  //   let SsoMfaPayload = {
  //     email: localStorage.getItem("UserName"),
  //     tenantId: DOMAIN_ID(),
  //     orgId: ORG_ID(),
  //     ssomfaenduser: e,
  //   };
  //   axios
  //     .post(`${REACT_APP_API_TENANTS_URL}/setSsoMfaEndUser`, SsoMfaPayload, {
  //       headers: {
  //         withCredentials: true,
  //         "X-Authorization": AUTH_TOKEN(),
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       notification.open({
  //         type: "success",
  //         content: `Enduser SSO MFA ${val.children} successful`,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       notification.open({
  //         type: "error",
  //         content: `Unable to update Enduser SSO MFA`,
  //       });
  //     });
  // };
  const updateAdminMFACache = (e, val) => {
    let adminMFACache = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      adminmfacache: e,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setAdminMfaCache`, adminMFACache, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `Push notification ${e === 0 ? "will" : "won't"} show on your wallet`,
        });
      })
      .catch((err) => {
        console.log(err);
        notification.open({
          type: "error",
          content: `Unable to update push notification for endpoint authentication`,
        });
      });
  };

  // const updateEnduserMFACache = (e, val) => {
  //   let enduserMFACache = {
  //     email: localStorage.getItem("UserName"),
  //     tenantId: DOMAIN_ID(),
  //     orgId: ORG_ID(),
  //     endusermfacache: e,
  //   };
  //   axios
  //     .post(`${REACT_APP_API_TENANTS_URL}/setEndUserMfaCache`, enduserMFACache, {
  //       headers: {
  //         withCredentials: true,
  //         "X-Authorization": AUTH_TOKEN(),
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       notification.open({
  //         type: "success",
  //         content: `Push notification ${e === 0 ? "will" : "won't"} show on your wallet`,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       notification.open({
  //         type: "error",
  //         content: `Unable to update push notification for endpoint authentication`,
  //       });
  //     });
  // };
  const updateAuthenticationPolicy = (e, val) => {
    let AuthenticationPolicyPayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      authenticationpolicy: e,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setAuthenticationPolicy`, AuthenticationPolicyPayload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `Authentication Policy updated successful to ${val.children}`,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const updateEntityAuthentication = (e, val) => {
  //   let EntityAuthenticationPayload = {
  //     email: localStorage.getItem("UserName"),
  //     tenantId: DOMAIN_ID(),
  //     orgId: ORG_ID(),
  //     entityauthentication: e,
  //   };
  //   axios
  //     .post(`${REACT_APP_API_TENANTS_URL}/setEntityAuthentication`, EntityAuthenticationPayload, {
  //       headers: {
  //         withCredentials: true,
  //         "X-Authorization": AUTH_TOKEN(),
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       notification.open({
  //         type: "success",
  //         content: `Endpoint Authentication MFA updated successful to ${val.children}`,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const updateConnectionMode = (e, val) => {
    let ConnectionModePayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      connectionmode: e,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setConnectionMode`, ConnectionModePayload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `Connection Mode updated successful to ${val.children}`,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateAuthenticationMechanism = (e, val) => {
    let authMechPayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      credentialstore: e,
    };

    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setCredentialMode`, authMechPayload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `Credential Mode updated successful to ${val.children}`,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCredentialStore = (e, val) => {
    let CredentialStorePayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      credentialstore: e,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setCredentialStore`, CredentialStorePayload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `Credential Store updated successful to ${val.children}`,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateCredentialShareMode = (e, val) => {
    let CredentialSharePayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      credentialsharemode: e,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setCredentialShareMode`, CredentialSharePayload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `Share Credentials updated successful to ${val.children}`,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateDITStatus = async (e) => {
    setDitStatus(e);
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      status: e,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setDITStatus`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        console.log(res);
        notification.open({
          type: "success",
          content: `DIT Status updated successful to ${e}`,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateTenantTimezone = async (e) => {
    setTenantTimezone(e);
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      timezone: e,
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/setTenantTimeZone`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        console.log(res);
        localStorage.setItem("timezone", e);
        notification.open({
          type: "success",
          content: `Timezone updated successful to ${e}`,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {setNotification}
      {CustomVaultModal(isModalOpen, () => setIsModalOpen(false), handleOk)}
      <div className="onboarding-container">
        {isOnBoarding && startTour()}

        <Row className="content-conatiner">
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <h2 className="title">Tenant Config</h2>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={6}>
                <p className="search-label">Tenant Name</p>
                <Input placeholder="Enter tenant name" value={tenantData?.tenantname} disabled />
              </Col>
            </Row>

            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                  height: "100%",
                }}
              >
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                      }}
                      spin
                    />
                  }
                />
              </div>
            ) : (
              <>
                <Collapse
                  // accordion
                  style={{ marginTop: "2rem" }}
                  defaultActiveKey={mfa ? ["1", "4"] : type === "pamLocal" ? ["1"] : []}
                >
                  <Panel header="MFA device + MFA settings" key="1">
                    {" "}
                    <Row gutter={16} style={{ marginLeft: "1rem" }}>
                      <Col span={24}>
                        <Row gutter={16}>
                          <Col span={6} id="onboarding_step1">
                            <p className="search-label">MFA Device</p>

                            <Select
                              defaultValue={tenantData.mfadevices}
                              onChange={updateMfaDevices}
                            >
                              <Option value={1}>Decentralized wallet</Option>
                            </Select>
                          </Col>
                        </Row>

                        <Row gutter={16}>
                          <Col span={6} id="onboarding_step4">
                            <p className="search-label">Select SSO MFA</p>

                            <Select
                              defaultValue={tenantData.ssomfa}
                              onChange={(e, val) => {
                                updateSsoMfa(e, val);
                              }}
                              placeholder="Select SSO MFA"
                              disabled
                            >
                              <Option value={3}>Enabled</Option>
                              <Option value={0}>Disabled</Option>
                            </Select>
                          </Col>
                          <Col span={8}>
                            <p className="search-label" style={{ marginLeft: "9px" }}>
                              Use SSO MFA Caching for Endpoint Authentication{" "}
                              {/* <Tooltip title="This means endpoint authentication notifications will be disabled when set as 'yes' and enabled when set as 'no'">
                                <InfoCircleOutlined />
                              </Tooltip> */}
                            </p>
                            <Col span={20}>
                              <Select
                                defaultValue={tenantData.adminmfacache}
                                onChange={updateAdminMFACache}
                              >
                                {/* NOTE: dont show push notification */}
                                <Option value={3}>Enabled</Option>
                                {/* NOTE : Show push notification */}
                                <Option value={0}>Disabled</Option>
                              </Select>
                            </Col>
                          </Col>
                        </Row>
                        {/* <Row gutter={16}>      
                      <Col span={6}>
                        <p className="search-label">
                          {" "}
                          Select SSO MFA(Enduser Console)
                        </p>

                        <Select
                          defaultValue={tenantData.ssomfaenduser}
                          onChange={(e, val) => {
                            updateSsoMfaUser(e, val);
                          }}
                        >
                          <Option value={3}>Enable</Option>
                          <Option value={0}>Disable</Option>
                        </Select>
                      </Col>
                      <>
                        <Col span={8}>
                          <p
                            className="search-label"
                            style={{ marginLeft: "9px" }}
                          >
                            Show Push Notification for Endpoint Authentication
                          </p>

                          <Col span={20}>
                            <Select
                              defaultValue={tenantData.endusermfacache}
                              onChange={updateEnduserMFACache}
                            >
                              NOTE : yes show push notification(mfa cache True)
                              <Option value={0}>Yes</Option>
                              NOTE: dont show push notification(mfa cache False)
                              <Option value={3}>No</Option>
                            </Select>
                          </Col>
                        </Col>
                      </>
                    </Row> */}

                        {/* <Col span={6}> Below fields will needed in future
                        <p className="search-label">Service Account MFA</p>

                        <Select defaultValue={1}>
                          <Option value={0}>No MFA - use service account policie</Option>
                          <Option value={1}>
                            Use MFA for services (defaults to application MFA)
                          </Option>
                        </Select>
                      </Col> */}
                        {/* Below fields will needed in future */}

                        <Row gutter={16}>
                          {/* <Col span={6}>
                        <p className="search-label">Application MFA (multi-select)</p>

                        <Select
                          defaultValue={
                            tenantData?.platformmfa === 0 ? "" : tenantData?.platformmfa
                          }
                          onChange={(e) => {
                            updatePlatformMfa(e);
                          }}
                        >
                          <Option value={1}>No MFA (use sso caching) </Option>
                          <Option value={2}>Decentralized credentials </Option>
                          <Option value={3}>Biometric MFA on all auth</Option>
                          <Option value={4}>App based MFA</Option>
                        </Select>
                      </Col> */}
                          {/* <Col span={6}>
                        <span id="onboarding_step2">
                          <p className="search-label">
                            AD / Kerberos / Endpoint Authentication MFA
                          </p>

                          <Select
                            defaultValue={
                              tenantData?.entityauthentication === 0
                                ? ""
                                : tenantData?.entityauthentication
                            }
                            onChange={updateEntityAuthentication}
                          >
                            <Option value={1}>No MFA (use sso caching) </Option>
                            <Option value={2}>Credential + Biometric MFA on all auth </Option>
                            <Option value={3}>Credential + OTP on all auth</Option>
                          </Select>
                        </span>
                      </Col> */}
                        </Row>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel header="Application Control" key="2">
                    {" "}
                    <Row gutter={16} style={{ marginLeft: "1rem" }}>
                      <Col span={12}>
                        <p className="search-label">Action</p>

                        <Select defaultValue={1}>
                          <Option value={1}>
                            Audit everything (audit and generate policies automatically)
                          </Option>
                          <Option value={2}>
                            Live mode (enforcement mode) also a zero trust mode when uac is on. New
                            policies are also created in blocked status{" "}
                          </Option>
                          <Option value={3}>
                            Policy mode (simulate enforcement and generate new policies). New
                            policies are created in blocked status
                          </Option>
                        </Select>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel header="Credential store and sharing" key="3">
                    <Row gutter={16} style={{ marginLeft: "1rem" }}>
                      <Col span={6}>
                        <p className="search-label">Credential Store</p>

                        <Select
                          defaultValue={tenantData?.credentialstore}
                          onChange={updateCredentialStore}
                        >
                          <Option value={1}>Decentralize</Option>
                          {/* Options will needed in future */}
                          {/* <Option value={2}>Use a vault</Option> */}
                        </Select>
                      </Col>
                      <Col span={6}>
                        <p className="search-label">Share Credentials</p>

                        <Select
                          defaultValue={tenantData?.credentialsharemode}
                          onChange={updateCredentialShareMode}
                          // mode="multiple"
                        >
                          <Option value={1}>Share to email </Option>
                          <Option value={2}>Share to wallets </Option>
                          <Option value={3}>Share via SSO only</Option>
                        </Select>
                      </Col>
                      <Col span={6}>
                        <p className="search-label">Credential Mode</p>

                        <Select
                          onChange={updateAuthenticationMechanism}
                          defaultValue={tenantData?.credentialstore}
                        >
                          <Option value={0}>Default</Option>
                          <Option value={1}>Policy</Option>
                        </Select>
                      </Col>

                      <Col span={6}>
                        <p className="search-label">Default Issuer</p>

                        <Select
                          onChange={handleSetIssuer}
                          placeholder="Select Default Issuer"
                          value={defaultIssuerName}
                        >
                          {issuer.map((issuer) => (
                            <Option value={issuer.id}>{issuer.issuerName}</Option>
                          ))}
                        </Select>
                      </Col>

                      {/* <Col span={6}>
                    <p className="search-label">Service Account MFA</p>

                    <Select>
                      <Option value={1}>No MFA - use service account policie</Option>
                      <Option value={2}>Use MFA for services (defaults to application MFA)</Option>
                    </Select>
                  </Col> */}
                    </Row>
                  </Panel>
                  <Panel header="Authentication Policy" key="4">
                    <Row gutter={16} style={{ marginLeft: "1rem" }}>
                      <Col span={12} id="onboarding_step3">
                        <p className="search-label">Authentication Policy</p>

                        <Select
                          defaultValue={tenantData?.authenticationpolicy}
                          onChange={updateAuthenticationPolicy}
                        >
                          <Option value={1}>
                            Audit - this will audit existing policies and create new policies (learn
                            the system)
                          </Option>
                          <Option value={2}>
                            Policy - Simulate enforcement policies and see the impact of blocked
                            policies
                          </Option>
                          <Option value={3}>
                            Live mode - Zero trust (block everything but trusted) - warning this
                            requires policies to be available
                          </Option>
                        </Select>
                      </Col>
                    </Row>
                  </Panel>

                  {/* Below fields will needed in future */}

                  {/* <Panel header="Service Account" key="5">
                <Row gutter={16} style={{ marginLeft: "1rem" }}>
                  <Col span={6}>
                    <p className="search-label">Discovery of service accounts</p>

                    <Select>
                      <Option value={0}>Discover and manage with policies</Option>
                      <Option value={1}> Ignore service accounts</Option>
                    </Select>
                  </Col>
                  <Col span={6}>
                    <p className="search-label">Authentication</p>

                    <Select>
                      <Option value={1}>
                        Zero trust (block everything but trusted) - warning this requires policies
                        to be available{" "}
                      </Option>
                      <Option value={2}>
                        {" "}
                        Audit Mode - this will audit existing policies and create new policies
                      </Option>
                      <Option value={3}>
                        Policy - Simulate enforcement policies and see the impact of blocked
                        policies
                      </Option>
                    </Select>
                  </Col>
                </Row>
              </Panel> */}
                  {/* Below fields will needed in future */}

                  {/* <Panel header="Revoke local admin credentials" key="6">
                {" "}
                <Row gutter={16} style={{ marginLeft: "1rem" }}>
                  <Col span={6}>
                    <p className="search-label">Windows</p>

                    <Select>
                      <Option value={0}>Revoke admin credentials</Option>
                      <Option value={1}> Ignore</Option>
                    </Select>
                  </Col>
                  <Col span={6}>
                    <p className="search-label">Linux</p>

                    <Select>
                      <Option value={0}>Revoke admin credentials</Option>
                      <Option value={1}>Ignore</Option>
                    </Select>
                  </Col>
                </Row>
              </Panel> */}
                  <Panel header="Connection" key="7">
                    {" "}
                    <Row gutter={16} style={{ marginLeft: "1rem" }}>
                      <Col span={6}>
                        <p className="search-label">Connection Mode</p>

                        <Select
                          defaultValue={tenantData?.connectionmode}
                          onChange={updateConnectionMode}
                        >
                          <Option value={1}>Jump Server</Option>
                          <Option value={2}> Direct</Option>
                          <Option value={3}> Direct and Jump Server</Option>
                        </Select>
                      </Col>
                    </Row>
                  </Panel>
                  {/* Below fields will needed in future */}

                  {/* <Panel header="Risk based mfa and dynamic authz" key="8">
                {" "}
                <Row gutter={16} style={{ marginLeft: "1rem" }}>
                  <Col span={6}>
                    <p className="search-label">Enable risk based mfa</p>

                    <Select>
                      <Option value={0}>Yes</Option>
                      <Option value={1}> No</Option>
                    </Select>
                  </Col>
                  <Col span={6}>
                    <p className="search-label">Enable dynamic authz</p>

                    <Select>
                      <Option value={1}>Yes</Option>
                      <Option value={2}>No</Option>
                    </Select>
                  </Col>
                </Row>
              </Panel> */}
                  {/* <Panel header="Authentication Mechanism" key="8">
                {" "}
                <Row gutter={16} style={{ marginLeft: "1rem" }}>
                  <Col span={6}>
                    <p className="search-label">Authentication Mode</p>

                    <Select onChange={updateAuthenticationMechanism}>
                      <Option value={0}>Default</Option>
                      <Option value={1}>Policy</Option>
                    </Select>
                  </Col>
                </Row>
              </Panel> */}
                  <Panel header=" Enable Direct AD authentication without policies" key="9">
                    {" "}
                    <Row gutter={16} style={{ marginLeft: "1rem" }}>
                      <Col span={6}>
                        <p className="search-label">Enable Ad domain authentication</p>

                        <Select defaultValue={0}>
                          <Option value={1}>Yes</Option>
                          <Option value={0}> No</Option>
                        </Select>
                      </Col>
                      <Col span={6}>
                        <p className="search-label">Enable dynamic authz</p>

                        <Select defaultValue={0}>
                          <Option value={1}>Yes</Option>
                          <Option value={0}>No</Option>
                        </Select>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel header="Secret Management" key="10">
                    <Row gutter={16} style={{ marginLeft: "1rem" }}>
                      <Col span={12}>
                        <p className="search-label">Vault Type</p>

                        <Select
                          placeholder="Select vault type"
                          onChange={updateVaultType}
                          value={vaultDetails.vaultType}
                        >
                          <Option value="Decentralized Wallet">
                            Decentralized Wallet (Secrets will be maintained in Wallet)
                          </Option>
                          <Option value="Centralized Vault">
                            Centralized Vault (Secrets will be maintained in Authnull Vault)
                          </Option>
                          <Option value="Custom Vault">
                            Custom Vault (Secrets will be maintained in Customer's Vault)
                          </Option>
                        </Select>
                      </Col>
                      {vaultDetails.vaultType === "Custom Vault" &&
                        vaultDetails.rootToken.length > 0 && (
                          <Col span={12}>
                            <div
                              style={{
                                padding: "10px",
                                paddingLeft: "20px",
                                backgroundColor: "#efefef",
                                borderRadius: "10px",
                              }}
                            >
                              <p className="search-label">Vault Details</p>
                              <p style={{ marginBottom: 0, marginTop: 0 }}>
                                Vault Type: {vaultDetails.vaultType}
                              </p>
                              <p style={{ marginBottom: 0, marginTop: 0 }}>
                                Root token: {vaultDetails.rootToken}
                              </p>
                              <p style={{ marginBottom: 0, marginTop: 0 }}>
                                IP Address: {vaultDetails.ipAddress}
                              </p>
                              <p style={{ marginTop: 0 }}>Port: {vaultDetails.port}</p>
                            </div>
                          </Col>
                        )}
                    </Row>
                  </Panel>
                  <Panel header="Dynamic Identity Tapestry" key="11">
                    <Row gutter={16} style={{ marginLeft: "1rem" }}>
                      <Col span={6}>
                        <p className="search-label">Authorization Policy</p>

                        <Select
                          placeholder="Select Authorization Policy"
                          onChange={updateDITStatus}
                          value={ditStatus}
                          options={[
                            { label: "Enabled", value: "Enabled" },
                            { label: "Disabled", value: "Disabled" },
                          ]}
                        ></Select>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel header="Timezone Configuration" key="12">
                    <Row gutter={16} style={{ marginLeft: "1rem" }}>
                      <Col span={6}>
                        <p className="search-label">Select Timezone</p>

                        <Select
                          showSearch
                          value={tenantTimezone}
                          placeholder="Select Time Zone"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                          }
                          onChange={updateTenantTimezone}
                        >
                          {TIME_ZONES.map((tz) => (
                            <Option key={tz.value} value={tz.value}>
                              {tz.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TenantConfig;
