import { Col, Input, Row, message, Button, Form, Typography, Checkbox } from "antd";
import React, { useState } from "react";
import { AUTH_TOKEN, REACT_APP_API_TENANTS_URL } from "../../constants";
import axios from "axios";
// import { useLocation } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import "../../styles/Unauthorized/signup.scss";
// import broadcomLogo from "../../assets/broadcom.png";
// import emaptaLogo from "../../assets/emapta.png";
// import comacMedLogo from "../../assets/comac-med.png";
// import meritexLogo from "../../assets/meritex.jpg";
import googleCloudLogo from "../../assets/google-cloud.png";
import nvidiaLogo from "../../assets/nvidia.jpg";
import comcastLogo from "../../assets/comcast.jpg";
import microsoftLogo from "../../assets/microsoft.png";

// import { Form, Input, Button, Select, Checkbox, Typography } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

const { Title, Paragraph, Text } = Typography;

const Signup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  const [notification, setNotification] = message.useMessage();

  // const UserId = queryParams.get("userId");

  const fetchSignup = (e) => {
    let pageDetails = {
      ...e,
    };
    setIsLoading(true);

    axios
      .post(`${REACT_APP_API_TENANTS_URL}/orgsignup`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        notification.open({
          type: "success",
          content: "User registered successfully. Please check you registered email to verify",
        });
        form.resetFields();
        setTimeout(() => {
          setIsLoading(false);
          window.location.replace("/org/success-signup");
        }, 1000);
        //setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Error during signup:", err);
      });
  };

  const validateEmailAndOrg = (mail, org) => {
    const validationData = {
      orgname: org ? org : "",
      email: mail ? mail : "",
    };

    return axios.post(`${REACT_APP_API_TENANTS_URL}/validateemailandorgname`, validationData, {
      headers: {
        withCredentials: true,
      },
    });
  };

  return (
    <div
      style={{
        background:
          "linear-gradient(to bottom right, rgb(251, 253, 255), rgb(243, 251, 255), rgb(221, 242, 253))",
        minHeight: "100vh",
      }}
    >
      {setNotification}

      <div style={styles.container}>
        <div style={styles.content}>
          <div style={styles.leftSection}>
            <Title level={1} style={styles.mainTitle}>
              Get Started with Authnull
            </Title>
            <Paragraph style={styles.subtitle}>
              Seamlessly secure Linux, Windows, Radius, and Databases with AI-powered access
              control—effortless, adaptive, and friction-free.
            </Paragraph>
            <div style={styles.bulletPoints}>
              <div style={styles.bulletItem}>
                <ArrowRightOutlined style={styles.bulletIcon} />
                <Text>Zero-Friction MFA</Text>
              </div>
              <div style={styles.bulletItem}>
                <ArrowRightOutlined style={styles.bulletIcon} />
                <Text>AI Policy Co-Pilot</Text>
              </div>
              <div style={styles.bulletItem}>
                <ArrowRightOutlined style={styles.bulletIcon} />
                <Text>Privileged Access</Text>
              </div>
              <div style={styles.bulletItem}>
                <ArrowRightOutlined style={styles.bulletIcon} />
                <Text>Conditional Access</Text>
              </div>
              <div style={styles.bulletItem}>
                <ArrowRightOutlined style={styles.bulletIcon} />
                <Text>Cross-platform Protection</Text>
              </div>
            </div>
            <hr
              style={{
                color: "#d1d1d1",
                backgroundColor: "#d1d1d1",
                height: "1px",
                border: "none",
              }}
            />
            <div style={styles.clientLogos}>
              {/* <img src={broadcomLogo} alt="broadcom" style={{ opacity: 0.8 }} />
              <img src={emaptaLogo} alt="emapta" style={{ opacity: 0.8 }} />
              <img
                src={comacMedLogo}
                alt="comac medical"
                style={{ opacity: 0.8, height: "75px" }}
              />
              <img src={meritexLogo} alt="meritex logo" style={{ opacity: 0.8, height: "75px" }} /> */}

              <img src={nvidiaLogo} alt="nvidia logo" style={{ opacity: 0.7, height: "35px" }} />
              <img src={comcastLogo} alt="Comcast logo" style={{ opacity: 0.7, height: "35px" }} />
              <img
                src={microsoftLogo}
                alt="Microsoft logo"
                style={{ opacity: 0.7, height: "55px" }}
              />
              <img
                src={googleCloudLogo}
                alt="google cloud logo"
                style={{ opacity: 0.7, height: "25px" }}
              />
            </div>
          </div>

          <div style={styles.rightSection}>
            <div style={styles.formContainer}>
              <Form
                form={form}
                name="register"
                onFinish={fetchSignup}
                layout="vertical"
                requiredMark={false}
              >
                <h2>Register with AuthNull</h2>

                <p style={{ color: "#bababa" }}>
                  <i>All fields are required unless marked as (Optional).</i>
                </p>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="firstName"
                      label="First Name*"
                      rules={[{ required: true, message: "Enter your first name!" }]}
                    >
                      <Input placeholder="John" style={styles.input} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="lastName"
                      label="Last Name*"
                      rules={[{ required: true, message: "Enter your last name!" }]}
                    >
                      <Input placeholder="Doe" style={styles.input} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="email"
                      label="Email*"
                      rules={[
                        { required: true, message: "Enter your email!" },
                        {
                          validator(rule, value) {
                            return new Promise(async (resolve, reject) => {
                              if (value) {
                                const emailRegex =
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                                const isValid = emailRegex.test(value.trim());
                                if (isValid) {
                                  try {
                                    const resEmail = await validateEmailAndOrg(value);
                                    resEmail?.data?.code === 400
                                      ? reject(
                                          "This email is already registered. Please use a different email."
                                        )
                                      : resolve();
                                  } catch {}
                                } else {
                                  reject("Enter valid email address");
                                }
                              } else {
                                reject();
                              }
                            });
                          },
                        },
                      ]}
                    >
                      <Input placeholder="name@company.com" style={styles.input} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="orgName"
                      label="Organization*"
                      rules={[
                        { required: true, message: "Enter your organization!" },
                        {
                          pattern: /^[a-zA-Z0-9\s]+$/,
                          message:
                            "No special characters allowed (only letters, numbers, and spaces)",
                        },
                        {
                          validator(rule, value) {
                            return new Promise(async (resolve, reject) => {
                              if (value) {
                                try {
                                  const resEmail = await validateEmailAndOrg("", value);
                                  resEmail?.data?.code === 400
                                    ? reject("This Organization is already registered.")
                                    : resolve();
                                } catch {}
                              } else {
                                reject();
                              }
                            });
                          },
                        },
                      ]}
                    >
                      <Input placeholder="Your company" style={styles.input} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="password"
                      label="Password*"
                      rules={[{ required: true, message: "Enter your password!" }]}
                    >
                      <Input.Password
                        placeholder="Password"
                        style={styles.input}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="confirmpassword"
                      label="Confirm Password*"
                      dependencies={["password"]}
                      rules={[
                        { required: true, message: "Confirm your password!" },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error("The two passwords do not match!"));
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder="Confirm password"
                        style={styles.input}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(new Error("You must accept the terms")),
                    },
                  ]}
                >
                  <Checkbox>
                    I agree to the <a href="https://authnull.com/terms">Terms of Service</a> and{" "}
                    <a href="https://authnull.com/privacy-policy">Privacy Policy</a>.
                  </Checkbox>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    style={styles.submitButton}
                  >
                    Register
                  </Button>
                </Form.Item>

                <div style={{ display: "flex", alignItem: "center", marginBottom: "2rem" }}>
                  <span>
                    Already have an account?{" "}
                    <a style={{ color: "blue" }} href="/org/login">
                      Log in here
                    </a>
                  </span>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "50px 20px",
  },

  content: {
    display: "flex",
    gap: "60px",
    alignItems: "flex-start",
  },
  leftSection: {
    flex: "1",
    paddingRight: "20px",
  },
  rightSection: {
    flex: "1",
  },
  mainTitle: {
    fontSize: "42px",
    lineHeight: "1.2",
    marginBottom: "20px",
    fontWeight: "semibold",
  },
  subtitle: {
    fontSize: "16px",
    color: "#666",
    marginBottom: "30px",
  },
  bulletPoints: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    marginBottom: "40px",
  },
  bulletItem: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  bulletIcon: {
    color: "rgb(25, 111, 241)",
  },
  clientLogos: {
    display: "flex",
    gap: "30px",
    alignItems: "center",
  },
  formContainer: {
    backgroundColor: "white",
    padding: "30px",
    border: "1px solid rgb(216, 216, 216)",
    borderRadius: "10px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
  },
  input: {
    height: "45px",
    borderRadius: "6px",
  },
  submitButton: {
    width: "100%",
    height: "45px",
    borderRadius: "8px",
    border: "none",
    fontSize: "16px",
  },
};

export default Signup;
