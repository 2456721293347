import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Select,
  TimePicker,
  DatePicker,
  Button,
  Tooltip,
  message,
  Typography,
  Card,
  Radio,
  Checkbox,
  Modal,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom"; // 1. Import useNavigate

const { Text } = Typography;
const { Option } = Select;

// Utility functions for localStorage operations
const getLocalStorageData = (key) => {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error(`Error parsing localStorage key "${key}":`, error);
    return null;
  }
};

const setLocalStorageData = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error setting localStorage key "${key}":`, error);
  }
};

const TIME_ZONES = moment.tz.names().map((tz) => {
  const offset = moment.tz(tz).format("Z"); // Get the offset in the format "+05:30"
  return {
    label: `${tz} (GMT${offset})`, // Include offset in the label
    value: tz,
  };
});

// Define daysOfWeekOptions to fix the ReferenceError
const daysOfWeekOptions = [
  { label: "Mon", value: "Monday" },
  { label: "Tue", value: "Tuesday" },
  { label: "Wed", value: "Wednesday" },
  { label: "Thu", value: "Thursday" },
  { label: "Fri", value: "Friday" },
  { label: "Sat", value: "Saturday" },
  { label: "Sun", value: "Sunday" },
];

const TimeBoundAccess = ({ onNavigateNext, onCreatePolicy, loadingPolicy }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const [form] = Form.useForm();
  const [isTimeBound, setIsTimeBound] = useState(true);
  const [accessType, setAccessType] = useState("One Time"); // 'One Time' or 'Recurring'
  const [loading, setLoading] = useState(false);
  const [notification, notificationHolder] = message.useMessage();
  const navigate = useNavigate(); // 2. Initialize useNavigate
  const [policySaved, setPolicySaved] = useState(false); // For indicating policy save status
  const [payload, setPayload] = useState();
  const isEditMode = searchParams.get("edit") === "true";

  useEffect(() => {
    // Load central policy data from localStorage on mount
    const basicPolicyData = getLocalStorageData("policyDraft.basicPolicyInformation");

    if (basicPolicyData) {
      const { policyJson } = basicPolicyData;
      const dit = policyJson?.dit || {};
      const schedule = dit.schedule || {};
      let initialValues = {};

      if (schedule.type === "jit") {
        initialValues = {
          isTimeBound: true,
          accessType: "One Time",
          startDate: schedule.value.startTime ? moment(schedule.value.startTime) : null,
          startTime: schedule.value.startTime ? moment(schedule.value.startTime) : null,
          endDate: schedule.value.endTime ? moment(schedule.value.endTime) : null,
          endTime: schedule.value.endTime ? moment(schedule.value.endTime) : null,
          timeZone: schedule.value.time_zone || moment.tz.guess(),
        };
        setIsTimeBound(true);
        setAccessType("One Time");
      } else if (schedule.type === "recurring") {
        initialValues = {
          isTimeBound: true,
          accessType: "Recurring",
          daysOfWeek: schedule.value.days
            ? schedule.value.days.map((day) => {
                const dayMapping = {
                  0: "Sunday",
                  1: "Monday",
                  2: "Tuesday",
                  3: "Wednesday",
                  4: "Thursday",
                  5: "Friday",
                  6: "Saturday",
                };
                return dayMapping[day];
              })
            : [],
          startTime: schedule.value.startTime ? moment(schedule.value.startTime) : null,
          endTime: schedule.value.endTime ? moment(schedule.value.endTime) : null,
          timeZone: schedule.value.time_zone || moment.tz.guess(),
        };
        setIsTimeBound(true);
        setAccessType("Recurring");
      } else {
        // If no schedule, set defaults
        initialValues = {
          isTimeBound: false,
        };
        setIsTimeBound(false);
      }

      form.setFieldsValue(initialValues);
    } else {
      // If no basic policy data, prompt user to complete basic policy first
      message.error("Primary Information is missing. Please complete it first.");
    }
  }, [form]);

  const handleSavePolicy = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      const basicPolicyDraft = getLocalStorageData("policyDraft.basicPolicyInformation");
      if (!basicPolicyDraft) {
        message.error("Primary Information is missing. Please complete it first.");
        setLoading(false);
        return null;
      }

      const basicPolicyData = { ...basicPolicyDraft };
      const policyJson = { ...basicPolicyData.policyJson };
      const dit = { ...policyJson.dit };

      let schedule = null;
      if (values.isTimeBound) {
        if (values.accessType === "One Time") {
          const { startDate, startTime, endDate, endTime, timeZone } = values;
          const startMoment = moment.tz(
            `${startDate.format("YYYY-MM-DD")} ${startTime.format("HH:mm")}`,
            "YYYY-MM-DD HH:mm",
            timeZone
          );
          const endMoment = moment.tz(
            `${endDate.format("YYYY-MM-DD")} ${endTime.format("HH:mm")}`,
            "YYYY-MM-DD HH:mm",
            timeZone
          );

          schedule = {
            type: "jit",
            value: {
              startTime: startMoment.valueOf(),
              endTime: endMoment.valueOf(),
              time_zone: timeZone,
            },
          };
        } else if (values.accessType === "Recurring") {
          const { daysOfWeek: days, startTime, endTime, timeZone } = values;
          const dayMapping = {
            Sunday: 0,
            Monday: 1,
            Tuesday: 2,
            Wednesday: 3,
            Thursday: 4,
            Friday: 5,
            Saturday: 6,
          };
          const mappedDays = days.map((day) => dayMapping[day]);

          schedule = {
            type: "recurring",
            value: {
              days: mappedDays,
              startTime: startTime.valueOf(),
              endTime: endTime.valueOf(),
              time_zone: timeZone,
            },
          };
        }
      }

      if (schedule) {
        dit.schedule = schedule;
      } else {
        delete dit.schedule;
      }

      const updatedPolicyJson = { ...policyJson, dit };
      const updatedBasicPolicyData = {
        ...basicPolicyData,
        policyJson: updatedPolicyJson,
      };

      setLocalStorageData("policyDraft.basicPolicyInformation", updatedBasicPolicyData);

      setLoading(false);
      return updatedBasicPolicyData;
    } catch (error) {
      setLoading(false);
      if (error.name === "Error") {
        message.error(error.message || "Validation error.");
      } else {
        message.error("Failed to save policy.");
      }
      return null;
    }
  };

  const handleSaveAndNext = async () => {
    const updatedData = await handleSavePolicy();
    if (updatedData) {
      onNavigateNext();
    }
  };

  const handleSaveAndCreate = async () => {
    const updatedData = await handleSavePolicy();
    if (updatedData) {
      onCreatePolicy({
        orgId: updatedData.orgId,
        tenantId: updatedData.tenantId,
        policyName: updatedData.policyName,
        policyType: updatedData.policyType,
        policyJson: updatedData.policyJson,
      });
    }
  };

  const handleSaveOnly = async () => {
    await handleSavePolicy();
  };
  const handleCancel = () => {
    Modal.confirm({
      title: "Are you sure you want to cancel?",
      content: "All unsaved changes will be lost.",
      onOk: () => {
        localStorage.removeItem("policyDraft.basicPolicyInformation");
        localStorage.removeItem("formSessionData");
        localStorage.removeItem("editPolicyData");
        navigate("/pam/policy/v2");
      },
    });
  };

  return (
    <div>
      {notificationHolder}
      <Card>
        <Form form={form} layout="vertical">
          <Form.Item>
            <Row align="middle">
              <Col span={8}>
                <span>
                  Is this policy Timebound?{" "}
                  <Tooltip title="Select if the policy is timebound or not.">
                    <InfoCircleOutlined />
                  </Tooltip>
                </span>
              </Col>
              <Col span={16}>
                <Form.Item
                  name="isTimeBound"
                  initialValue={isTimeBound}
                  rules={[
                    {
                      required: true,
                      message: "Please select if policy is timebound",
                    },
                  ]}
                  noStyle
                >
                  <Radio.Group onChange={(e) => setIsTimeBound(e.target.value)} value={isTimeBound}>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          {isTimeBound && (
            <>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <span>
                        What type of access do they need?{" "}
                        <Tooltip title="Choose whether the access is a one-time event or occurs regularly.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="accessType"
                    rules={[
                      {
                        required: true,
                        message: "Please select an access type",
                      },
                    ]}
                  >
                    <Select
                      value={accessType}
                      onChange={(value) => setAccessType(value)}
                      placeholder="Select Access Type"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      <Option value="One Time">One Time</Option>
                      <Option value="Recurring">Recurring</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {accessType === "One Time" && (
                <>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label={
                          <span>
                            Start Date{" "}
                            <Tooltip title="Select the start date for access.">
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        name="startDate"
                        rules={[
                          {
                            required: true,
                            message: "Please select a start date",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format="YYYY-MM-DD"
                          placeholder="Select Start Date"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={
                          <span>
                            Start Time{" "}
                            <Tooltip title="Select the start time for access.">
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        name="startTime"
                        rules={[
                          {
                            required: true,
                            message: "Please select a start time",
                          },
                        ]}
                      >
                        <TimePicker
                          use12Hours
                          format="h:mm a"
                          placeholder="Select Start Time"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label={
                          <span>
                            End Date{" "}
                            <Tooltip title="Select the end date for access. Must be after the start date.">
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        name="endDate"
                        dependencies={["startDate"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select an end date",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const startDate = getFieldValue("startDate");
                              if (!value || !startDate || value.isAfter(startDate)) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error("End date must be after start date"));
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format="YYYY-MM-DD"
                          placeholder="Select End Date"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={
                          <span>
                            End Time{" "}
                            <Tooltip title="Select the end time for access. Must be at least 1 hour after the start time if on the same day.">
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        name="endTime"
                        dependencies={["startDate", "startTime", "endDate"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select an end time",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const startDate = getFieldValue("startDate");
                              const endDate = getFieldValue("endDate");
                              const startTime = getFieldValue("startTime");
                              const endTime = value;

                              if (!startDate || !endDate || !startTime || !endTime) {
                                return Promise.resolve();
                              }

                              // If end date is after start date, no need to check time
                              if (endDate.isAfter(startDate, "day")) {
                                return Promise.resolve();
                              }

                              // If end date is the same as start date, ensure end time is at least 1 hour after start time
                              if (endDate.isSame(startDate, "day")) {
                                const startDateTime = moment(startDate)
                                  .hour(startTime.hour())
                                  .minute(startTime.minute())
                                  .second(0);
                                const endDateTime = moment(endDate)
                                  .hour(endTime.hour())
                                  .minute(endTime.minute())
                                  .second(0);

                                if (endDateTime.diff(startDateTime, "hours", true) >= 1) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    "End time must be at least 1 hour after start time on the same day"
                                  )
                                );
                              }

                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <TimePicker
                          use12Hours
                          format="h:mm a"
                          placeholder="Select End Time"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <span>
                            Time Zone{" "}
                            <Tooltip title="Select the time zone for the specified times.">
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        name="timeZone"
                        rules={[
                          {
                            required: true,
                            message: "Please select a time zone",
                          },
                        ]}
                        initialValue={moment.tz.guess()}
                      >
                        <Select
                          placeholder="Select Time Zone"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                          }
                        >
                          {TIME_ZONES.map((tz) => (
                            <Option key={tz.value} value={tz.value}>
                              {tz.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              {accessType === "Recurring" && (
                <>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <span>
                            Days of the Week{" "}
                            <Tooltip title="Select the days when access is permitted.">
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        name="daysOfWeek"
                        rules={[
                          {
                            required: true,
                            message: "Please select at least one day of the week",
                          },
                        ]}
                      >
                        <Checkbox.Group
                          options={daysOfWeekOptions.map((day) => ({
                            label: day.label,
                            value: day.value,
                          }))}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label={
                          <span>
                            Start Time{" "}
                            <Tooltip title="Select the start time for access each day.">
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        name="startTime"
                        rules={[
                          {
                            required: true,
                            message: "Please select a start time",
                          },
                        ]}
                      >
                        <TimePicker
                          use12Hours
                          format="h:mm a"
                          placeholder="Select Start Time"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={
                          <span>
                            End Time{" "}
                            <Tooltip title="Select the end time for access each day. Must be after the start time.">
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        name="endTime"
                        dependencies={["startTime"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select an end time",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const startTime = getFieldValue("startTime");
                              if (!value || !startTime || value.isAfter(startTime)) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error("End time must be after start time"));
                            },
                          }),
                        ]}
                      >
                        <TimePicker
                          use12Hours
                          format="h:mm a"
                          placeholder="Select End Time"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <span>
                            Time Zone{" "}
                            <Tooltip title="Select the time zone for the specified times.">
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        name="timeZone"
                        rules={[
                          {
                            required: true,
                            message: "Please select a time zone",
                          },
                        ]}
                        initialValue={moment.tz.guess()}
                      >
                        <Select
                          placeholder="Select Time Zone"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                          }
                        >
                          {TIME_ZONES.map((tz) => (
                            <Option key={tz.value} value={tz.value}>
                              {tz.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Form>
        <Row>
          <Col>
            <Text type="secondary">
              "All these fields are optional and managed by Authnull agent"
            </Text>
          </Col>
        </Row>
      </Card>
      {/* Save and Cancel Buttons */}
      <Row justify="end" style={{ marginTop: "24px" }}>
        <Col>
          <Button style={{ marginRight: "10px" }} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleSaveAndNext}
            loading={loading}
            style={{ marginRight: "16px" }}
          >
            {isEditMode ? "Update and Next" : "Save and Next"}
          </Button>
          <Button type="primary" onClick={handleSaveAndCreate} loading={loadingPolicy}>
            {isEditMode ? "Update Policy" : "Create Policy"}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default TimeBoundAccess;
