import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Row, Select, Tag, message } from "antd";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { useDebounce } from "../../common/debounce";
import { useLocation } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_API, ORG_ID, DOMAIN_ID } from "../../constants";
import { formatTimeByTimezone } from "../../common/formatTimebyTimezone";

// Hardcoded privileges list
const PRIVILEGES_LIST = [
  "USAGE",
  "ALTER",
  "ALTER ROUTINE",
  "APPLICATION_PASSWORD_ADMIN",
  "AUDIT_ABORT_EXEMPT",
  "AUDIT_ADMIN",
  "AUTHENTICATION_POLICY_ADMIN",
  "BACKUP_ADMIN",
  "BINLOG_ADMIN",
  "BINLOG_ENCRYPTION_ADMIN",
  "CLONE_ADMIN",
  "CONNECTION_ADMIN",
  "CREATE",
  "CREATE ROLE",
  "CREATE ROUTINE",
  "CREATE TABLESPACE",
  "CREATE TEMPORARY TABLES",
  "CREATE USER",
  "CREATE VIEW",
  "DELETE",
  "DROP",
  "DROP ROLE",
  "ENCRYPTION_KEY_ADMIN",
  "EVENT",
  "EXECUTE",
  "FILE",
  "FIREWALL_EXEMPT",
  "FLUSH_OPTIMIZER_COSTS",
  "FLUSH_STATUS",
  "FLUSH_TABLES",
  "FLUSH_USER_RESOURCES",
  "GROUP_REPLICATION_ADMIN",
  "GROUP_REPLICATION_STREAM",
  "INDEX",
  "INNODB_REDO_LOG_ARCHIVE",
  "INNODB_REDO_LOG_ENABLE",
  "INSERT",
  "LOCK TABLES",
  "PASSWORDLESS_USER_ADMIN",
  "PERSIST_RO_VARIABLES_ADMIN",
  "PROCESS",
  "REFERENCES",
  "RELOAD",
  "REPLICATION CLIENT",
  "REPLICATION SLAVE",
  "REPLICATION_APPLIER",
  "REPLICATION_SLAVE_ADMIN",
  "RESOURCE_GROUP_ADMIN",
  "RESOURCE_GROUP_USER",
  "ROLE_ADMIN",
  "SELECT",
  "SENSITIVE_VARIABLES_OBSERVER",
  "SERVICE_CONNECTION_ADMIN",
  "SESSION_VARIABLES_ADMIN",
  "SET_USER_ID",
  "SHOW DATABASES",
  "SHOW VIEW",
  "SHOW_ROUTINE",
  "SHUTDOWN",
  "SUPER",
  "SYSTEM_USER",
  "SYSTEM_VARIABLES_ADMIN",
  "TABLE_ENCRYPTION_ADMIN",
  "TELEMETRY_LOG_ADMIN",
  "TRIGGER",
];

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: 24, color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const UserPrivilegesScreen = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [userPrivileges, setUserPrivileges] = useState([]);
  const [notification, contextHolder] = message.useMessage();

  // Pagination (API is 1-indexed)
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });

  // Filter dropdown lists
  const [databaseList, setDatabaseList] = useState([]);
  const [userList, setUserList] = useState([]);

  // Filters: Database, User, Privilege, Status
  const [appliedFilters, setAppliedFilters] = useState({
    Database: null,
    User: null,
    Privilege: null,
    Status: null,
  });

  // Debounce filters to avoid rapid API calls
  const debouncedFilters = useDebounce(appliedFilters, 500);

  // Fetch filter lists on mount
  useEffect(() => {
    fetchFilterLists();
  }, []);

  // If navigated from UserScreen with a user filter, set it automatically.
  useEffect(() => {
    if (location.state && location.state.userFilter) {
      setAppliedFilters((prev) => ({ ...prev, User: location.state.userFilter }));
    }
  }, [location.state]);

  // Fetch privileges whenever filters or pagination change
  useEffect(() => {
    fetchUserPrivileges();
  }, [debouncedFilters, paginationParams]);

  const fetchFilterLists = async () => {
    try {
      const payload = { orgId: ORG_ID(), tenantId: DOMAIN_ID() };

      const dbRes = await axios.post(
        `${REACT_APP_API}/api/v1/databaseService/getUniqueDatabasenames`,
        payload,
        { headers: { "X-Authorization": AUTH_TOKEN(), withCredentials: true } }
      );
      const userRes = await axios.post(
        `${REACT_APP_API}/api/v1/databaseService/getUniqueUsernames`,
        payload,
        { headers: { "X-Authorization": AUTH_TOKEN(), withCredentials: true } }
      );

      setDatabaseList(dbRes.data?.db_names || []);
      setUserList(userRes.data?.usernames || []);
    } catch (error) {
      console.error("Error fetching filter lists:", error);
      notification.error({
        content: "Unable to fetch database/user filter lists",
      });
    }
  };

  const fetchUserPrivileges = () => {
    const filters = [];
    if (appliedFilters.Database)
      filters.push({ filterParameter: "Database", filterValue: appliedFilters.Database });
    if (appliedFilters.User)
      filters.push({ filterParameter: "User", filterValue: appliedFilters.User });
    if (appliedFilters.Privilege)
      filters.push({ filterParameter: "Privilege", filterValue: appliedFilters.Privilege });
    if (appliedFilters.Status)
      filters.push({ filterParameter: "Status", filterValue: appliedFilters.Status });

    const payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      filters,
      requestId: "",
      limit: paginationParams.pageSize,
      page_id: paginationParams.current,
    };

    setIsLoading(true);

    axios
      .post(`${REACT_APP_API}/api/v1/databaseService/listUserPrivilege`, payload, {
        headers: { "X-Authorization": AUTH_TOKEN(), withCredentials: true },
      })
      .then((res) => {
        setIsLoading(false);
        setUserPrivileges(
          (res?.data?.data || []).map((data, index) => ({
            ...data,
            id: `${data.id}-${index}`,
          }))
        );
        setTotalCount(res?.data?.total_count || 0);
        setCurrentPageModel({ pageSize: PAGESIZE, page: currentPage });
      })
      .catch((err) => {
        setIsLoading(false);
        notification.error({ content: "Unable to fetch user privileges" });
        console.error(err);
      });
  };

  const combinedFilters = [];
  if (appliedFilters.Database)
    combinedFilters.push({ key: "Database", display: `Database: ${appliedFilters.Database}` });
  if (appliedFilters.User)
    combinedFilters.push({ key: "User", display: `User: ${appliedFilters.User}` });
  if (appliedFilters.Privilege)
    combinedFilters.push({ key: "Privilege", display: `Privilege: ${appliedFilters.Privilege}` });
  if (appliedFilters.Status)
    combinedFilters.push({ key: "Status", display: `Status: ${appliedFilters.Status}` });

  const columns = [
    {
      headerName: "Database Name",
      field: "db_name",
      flex: 1,
    },
    {
      headerName: "User Name",
      field: "user_name",
      flex: 1.5,
    },
    {
      headerName: "Role",
      field: "role",
      flex: 1,
      renderCell: (params) => {
        const role = params.value;
        const color = role === "Admin" ? "red" : role === "User" ? "blue" : "default";
        return <Tag color={color}>{role}</Tag>;
      },
    },
    {
      headerName: "Privilege",
      field: "privilege",
      flex: 1,
      renderCell: (params) => <Tag color="geekblue">{params.value}</Tag>,
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      renderCell: (params) => (
        <Tag color={params.value === "Active" ? "green" : "red"}>{params.value}</Tag>
      ),
    },
    {
      headerName: "Granted At",
      field: "created_at",
      flex: 1.5,
      renderCell: (val) => {
        const timeStamp = val?.row?.created_at;
        const timezone = localStorage.getItem("timezone");
        return formatTimeByTimezone(timeStamp, timezone);
      },
      // renderCell: (params) =>
      //   moment.unix(params.value).tz("America/Los_Angeles").format("DD-MM-YYYY HH:mm:ss [PST]"),
    },
  ];

  return (
    <>
      {contextHolder}
      <Row className="content-container">
        <Col span={24}>
          <Row justify="space-between" style={{ marginBottom: 16 }}>
            <Col>
              <h2 className="title">User Privileges</h2>
            </Col>
          </Row>

          {/* Filters Row */}
          <Row style={{ marginBottom: "1rem", marginTop: "1rem" }} gutter={20}>
            <Col span={4}>
              <p className="search-label">Database</p>
              <Select
                showSearch
                placeholder="Select Database"
                style={{ width: "100%" }}
                allowClear
                value={appliedFilters.Database}
                onChange={(value) => setAppliedFilters((prev) => ({ ...prev, Database: value }))}
                filterOption={(input, option) =>
                  (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
                }
              >
                {databaseList.map((db) => (
                  <Select.Option key={db} value={db}>
                    {db}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            <Col span={4}>
              <p className="search-label">User</p>
              <Select
                showSearch
                placeholder="Select User"
                style={{ width: "100%" }}
                allowClear
                value={appliedFilters.User}
                onChange={(value) => setAppliedFilters((prev) => ({ ...prev, User: value }))}
                filterOption={(input, option) =>
                  (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
                }
              >
                {userList.map((user) => (
                  <Select.Option key={user} value={user}>
                    {user}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            <Col span={4}>
              <p className="search-label">Privilege</p>
              <Select
                showSearch
                placeholder="Select Privilege"
                style={{ width: "100%" }}
                allowClear
                value={appliedFilters.Privilege}
                onChange={(value) => setAppliedFilters((prev) => ({ ...prev, Privilege: value }))}
                filterOption={(input, option) =>
                  (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
                }
              >
                {PRIVILEGES_LIST.map((priv) => (
                  <Select.Option key={priv} value={priv}>
                    {priv}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            <Col span={4}>
              <p className="search-label">Status</p>
              <Select
                placeholder="Select Status"
                style={{ width: "100%" }}
                value={appliedFilters.Status}
                onChange={(value) => setAppliedFilters((prev) => ({ ...prev, Status: value }))}
                allowClear
              >
                <Select.Option value="Active">Active</Select.Option>
                <Select.Option value="Inactive">Inactive</Select.Option>
              </Select>
            </Col>
          </Row>

          {/* Applied Filters Tags */}
          {combinedFilters.length > 0 && (
            <Row style={{ marginBottom: 16 }}>
              <Col>
                <p>Applied Filters</p>
                {combinedFilters.map(({ key, display }) => (
                  <Tag
                    key={key}
                    closable
                    onClose={() => setAppliedFilters((prev) => ({ ...prev, [key]: null }))}
                    style={{ marginBottom: 8, marginRight: 8 }}
                  >
                    {display}
                  </Tag>
                ))}
              </Col>
            </Row>
          )}

          {/* DataGrid */}
          <Row>
            <Col span={24}>
              <DataGrid
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={userPrivileges}
                columns={columns}
                paginationMode="server"
                rowCount={totalCount}
                loading={isLoading}
                style={{ border: "none" }}
                paginationModel={currentPageModel}
                page={currentPage}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: PAGESIZE, page: 0 },
                  },
                }}
                onPaginationModelChange={(params) => {
                  setCurrentPage(params.page);
                  setPaginationParams({
                    current: params.page + 1,
                    pageSize: params.pageSize,
                  });
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default UserPrivilegesScreen;
