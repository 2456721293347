import { Button, Col, Form, Row, Select, message, Tooltip, Card, Modal, Input } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API,
  REACT_APP_API_PAM_URL,
  REACT_APP_PAM_API,
} from "../../../constants";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";

const { Option } = Select;

const POLICY_TYPES = [
  { value: "AD", label: "Active Directory Policy" }, // For AD Domain/OU/Users only
  { value: "GroupAD", label: "AD Group Policy" }, // New type for Domain/OU/AD Groups
  { value: "ServiceAccount", label: "Service Account" },
  { value: "endpoints", label: "Endpoint user policies" },
  { value: "radius", label: "Network device access policies" },
  { value: "database", label: "Database Policy" },
];

export default function BasicPolicyInformation({
  onNavigateNext,
  onCreatePolicy,
  loadingPolicy,
  onPolicyTypeChange, // callback from parent to update parent's policyType
  parentPolicyType, // if you want the parent's policyType for reference
}) {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isEditMode = searchParams.get("edit") === "true";

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [policyType, setPolicyType] = useState("");
  const [policyName, setPolicyName] = useState("");

  const [endpointsData, setEndpointsData] = useState([]);
  const [endpointGroupsData, setEndpointGroupsData] = useState([]);
  const [adGroupsData, setAdGroupsData] = useState([]);
  const [endpointUsersData, setEndpointUsersData] = useState([]);
  const [selectedEndpointGroups, setSelectedEndpointGroups] = useState([]);
  const [selectedEndpoints, setSelectedEndpoints] = useState([]);
  const [walletUsers, setWalletUsers] = useState([]);
  const [adUsersData, setAdUsersData] = useState([]);
  const [infraSelectedADGroups, setInfraSelectedADGroups] = useState([]);
  const [networkDevices, setNetworkDevices] = useState([]);
  const [notification] = message.useMessage();
  const [editPolicyData, setEditPolicyData] = useState(null);
  const [initialEndpointGroups, setInitialEndpointGroups] = useState([]);
  const [initialEndpoints, setInitialEndpoints] = useState([]);
  const [initialEndpointUsers, setInitialEndpointUsers] = useState([]);
  const [domainData, setDomainData] = useState([]);
  const [selectedDomainOU, setSelectedDomainOU] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState();
  const [OUData, setOUData] = useState([]);
  const [sourceEndpointsData, setSourceEndpointsData] = useState([]);
  const [serviceAccountsData, setServiceAccountsData] = useState([]);
  const [selectedSourceEndpoints, setSelectedSourceEndpoints] = useState([]);
  const [selectedDestinationEndpoints, setSelectedDestinationEndpoints] = useState([]);
  const [selectedADGroups, setSelectedADGroups] = useState([]);
  const [selectDomainADPolicy, setSelectDomainADPolicy] = useState();
  const [adUsersDataADPolicy, setAdUsersDataADpolicy] = useState([]);
  const [selectedDomainID, setSelectedDomainID] = useState(null);

  const [databaseList, setDatabaseList] = useState([]); // from getTenantNames
  const [tableList, setTableList] = useState([]); // from getTableNames
  const [fieldsMap, setFieldsMap] = useState({}); // from getTableFields
  const [selectedTables, setSelectedTables] = useState([]);
  const [fieldMasking, setFieldMasking] = useState({});
  const [dbUsersList, setDbUsersList] = useState([]);

  useEffect(() => {
    /**
     * EDIT/LOAD mode: if "edit" is true, load from "editPolicyData"
     * If not, check if there's an existing saved "policyDraft.basicPolicyInformation"
     * Or else generate a dynamic policy name.
     */
    if (isEditMode) {
      const storedData = localStorage.getItem("editPolicyData");
      if (storedData) {
        const policy = JSON.parse(storedData);
        setEditPolicyData(policy);

        const pj = policy.policyJson || {};
        const pType = pj.policyType || policy.policyType || pj.policy_type;
        const pName = pj.policyName || policy.policyName;

        setPolicyType(pType);
        setPolicyName(pName);

        // We'll parse common fields:
        const iamUsers = pj.permissions?.iam_users || [];

        // Some placeholders for endpoints, if they exist:
        const endpointsObject = pj.endpoints || {};
        const endpointUsers = endpointsObject?.users || [];
        const endpointGroupsFromPolicy = endpointsObject?.groups || {};
        const endpoints = endpointGroupsFromPolicy
          ? Object.values(endpointGroupsFromPolicy).flat()
          : [];

        // Prepare initial form values (and fill them as needed for each policy type).
        const initialValues = {
          policyType: pType,
          policyName: pName,
          iamUsers: iamUsers,
          endpointUsers: endpointUsers,
          endpoints: endpoints,
          endpointGroups: Object.keys(endpointGroupsFromPolicy) || [],
        };

        if (pType === "radius") {
          initialValues.networkDevices = pj.networks?.endpoints || [];
        }

        if (pType === "ServiceAccount") {
          initialValues.sourceEndpoints = pj.serviceaccount?.source_endpoint || [];
          initialValues.destinationEndpoints = pj.serviceaccount?.destination_endpoint || [];
          initialValues.serviceAccountUsers = pj.serviceaccount?.users || [];
        }

        if (pType === "AD") {
          initialValues.domain = pj.ad?.domain || [];
          initialValues.OU = pj.ad?.ous || [];
          initialValues.ADUsers = pj.ad?.users || [];
        }

        if (pType === "GroupAD") {
          initialValues.domain = pj.infra?.ad?.domain || [];
          initialValues.OU = pj.infra?.ad?.ous || [];
          initialValues.ADGroups = pj.infra?.ad?.ad_groups || [];
        }

        if (pType === "database" && pj.database) {
          initialValues.databaseName = pj.database.database_name;
          initialValues.tables = pj.database.tables;
          initialValues.dbUser = pj.database.user;
          initialValues.dbPrivilege = pj.database.privilege;
          setSelectedTables(pj.database.tables || []);
          setFieldMasking(pj.database.field_masking || {});
        }

        form.setFieldsValue(initialValues);

        setInitialEndpointGroups(initialValues.endpointGroups);
        setInitialEndpoints(initialValues.endpoints);
        setInitialEndpointUsers(initialValues.endpointUsers);

        const editPayload = {
          orgId: ORG_ID(),
          tenantId: DOMAIN_ID(),
          policyName: pName,
          policyType: pType,
          policyJson: pj,
        };
        localStorage.setItem("policyDraft.basicPolicyInformation", JSON.stringify(editPayload));
      }
    } else {
      const savedFormData = localStorage.getItem("policyDraft.basicPolicyInformation");
      if (savedFormData) {
        const policy = JSON.parse(savedFormData);
        setEditPolicyData(policy);

        const pj = policy.policyJson || {};
        const pType = pj.policyType || policy.policyType || pj.policy_type;
        const pName = pj.policyName || policy.policyName;

        setPolicyType(pType);
        setPolicyName(pName);

        const iamUsers = pj.permissions?.iam_users || [];
        const endpointsObject = pj.endpoints || {};
        const endpointUsers = endpointsObject?.users || [];
        const endpointGroupsFromPolicy = endpointsObject?.groups || {};
        const endpoints = endpointGroupsFromPolicy
          ? Object.values(endpointGroupsFromPolicy).flat()
          : [];

        const initialValues = {
          policyType: pType,
          policyName: pName,
          iamUsers: iamUsers,
          endpointUsers: endpointUsers,
          endpoints: endpoints,
          endpointGroups: Object.keys(endpointGroupsFromPolicy),
        };

        if (pType === "radius") {
          initialValues.networkDevices = pj.networks?.endpoints || [];
        }
        if (pType === "ServiceAccount") {
          initialValues.sourceEndpoints = pj.serviceaccount?.source_endpoint || [];
          initialValues.destinationEndpoints = pj.serviceaccount?.destination_endpoint || [];
          initialValues.serviceAccountUsers = pj.serviceaccount?.users || [];
        }
        if (pType === "AD") {
          initialValues.domain = pj.ad?.domain || [];
          initialValues.OU = pj.ad?.ous || [];
          initialValues.ADUsers = pj.ad?.users || [];
        }
        if (pType === "GroupAD") {
          initialValues.domain = pj.infra?.ad?.domain || [];
          initialValues.OU = pj.infra?.ad?.ous || [];
          initialValues.ADGroups = pj.infra?.ad?.ad_groups || [];
        }

        if (pType === "database" && pj.database) {
          initialValues.databaseName = pj.database.database_name;
          initialValues.tables = pj.database.tables;
          initialValues.dbUser = pj.database.user;
          initialValues.dbPrivilege = pj.database.privilege;
          setSelectedTables(pj.database.tables || []);
          setFieldMasking(pj.database.field_masking || {});
        }

        form.setFieldsValue(initialValues);
      } else {
        generateDynamicPolicyName("", 36);
      }
    }
  }, []);

  // Fetch base data for other policies
  useEffect(() => {
    fetchDomains();
    fetchDomainOU();
    fetchWalletUsers();
  }, []);

  useEffect(() => {
    if (policyType === "endpoints") {
      fetchEndpointGroups();
    } else if (
      policyType === "AD" ||
      policyType === "serviceAccountPolicy" ||
      policyType === "GroupAD"
    ) {
      fetchEndpointGroups();
    } else if (policyType === "radius") {
      fetchNetworkDevices();
    } else if (policyType === "ServiceAccount") {
      fetchEndpointsList();
    } else if (policyType === "database") {
      fetchTenantNames();
      fetchDbUsers();
    }
  }, [policyType, selectDomainADPolicy]);

  // Fetch AD users when domain changes for "AD"
  useEffect(() => {
    if (selectDomainADPolicy) {
      fetchADUsersADpolicy();
    }
  }, [selectDomainADPolicy]);

  // Fetch endpoints when endpoint groups are selected
  useEffect(() => {
    if (selectedEndpointGroups.length > 0) {
      fetchEndpoints();
    }
  }, [selectedEndpointGroups]);

  // For ServiceAccount, if a user selects Destination Endpoints
  // then fetch the serviceAccount users
  useEffect(() => {
    if (policyType === "ServiceAccount") {
      if (selectedDestinationEndpoints.length > 0) {
        fetchServiceAccountUser();
      }
    }
  }, [selectedDestinationEndpoints, policyType]);

  // Fetch endpoint users when endpoints are selected
  useEffect(() => {
    if (selectedEndpoints.length > 0) {
      fetchEndpointUsers();
    }
  }, [selectedEndpoints]);

  // Fetch AD users (by group) if group was selected -- if needed
  useEffect(() => {
    if (infraSelectedADGroups.length > 0) {
      fetchADUsers();
    }
  }, [infraSelectedADGroups]);

  useEffect(() => {
    fetchADGroups();
  }, [selectedDomainID]);

  const onValuesChange = (changedValues, allValues) => {
    localStorage.setItem("policyDraft.basicPolicyInformation", JSON.stringify(allValues));
  };

  // Helper function: Compare arrays
  const arraysAreEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }
    return true;
  };

  const handleChangePolicyType = (value) => {
    setPolicyType(value);

    // Immediately update localStorage so if they refresh, it’s retained
    const existingDraft = localStorage.getItem("policyDraft.basicPolicyInformation");
    let draftObj = existingDraft ? JSON.parse(existingDraft) : {};
    draftObj.policyType = value;
    localStorage.setItem("policyDraft.basicPolicyInformation", JSON.stringify(draftObj));

    // MOST IMPORTANT: call parent's callback so the parent re-renders the sidebar
    if (onPolicyTypeChange) {
      onPolicyTypeChange(value);
    }
  };

  // Helper to generate dynamic policy name
  const generateDynamicPolicyName = (prefix, length) => {
    const characters = "0123456789";
    let result = prefix;
    const sections = [7, 8];
    for (let sectionLength of sections) {
      for (let i = 0; i < sectionLength; i++) {
        const randomCharIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomCharIndex);
      }
      if (sectionLength !== 8) {
        result += "-";
      }
    }
    setPolicyName(result);
    form.setFieldsValue({ policyName: result });
  };

  const fetchTenantNames = async () => {
    // getTenantNames
    try {
      const payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
      };
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/databaseService/getUniqueDatabasenames`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      if (res.data?.db_names) {
        setDatabaseList(res.data.db_names);
      }
    } catch (error) {
      notification.error({ message: "Error fetching database names", description: error.message });
    }
  };
  // Suppose your API endpoint is "/api/v1/databaseService/getDbUsers"
  // Adjust parameters as needed
  const fetchDbUsers = async () => {
    try {
      const payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
      };

      const res = await axios.post(
        `${REACT_APP_API}/api/v1/databaseService/getUniqueUsernames`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );

      if (res.data?.usernames) {
        setDbUsersList(res.data.usernames);
      }
    } catch (error) {
      notification.error({
        message: "Error fetching DB users",
        description: error.message,
      });
    }
  };

  const fetchTableNames = async (dbName) => {
    try {
      const payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        DbName: dbName,
      };
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/databaseService/getTableNames`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      if (res.data?.table_names) {
        setTableList(res.data.table_names);
      }
    } catch (error) {
      notification.error({ message: "Error fetching table names", description: error.message });
    }
  };

  const fetchTableFields = async (dbName, tables) => {
    // getTableFields
    try {
      const payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        tenantName: dbName,
        tableNames: tables,
      };
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/databaseService/getTableFields`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      if (res.data?.fields) {
        setFieldsMap(res.data.fields);
      }
    } catch (error) {
      notification.error({ message: "Error fetching table fields", description: error.message });
    }
  };

  // UI handlers for DB policy
  const handleDatabaseChange = async (dbName) => {
    // reset tables & fields
    form.setFieldsValue({ tables: [] });
    setSelectedTables([]);
    setFieldMasking({});

    if (dbName) {
      await fetchTableNames(dbName);
    } else {
      setTableList([]);
    }
  };

  const handleTableChange = async (tables) => {
    setSelectedTables(tables);
    if (form.getFieldValue("databaseName") && tables.length > 0) {
      await fetchTableFields(form.getFieldValue("databaseName"), tables);
    } else {
      setFieldsMap({});
    }
  };

  const handleFieldMaskingChange = (tableName, selectedCols) => {
    setFieldMasking((prev) => ({ ...prev, [tableName]: selectedCols }));
  };

  const fetchDomains = async () => {
    try {
      const payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        filter: "",
      };
      const res = await axios.post(`${REACT_APP_API}/ad/GetAllDomains`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      const tempData = res?.data?.domains?.map((ad, i) => ({
        id: i + 1,
        ...ad,
      }));
      setDomainData(tempData ? tempData : []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchDomainOU = async () => {
    try {
      let payload = {
        org_Id: ORG_ID(),
        tenant_Id: DOMAIN_ID(),
      };
      const res = await axios.post(`${REACT_APP_API}/api/v1/policyService/listOU`, payload, {
        headers: {
          "Content-Type": "application/json",
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      setOUData(res?.data?.data || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchADGroups = async () => {
    try {
      let payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        adId: selectedDomainID,
        pageNo: 1,
        pageSize: 100,
        filter: "",
      };
      const res = await axios.post(`${REACT_APP_API}/ad/GetAllGroupsByDomain`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      setAdGroupsData(res?.data?.groups || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchWalletUsers = async () => {
    try {
      let payload = {
        orgId: ORG_ID(),
        domainId: DOMAIN_ID(),
        pageNumber: 1,
        pageSize: 100,
        search: "",
        filter: "",
      };
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/walletService/walletUserList`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      const tempData = res?.data?.users?.map((user, index) => {
        return {
          id: index + 1,
          value: user.emailAddress,
          label: user.emailAddress,
        };
      });
      setWalletUsers(tempData || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchEndpointGroups = async () => {
    try {
      let payload = {
        org_id: ORG_ID(),
        tenant_id: DOMAIN_ID(),
      };
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/GetUniqueGroups`,
        payload
      );
      const tempData = res?.data?.groups?.map((group, index) => {
        return {
          id: index + 1,
          value: group.group_id,
          label: group.group_name,
        };
      });
      setEndpointGroupsData(tempData || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchEndpoints = async () => {
    try {
      let payload = {
        org_id: ORG_ID(),
        tenant_id: DOMAIN_ID(),
        group_ids: selectedEndpointGroups,
      };
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/GetMachinesByGroupID`,
        payload
      );
      setEndpointsData(res?.data?.machines || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchEndpointUsers = async () => {
    try {
      let payload = {
        org_id: ORG_ID(),
        tenant_id: DOMAIN_ID(),
        machine_ids: selectedEndpoints,
      };
      const res = await axios.post(`${REACT_APP_API}/api/v1/policyService/GetUsers`, payload);
      const tempData = res?.data?.users?.map((user, index) => {
        return {
          id: index + 1,
          value: user.epm_user_name,
          label: user.epm_user_name,
        };
      });
      setEndpointUsersData(tempData || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchADUsers = async () => {
    // If needed for AD groups -> users
    const groupIds = infraSelectedADGroups.map((group) => group.id) || [];
    try {
      let payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        groupId: groupIds,
      };
      const res = await axios.post(`${REACT_APP_API}/ad/GetAllUsersByGroup`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      const tempData = res?.data?.users?.map((user, index) => {
        return {
          id: index + 1,
          value: user.userName,
          label: user.userName,
        };
      });
      setAdUsersData(tempData || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchADUsersADpolicy = async () => {
    // For domain-based user listing in "AD" policy
    const domainID = selectDomainADPolicy;
    try {
      let payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        pageNo: 1,
        pageSize: 10,
        filters: {
          emailId: "",
          status: "",
          logoname: "",
          adId: domainID,
        },
      };
      const res = await axios.post(`${REACT_APP_API}/ad/GetAllAdUsers`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      const tempData = res?.data?.users?.map((user, index) => {
        return {
          id: index + 1,
          value: user.username,
          label: user.username,
        };
      });
      setAdUsersDataADpolicy(tempData || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchNetworkDevices = async () => {
    let payload = {
      OrgID: ORG_ID(),
      DomainID: 1,
      tenantId: DOMAIN_ID(),
      Search: "",
      Filter: { FilterBy: "", Value: "" },
      PageSize: 100,
      PageID: 1,
      MachineType: "radius",
    };
    try {
      const res = await axios.post(`${REACT_APP_PAM_API}/network_device/ListDevices`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });

      const tempData = res?.data?.network_devices?.map((instance, index) => {
        return {
          id: index + 1,
          value: instance?.client_ip,
          label: instance?.device_type,
        };
      });
      setNetworkDevices(tempData || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchEndpointsList = async () => {
    try {
      const response = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/listEndpoints`,
        {
          org_Id: ORG_ID(),
          tenant_Id: DOMAIN_ID(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      const data = response.data;
      const combinedEndpoints = [...data.l_endpoint, ...data.w_endpoint];
      setSourceEndpointsData(combinedEndpoints);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchServiceAccountUser = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageId: 1,
      pageSize: 100,
      token: AUTH_TOKEN(),
      instanceIds: selectedDestinationEndpoints,
      filter: {
        filterBy: "",
        Value: "",
      },
      search: "",
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/endpointUsers/listAllEpmUsers`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setServiceAccountsData(res?.data?.epmUsers);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ========== Handle Save ==========

  const handleFinish = (values) => {
    setIsSubmitting(true);

    // If editing existing policy, we can optionally check if user changed something
    if (isEditMode) {
      const unchanged =
        arraysAreEqual(values.endpointGroups, initialEndpointGroups) &&
        arraysAreEqual(values.endpoints, initialEndpoints) &&
        arraysAreEqual(values.endpointUsers, initialEndpointUsers);

      if (unchanged) {
        messageApi.error("Please change endpoint policies before proceeding.");
        setIsSubmitting(false);
        return;
      }
    }

    let payload = {};
    const iamGroups = []; // If you store group logic for IAM, set here if needed
    const iamUsers = values.iamUsers || [];

    /**
     * Construct separate policyJson depending on policyType
     * Keep existing structure for the old types so you don't break them.
     */

    // -------------- AD USER POLICY -------------
    if (values.policyType === "AD") {
      // The old AD structure (only domain, OU, ADUsers now).
      // If you also had endpoints logic for AD, keep it if required:
      const adPolicyPayload = {
        policyName: policyName,
        policyType: values.policyType,
        ad: {
          ous: values.OU || [],
          domain: values.domain || [],
          // No ad groups here
          users: values.ADUsers || [],
        },
        permissions: {
          allowed: true,
          iam_users: iamUsers,
          iam_groups: iamGroups,
        },
      };

      payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        policyName: policyName,
        policyType: values.policyType,
        policyJson: adPolicyPayload,
      };
    }

    // -------------- NEW "GroupAD" POLICY -------------
    else if (values.policyType === "GroupAD") {
      // The new structure you requested:
      const groupAdPayload = {
        policyName: policyName,
        policyType: "GroupAD", // additional custom field
        infra: {
          ad: {
            domain: [values.domain] || [],
            ous: values.OU || [],
            ad_groups: values.ADGroups || [],
          },
        },
        permissions: {
          allowed: true,
          iam_users: iamUsers,
        },
      };

      payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        policyName: policyName,
        policyType: values.policyType,
        policyJson: groupAdPayload,
      };
    }

    // -------------- ENDPOINTS POLICY -------------
    else if (values.policyType === "endpoints") {
      // The existing endpoints logic remains the same
      let groups = {};
      if (values.endpointGroups) {
        groups = values.endpointGroups.reduce((acc, group) => {
          const [, groupName] = group.split("|");
          acc[groupName.trim()] = [];
          return acc;
        }, {});
      }

      const trimmedEndpoints =
        values.endpoints?.map((endpoint) => endpoint.split("|")[1]?.trim()) || [];
      const filteredEndpoints = endpointsData?.filter((endpoint) =>
        trimmedEndpoints.includes(endpoint.hostname)
      );
      filteredEndpoints?.forEach((endpoint) => {
        const endpointGroupName = endpoint.groupname?.trim();
        if (groups[endpointGroupName]) {
          groups[endpointGroupName].push(endpoint.hostname);
        }
      });

      let endpointPolicyPayload = {
        policyName: policyName,
        policyType: values.policyType,
        endpoints: {
          groups: groups,
          users: values.endpointUsers || [],
        },
        permissions: {
          allowed: true,
          iam_users: iamUsers,
          iam_groups: iamGroups,
        },
      };

      payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        policyName: policyName,
        policyType: values.policyType,
        policyJson: endpointPolicyPayload,
      };
    }

    // -------------- RADIUS POLICY -------------
    else if (values.policyType === "radius") {
      let radiusPolicyPayload = {
        policyName: policyName,
        policyType: values.policyType,
        networks: {
          endpoints: values.networkDevices || [],
        },
        permissions: {
          allowed: true,
          iam_users: iamUsers,
          iam_groups: iamGroups,
        },
      };

      payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        policyName: policyName,
        policyType: values.policyType,
        policyJson: radiusPolicyPayload,
      };
    }

    // -------------- SERVICE ACCOUNT POLICY -------------
    else if (values.policyType === "ServiceAccount") {
      let serviceAccountPayload = {
        policyName: policyName,
        policyType: values.policyType,
        serviceaccount: {
          source_endpoint: values.sourceEndpoints || [],
          destination_endpoint: values.destinationEndpoints || [],
          users: values.serviceAccountUsers || [],
        },
        permissions: {
          allowed: true,
          iam_users: iamUsers,
          iam_groups: iamGroups,
        },
      };

      payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        policyName: policyName,
        policyType: values.policyType,
        policyJson: serviceAccountPayload,
      };
    }

    // If editing, keep any existing "dit" property if needed
    // ========== DATABASE POLICY (NEW) ==========
    else if (values.policyType === "database") {
      // Build final JSON, including user & privilege
      const databasePolicyPayload = {
        policyName: policyName,
        policyType: "database",
        database: {
          database_name: values.databaseName,
          tables: values.tables || [],
          field_masking: fieldMasking || {},
          user: values.dbUser,
          privilege: [values.dbPrivilege],
        },
        permissions: {
          allowed: true,
          iam_users: iamUsers,
          iam_groups: iamGroups,
        },
      };
      payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        policyName: policyName,
        policyType: values.policyType,
        policyJson: databasePolicyPayload,
      };
    }

    if (isEditMode) {
      const existingDraft = localStorage.getItem("editPolicyData");
      const existingPolicy = existingDraft ? JSON.parse(existingDraft) : null;
      const existingDIT = existingPolicy?.policyJson?.dit;
      if (existingDIT) {
        payload.policyJson.dit = existingDIT;
      }
    }

    // Store in localStorage
    localStorage.setItem("policyDraft.basicPolicyInformation", JSON.stringify(payload));
    localStorage.setItem("editPolicyData", JSON.stringify(payload));

    messageApi.success("Primary Information saved!");
    setIsSubmitting(false);
    onNavigateNext();
  };

  // Cancel handler with confirm
  const handleCancel = () => {
    Modal.confirm({
      title: "Are you sure you want to cancel?",
      content: "All unsaved changes will be lost.",
      onOk: () => {
        localStorage.removeItem("policyDraft.basicPolicyInformation");
        localStorage.removeItem("formSessionData");
        localStorage.removeItem("editPolicyData");
        navigate("/pam/policy/v2");
      },
    });
  };

  // ========== RENDER ==========

  return (
    <div>
      {contextHolder}
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={{ policyName: policyName }}
      >
        <Card>
          <span
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              color: "#000",
            }}
          >
            What kind of policy would you like to create?
          </span>

          <Row style={{ marginTop: 16 }} gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Policy Type"
                name="policyType"
                rules={[{ required: true, message: "Please select a policy type" }]}
              >
                <Select
                  placeholder="Select policy type"
                  value={policyType}
                  onChange={handleChangePolicyType}
                  allowClear
                >
                  {POLICY_TYPES.map((policy, index) => (
                    <Option key={index} value={policy.value}>
                      {policy.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Card
          title={
            <span>
              Who will get access (from your IAM)?{" "}
              <Tooltip title="Select the users, groups, or entities who will be granted access through this policy.">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          style={{ marginBottom: 16, marginTop: 16 }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={
                  <span>
                    Select IAM Users &nbsp;
                    <Tooltip title="Select one or more users.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="iamUsers"
              >
                <Select mode="multiple" placeholder="Select users" disabled={!walletUsers.length}>
                  {walletUsers.map((user, index) => (
                    <Option key={index} value={user.value}>
                      {user.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Card>

        {(policyType === "endpoints" ||
          policyType === "radius" ||
          policyType === "AD" ||
          policyType === "GroupAD") && (
          <Card
            title={
              <span>
                What Infrastructure will they get access to?{" "}
                <Tooltip title="Specify the resources or entities this policy will apply to.">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
            style={{ marginBottom: 16 }}
          >
            {/* --------- ENDPOINTS BLOCK --------- */}
            {policyType === "endpoints" && (
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <span>
                        Select Endpoint Groups &nbsp;
                        <Tooltip title="Select one or more endpoint groups.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="endpointGroups"
                    rules={[
                      {
                        required: true,
                        message: "Please select an endpoint group",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Endpoint group"
                      onChange={(value) => {
                        const selectedGroupIds = value.map((item) =>
                          parseInt(item.split("|")[0], 10)
                        );
                        setSelectedEndpointGroups(selectedGroupIds);
                      }}
                      allowClear
                    >
                      {endpointGroupsData.map((group, index) => (
                        <Option key={index} value={`${group.value}|${group.label}`}>
                          {group.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={
                      <span>
                        Select Endpoints &nbsp;
                        <Tooltip title="Select one or more endpoints.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="endpoints"
                    rules={[{ required: true, message: "Please select endpoints" }]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select endpoint"
                      onChange={(value) => {
                        const selectedEndpointIds = value.map((item) =>
                          parseInt(item.split("|")[0], 10)
                        );
                        setSelectedEndpoints(selectedEndpointIds);
                      }}
                      disabled={!selectedEndpointGroups.length}
                      allowClear
                    >
                      {endpointsData.map((endpoint, index) => (
                        <Option key={index} value={`${endpoint.machine_id}|${endpoint.hostname}`}>
                          {endpoint.hostname}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={
                      <span>
                        Select Endpoint Users &nbsp;
                        <Tooltip title="Select one or more endpoint users.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="endpointUsers"
                    rules={[
                      {
                        required: true,
                        message: "Please select endpoint users",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select endpoint users"
                      disabled={!selectedEndpoints.length}
                      allowClear
                    >
                      {endpointUsersData.map((user, index) => (
                        <Option key={index} value={user.value}>
                          {user.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}

            {/* --------- RADIUS BLOCK --------- */}
            {policyType === "radius" && (
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label={
                      <span>
                        Select Network Devices &nbsp;
                        <Tooltip title="Select one or more network devices.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="networkDevices"
                    rules={[{ required: true, message: "Please select devices" }]}
                  >
                    <Select mode="multiple" placeholder="Select network devices" allowClear>
                      {networkDevices.map((device, index) => (
                        <Option key={index} value={device.value}>
                          {`${device.label} - (${device.value})`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}

            {/* --------- AD USER POLICY BLOCK --------- */}
            {policyType === "AD" && (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Select Domain"
                    name="domain"
                    rules={[{ required: true, message: "Please select a domain" }]}
                  >
                    <Select
                      placeholder="Select domain"
                      onChange={(selectedName, option) => {
                        const domainId = parseInt(option?.key, 10);
                        const selectedDomainObj = domainData.find(
                          (domain) => domain.id === domainId
                        );
                        if (selectedDomainObj) {
                          setSelectedDomain(selectedDomainObj.id);
                          setSelectDomainADPolicy(selectedDomainObj.id);
                        }
                        const selectedOu = OUData.filter((ou) => ou.ad_id === domainId);
                        setSelectedDomainOU(selectedOu);
                      }}
                    >
                      {domainData.map((dom) => (
                        <Select.Option key={dom.id} value={dom.directoryName}>
                          {dom.directoryName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="OU"
                    name="OU"
                    rules={[{ required: true, message: "Please select OU" }]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select OU"
                      allowClear
                      disabled={!selectedDomainOU.length}
                    >
                      {selectedDomainOU.map((ou) => (
                        <Option key={ou.id} value={ou.ou_name}>
                          {ou.ou_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={
                      <span>
                        Select AD Users &nbsp;
                        <Tooltip title="Select one or more AD users.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="ADUsers"
                  >
                    <Select mode="multiple" placeholder="Select User">
                      {adUsersDataADPolicy.map((user, index) => (
                        <Option key={index} value={user.value}>
                          {user.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}

            {/* --------- AD GROUP POLICY BLOCK --------- */}
            {policyType === "GroupAD" && (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Select Domain"
                    name="domain"
                    rules={[{ required: true, message: "Please select one or more domains" }]}
                  >
                    <Select
                      placeholder="Select one or more domains"
                      onChange={(selectedDomainNames, option) => {
                        setSelectedDomainID(Number(option?.key));

                        // Find the matching domain objects by name
                        const matchedDomainObjs = domainData.filter((dom) =>
                          selectedDomainNames.includes(dom.directoryName)
                        );

                        // Extract the domain IDs from the matched domain objects
                        const matchedDomainIds = matchedDomainObjs.map((dom) => dom.id);

                        // Store the domain IDs in localStorage under the key "adIds"
                        localStorage.setItem("adIds", JSON.stringify(matchedDomainIds));

                        // Also update your current policy draft with the selected domain names
                        const currentDraft =
                          JSON.parse(localStorage.getItem("policyDraft.basicPolicyInformation")) ||
                          {};
                        if (!currentDraft.policyJson) currentDraft.policyJson = {};
                        if (!currentDraft.policyJson.infra) currentDraft.policyJson.infra = {};
                        if (!currentDraft.policyJson.infra.ad)
                          currentDraft.policyJson.infra.ad = {};
                        currentDraft.policyJson.infra.ad.domain = selectedDomainNames;
                        localStorage.setItem(
                          "policyDraft.basicPolicyInformation",
                          JSON.stringify(currentDraft)
                        );

                        // Filter OUData to include all OUs whose ou.ad_id is in the matched domain IDs
                        const combinedOUs = OUData.filter((ou) =>
                          matchedDomainIds.includes(ou.ad_id)
                        );
                        setSelectedDomainOU(combinedOUs);
                      }}
                    >
                      {domainData.map((dom) => (
                        <Select.Option key={dom.id} value={dom.directoryName}>
                          {dom.directoryName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="OU"
                    name="OU"
                    rules={[{ required: true, message: "Please select OU(s)" }]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select OU(s)"
                      allowClear
                      // We disable this if no domains have been selected
                      disabled={!selectedDomainOU.length}
                    >
                      {selectedDomainOU.map((ou) => (
                        <Option key={ou.id} value={ou.ou_name}>
                          {ou.ou_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label="Select AD Groups"
                    name="ADGroups"
                    rules={[{ required: true, message: "Please select at least one group" }]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select AD group(s)"
                      onChange={(value) => {
                        // Keep local state, if needed
                        const selected = adGroupsData.filter((group) =>
                          value.includes(group.groupName)
                        );
                        setInfraSelectedADGroups(selected);
                      }}
                    >
                      {adGroupsData.map((group, index) => (
                        <Option key={index} value={group.groupName}>
                          {group.groupName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Card>
        )}

        {policyType === "ServiceAccount" && (
          <Card
            title={
              <span>
                What Infrastructure will they get access to?{" "}
                <Tooltip title="Specify the resources or entities this policy will apply to.">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
            style={{ marginBottom: 16 }}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={
                    <span>
                      Select Source Endpoints &nbsp;
                      <Tooltip title="Select one or more source endpoints">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                  name="sourceEndpoints"
                  rules={[
                    {
                      required: true,
                      message: "Please select source endpoints",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select source endpoints"
                    onChange={(value) => setSelectedSourceEndpoints(value)}
                    allowClear
                  >
                    {sourceEndpointsData.map((endpoint, index) => (
                      <Option key={index} value={endpoint.host_name}>
                        {`${endpoint.host_name} - (${endpoint.public_ip})`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={
                    <span>
                      Select Destination Endpoints &nbsp;
                      <Tooltip title="Select one or more destination endpoints">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                  name="destinationEndpoints"
                  rules={[
                    {
                      required: true,
                      message: "Please select destination endpoints",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select destination endpoints"
                    onChange={(value) => {
                      const selectedEndpointIds = value.map((item) =>
                        parseInt(item.split("|")[1], 10)
                      );
                      setSelectedDestinationEndpoints(selectedEndpointIds);
                    }}
                    allowClear
                  >
                    {sourceEndpointsData.map((endpoint, index) => (
                      <Option
                        key={index}
                        value={`${endpoint.host_name}|${endpoint.id}`}
                        disabled={selectedSourceEndpoints?.includes(endpoint.host_name)}
                      >
                        {`${endpoint.host_name} - (${endpoint.public_ip})`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={
                    <span>
                      Select Service Account Users &nbsp;
                      <Tooltip title="Select one or more service accounts">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                  name="serviceAccountUsers"
                  rules={[
                    {
                      required: true,
                      message: "Please select service account",
                    },
                  ]}
                >
                  <Select mode="multiple" placeholder="Select service account" allowClear>
                    {serviceAccountsData.map((user, index) => (
                      <Option key={index} value={user?.userName}>
                        {user?.userName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        )}

        {policyType === "database" && (
          <Card
            title={
              <span>
                What Database will they get access to?{" "}
                <Tooltip title="Specify the database, tables, columns, and privileges.">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
            style={{ marginBottom: 16 }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Database User"
                  name="dbUser"
                  rules={[{ required: true, message: "Please select database user" }]}
                >
                  <Select placeholder="Select Database user" allowClear>
                    {dbUsersList.map((user) => (
                      <Option key={user} value={user}>
                        {user}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Select Database"
                  name="databaseName"
                  rules={[{ required: true, message: "Please select a database" }]}
                >
                  <Select
                    placeholder="Select a database"
                    onChange={handleDatabaseChange}
                    allowClear
                  >
                    {databaseList.map((db) => (
                      <Option key={db} value={db}>
                        {db}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Select Tables"
                  name="tables"
                  rules={[{ required: true, message: "Please select one or more tables" }]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select tables"
                    onChange={handleTableChange}
                    disabled={!form.getFieldValue("databaseName")}
                    allowClear
                  >
                    {tableList.map((table) => (
                      <Option key={table} value={table}>
                        {table}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {/* Two more fields: user & privilege */}
            <Row gutter={16}></Row>

            {/* For each selected table, select columns to mask */}
            {selectedTables.length > 0 && (
              <Row gutter={[16, 16]}>
                {selectedTables.map((table) => (
                  <Col span={24} key={table}>
                    <Form.Item
                      label={
                        <span>
                          Fields to mask in <b>{table}</b>
                        </span>
                      }
                    >
                      <Select
                        mode="multiple"
                        placeholder={`Select fields to mask from ${table}`}
                        value={fieldMasking[table] || []}
                        onChange={(cols) => handleFieldMaskingChange(table, cols)}
                        rules={[{ required: true, message: "Please select fields to mask" }]}
                        allowClear
                      >
                        {(fieldsMap[table] || []).map((col) => (
                          <Option key={col} value={col}>
                            {col}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                ))}
                <Col span={24}>
                  <Form.Item
                    label="Privilege"
                    name="dbPrivilege"
                    rules={[{ required: true, message: "Please select DB privilege" }]}
                  >
                    <Select placeholder="Select privilege(s)" allowClear>
                      <Option value="READ">READ</Option>
                      <Option value="WRITE">WRITE</Option>
                      <Option value="EXECUTE">EXECUTE</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Card>
        )}

        <Row gutter={20} justify="end">
          <Form.Item>
            <Button style={{ margin: "10px" }} onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              style={{ margin: "10px" }}
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
            >
              {isEditMode ? "Update and Next" : "Save and Next"}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}
