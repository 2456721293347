import { Col, Row, Button, message, Badge, Tooltip, Cascader, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  AUTH_TOKEN,
  REACT_APP_PAM_API,
  ORG_ID,
  DOMAIN_ID,
  REACT_APP_API_TENANTS_URL,
} from "../../constants";
import axios from "axios";
import { logOutUser } from "../../common";
import { useDebounce } from "../../common/debounce";
import { DataGrid, GridOverlay, useGridApiRef } from "@mui/x-data-grid";
import { InboxOutlined, MoreOutlined } from "@ant-design/icons";
import { AutoComplete, Dropdown, Menu } from "antd";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const ListRadiusDevices = () => {
  const apiRef = useGridApiRef();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [endpointData, setEndpointData] = useState([]);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [sortModel, setSortModel] = useState();
  const debouncedValue = useDebounce(search, 500);

  //pagination
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const { Text } = Typography;
  // New state to store filter options generated dynamically from the API response
  const [filterOptions, setFilterOptions] = useState([]);
  // Options for the AutoComplete search (now based on device_type)
  const [searchOptions, setSearchOptions] = useState([]);

  // ─────────────────────────────────────────────────────────────────────────────
  // Handle search for the AutoComplete (searching by device_type)
  // ─────────────────────────────────────────────────────────────────────────────
  const handleSearch = (value) => {
    if (!value) {
      setSearchOptions([]);
      return;
    }
    const filteredOptions = endpointData
      .filter((device) => device.device_type?.toLowerCase().includes(value.toLowerCase()))
      .map((device) => ({
        label: device.device_type,
        value: device.device_type,
      }));

    setSearchOptions(filteredOptions);
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // Fetch List of Devices and dynamically generate filter options
  // ─────────────────────────────────────────────────────────────────────────────
  const fetchInstance = () => {
    setIsLoading(true);

    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      search: debouncedValue,
      filter: {
        filterBy: filterBy,
        value: filteredValue,
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
      MachineType: "radius",
      tenantId: DOMAIN_ID(),
    };

    axios
      .post(`${REACT_APP_PAM_API}/network_device/ListDevices`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);

        // Store only `network_devices` from the response
        const devices = res?.data?.network_devices || [];
        setEndpointData(devices);

        // Generate unique filter options using the mappings:
        // Device Name -> device_type, Client IP -> client_ip, Device IP -> ip_address
        const uniqueDeviceTypes = Array.from(
          new Set(devices.map((device) => device.device_type).filter(Boolean))
        );
        const uniqueClientIPs = Array.from(
          new Set(devices.map((device) => device.client_ip).filter(Boolean))
        );
        const uniqueDeviceIPs = Array.from(
          new Set(devices.map((device) => device.ip_address).filter(Boolean))
        );

        const cascaderOptions = [
          {
            value: "DEVICE_NAME",
            label: "Device",
            children: uniqueDeviceTypes.map((val) => ({ value: val, label: val })),
          },
          {
            value: "CLIENTIP",
            label: "Client IP",
            children: uniqueClientIPs.map((val) => ({ value: val, label: val })),
          },
          {
            value: "DEVICEIP",
            label: "Device IP",
            children: uniqueDeviceIPs.map((val) => ({ value: val, label: val })),
          },
        ];
        setFilterOptions(cascaderOptions);

        // The totalCount is unknown from the new API; setting it to the length of devices for now.
        setTotalCount(devices.length);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch devices",
          });
        }
      });
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // Delete Device
  // ─────────────────────────────────────────────────────────────────────────────
  const handleDeleteDevice = async (deviceRow) => {
    try {
      const payload = {
        client_ip: deviceRow?.client_ip,
        org_id: ORG_ID(),
      };

      console.log("payload", payload);

      await axios.post(`${REACT_APP_PAM_API}/network_device/DeleteDevice`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      });

      notification.open({
        type: "success",
        content: "Device Deleted Successfully",
      });

      // refetch devices after delete
      fetchInstance();
    } catch (error) {
      notification.open({
        type: "error",
        content: "Error deleting device",
      });
    }
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // Columns definition - new fields + new Actions column
  // ─────────────────────────────────────────────────────────────────────────────
  const columns = [
    {
      headerName: "NAS IP",
      field: "ip_address",
      key: "ip_address",
      flex: 1,
    },
    {
      headerName: "Name",
      field: "device_type",
      key: "device_type",
      flex: 1,
    },
    {
      headerName: "NAS Port Type",
      field: "nas_port_type",
      key: "nas_port_type",
      flex: 1,
    },
    {
      headerName: "Client IP",
      field: "client_ip",
      key: "client_ip",
      flex: 1,
    },
    {
      headerName: "Actions",
      field: "actions",
      key: "actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const rowData = params.row;

        const menu = (
          <Menu>
            <Menu.Item key="delete" onClick={() => handleDeleteDevice(rowData)}>
              Delete
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  // ─────────────────────────────────────────────────────────────────────────────
  // Sorting
  // ─────────────────────────────────────────────────────────────────────────────
  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // Filter handling
  // ─────────────────────────────────────────────────────────────────────────────
  const displayRender = (labels) => labels[labels.length - 1];

  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  // The cascader now returns an array with two items:
  // [filter category, selected value]
  const handleFilter = (_, data) => {
    setfilterBy(data ? data[0]?.value : "");
    setFilteredValue(data ? data[1]?.value : "");
    setCurrentPage(0);
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // Fetch devices on mount or on changes (search, filter, etc.)
  // ─────────────────────────────────────────────────────────────────────────────
  useEffect(() => {
    fetchInstance();
    // eslint-disable-next-line
  }, [debouncedValue, filteredValue, paginationParams, currentPage]);

  // ─────────────────────────────────────────────────────────────────────────────
  // JSX Rendering
  // ─────────────────────────────────────────────────────────────────────────────
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Radius Devices</h2>
            </Col>
            <Col>
              <Link to={"/pam/radius/onboard"}>
                <Button type="primary">Add Radius Device</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-conatiner"
                  >
                    <Col span={6}>
                      <p className="search-label">Filter by</p>
                      <Cascader
                        options={filterOptions}
                        expandTrigger="hover"
                        displayRender={displayRender}
                        onChange={handleFilter}
                        placeholder="Select filter"
                        showSearch={{
                          filter,
                        }}
                        onClear={() => {
                          setfilterBy("");
                          setFilteredValue("");
                          setCurrentPage(0);
                        }}
                      />
                    </Col>
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <AutoComplete
                          placeholder="Search Device Type"
                          allowClear
                          onSearch={handleSearch}
                          options={searchOptions}
                          onChange={(value) => {
                            setSearch(value);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DataGrid
                apiRef={apiRef}
                loading={isLoading}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                getRowId={(row) => row.id} // 'id' is from the new API
                rows={endpointData}
                paginationMode="server"
                columns={columns}
                rowCount={totalCount}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
                page={currentPage}
                paginationModel={currentPageModel}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
                onPaginationModelChange={(params) => {
                  setCurrentPage(params.page);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ListRadiusDevices;
