import React, { useEffect, useState } from "react";
import {
  Table,
  Select,
  Button,
  Card,
  Row,
  Col,
  Modal,
  message,
  Typography,
  Tooltip,
  Switch,
} from "antd";
import axios from "axios";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { DOMAIN_ID, ORG_ID, REACT_APP_API } from "../../../constants";

const { Text } = Typography;
const { Option } = Select;

function getLocalStorageData(key) {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error(`Error parsing localStorage key "${key}":`, error);
    return null;
  }
}

function setLocalStorageData(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error setting localStorage key "${key}":`, error);
  }
}

export default function JustInTimeWorkflow({ onNavigateNext, onCreatePolicy, loadingPolicy }) {
  const navigate = useNavigate();
  const [messageApi, messageContextHolder] = message.useMessage();

  const [adGroups, setAdGroups] = useState([]);

  const [selections, setSelections] = useState({});

  const [shadowGroups, setShadowGroups] = useState([]);

  const [loading, setLoading] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const isEditMode = searchParams.get("edit") === "true";

  const [isJitActive, setIsJitActive] = useState(false);
  const [policyType, setPolicyType] = useState("");
  const [isScheduleJit, setIsScheduleJit] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  useEffect(() => {
    const policyData = getLocalStorageData("policyDraft.basicPolicyInformation");
    if (!policyData) {
      messageApi.error("Primary Information is missing. Please complete it first!");
      return;
    }

    const pType = policyData?.policyType;
    setPolicyType(pType);
    if (pType !== "GroupAD") {
      setWarningMessage("Just in Time is only available for 'GroupAD' policies.");
    }

    const scheduleType = policyData?.policyJson?.dit?.schedule?.type;
    if (scheduleType === "jit" || scheduleType === "recurring") {
      setIsScheduleJit(true);
      setIsJitActive(true);
    } else {
      setWarningMessage(
        "JIT will be disabled as this policy is not timebound or schedule isn't 'jit'."
      );
    }

    const adGroupsFromPolicy = policyData?.policyJson?.infra?.ad?.ad_groups || [];
    setAdGroups(adGroupsFromPolicy);

    const existingJITObj = policyData?.policyJson?.dit?.jit?.ad?.groups;
    if (existingJITObj && typeof existingJITObj === "object") {
      const inverted = {};
      Object.entries(existingJITObj).forEach(([shadow, ad]) => {
        inverted[ad] = shadow;
      });
      setSelections(inverted);
      setIsJitActive(true);
    } else {
      const initial = {};
      adGroupsFromPolicy.forEach((ad) => {
        initial[ad] = "";
      });
      setSelections(initial);
    }

    const adIds = getLocalStorageData("adIds") || [];
    if (adIds.length === 0) {
      messageApi.error("AD groups not selected.");
      return;
    }
    const firstAdId = adIds[0];
    const orgId = policyData.orgId;
    const tenantId = policyData.tenantId;
    fetchShadowGroups({ orgId, tenantId, adId: firstAdId });
  }, [messageApi]);

  const fetchShadowGroups = async ({ orgId, tenantId, adId }) => {
    setLoading(true);
    try {
      const payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        adId: adId,
      };
      const res = await axios.post(`${REACT_APP_API}/ad/GetShadowGroups`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const groupNames = (res.data.groups || []).map((item) => item.groupName);
      setShadowGroups(groupNames);
    } catch (error) {
      messageApi.error(error.message || "Error fetching shadow groups");
    } finally {
      setLoading(false);
    }
  };

  const handleShadowChange = (adGroup, newShadow) => {
    setSelections((prev) => ({
      ...prev,
      [adGroup]: newShadow,
    }));
  };

  const handleSavePolicy = async () => {
    try {
      setLoading(true);

      if (!isJitActive || !isScheduleJit || policyType !== "GroupAD") {
        return null;
      }

      for (const adGroup of adGroups) {
        if (!selections[adGroup]) {
          messageApi.error(`Please select a shadow group for ${adGroup}.`);
          setLoading(false);
          return null;
        }
      }

      const policyData = getLocalStorageData("policyDraft.basicPolicyInformation");
      if (!policyData) {
        messageApi.error("Primary Information is missing. Cannot save JIT data.");
        return null;
      }

      const policyJson = policyData.policyJson || {};

      const groupsObj = {};
      Object.entries(selections).forEach(([adGroup, shadowGroup]) => {
        if (shadowGroup) {
          groupsObj[shadowGroup] = adGroup;
        }
      });

      if (!policyJson.dit) policyJson.dit = {};
      if (!policyJson.dit.jit) policyJson.dit.jit = {};
      if (!policyJson.dit.jit.ad) policyJson.dit.jit.ad = {};
      policyJson.dit.jit.ad.groups = groupsObj;

      policyData.policyJson = policyJson;
      setLocalStorageData("policyDraft.basicPolicyInformation", policyData);

      return policyData;
    } catch (error) {
      console.error("Error saving JIT data:", error);
      messageApi.error("Failed to save JIT data.");
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleSaveAndNext = async () => {
    const updatedPolicy = await handleSavePolicy();
    if (updatedPolicy) {
      onNavigateNext?.();
    }
  };

  const handleSaveAndCreate = async () => {
    const updatedPolicy = await handleSavePolicy();
    if (updatedPolicy) {
      onCreatePolicy?.({
        orgId: updatedPolicy.orgId,
        tenantId: updatedPolicy.tenantId,
        policyName: updatedPolicy.policyName,
        policyType: updatedPolicy.policyType,
        policyJson: updatedPolicy.policyJson,
      });
    }
  };

  const handleCancel = () => {
    Modal.confirm({
      title: "Are you sure you want to cancel?",
      content: "All unsaved changes will be lost.",
      onOk: () => {
        localStorage.removeItem("policyDraft.basicPolicyInformation");
        localStorage.removeItem("formSessionData");
        localStorage.removeItem("editPolicyData");
        navigate("/pam/policy/v2");
      },
    });
  };

  const columns = [
    {
      title: "AD Group",
      dataIndex: "adGroup",
      key: "adGroup",
    },
    {
      title: "Shadow Group",
      dataIndex: "adGroup", // using the same field for lookup
      key: "shadowGroup",
      render: (adGroup) => {
        const selectedInOtherRows = Object.entries(selections)
          .filter(([group, value]) => group !== adGroup && value)
          .map(([, value]) => value);
        return (
          <Select
            placeholder="Select shadow group"
            style={{ width: 220 }}
            loading={loading}
            value={selections[adGroup] || undefined}
            onChange={(val) => handleShadowChange(adGroup, val)}
            allowClear
            disabled={!isJitActive || !isScheduleJit || policyType !== "GroupAD"}
          >
            {shadowGroups.map((sg) => (
              <Option key={sg} value={sg} disabled={selectedInOtherRows.includes(sg)}>
                {sg}
              </Option>
            ))}
          </Select>
        );
      },
    },
  ];

  const dataSource = adGroups.map((ad) => ({
    key: ad,
    adGroup: ad,
  }));

  return (
    <div style={{ marginTop: 8 }}>
      {messageContextHolder}

      {warningMessage && (
        <Text type="danger" style={{ marginBottom: 16, display: "block" }}>
          {warningMessage}
        </Text>
      )}

      <Card>
        <Row align="middle" justify="space-between" style={{ marginBottom: 24 }}>
          <Col>
            <Text strong style={{ fontSize: 16 }}>
              Activate Just in Time&nbsp;
              <Tooltip title="Toggle to enable or disable JIT for 'GroupAD' and schedule='jit'.">
                <InfoCircleOutlined />
              </Tooltip>
            </Text>
          </Col>
          <Col>
            <Switch
              checked={isScheduleJit ? true : isJitActive}
              onChange={(checked) => setIsJitActive(checked)}
              disabled={policyType !== "GroupAD" || isScheduleJit}
            />
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={loading}
          bordered
        />
      </Card>

      <Row justify="end" style={{ marginTop: 24 }}>
        <Button onClick={handleCancel} style={{ marginRight: 16 }}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={handleSaveAndNext}
          loading={loading}
          style={{ marginRight: 16 }}
        >
          {isEditMode ? "Update and Next" : "Save and Next"}
        </Button>
        <Button type="primary" onClick={handleSaveAndCreate} loading={loadingPolicy}>
          {isEditMode ? "Update Policy" : "Create Policy"}
        </Button>
      </Row>
    </div>
  );
}
