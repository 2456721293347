import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Space} from "antd";

function CreateTenant() {
  const [form] = Form.useForm();
  const handleCreate = (e) => {
    console.log(e);
  };

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row align="middle" justify="space-between">
            <Col>
              <h2 className="title">Create Tenant</h2>
            </Col>
          </Row>
          <Row>
            <Col span={11}>
              <Form
                // labelCol={{
                //   span: 6,
                // }}
                // wrapperCol={{
                //   span: 18,
                // }}
                form={form}
                name="dynamic_form_complex"
                style={{
                  maxWidth: 900,
                }}
                autoComplete="off"
                initialValues={{
                  items: [{}],
                }}
                onFinish={handleCreate}
              >
                <Form.List name="items">
                  {(fields, { add, remove }) => (
                    <div
                      style={{
                        display: "flex",
                        rowGap: 16,
                        flexDirection: "column",
                      }}
                    >
                      {fields.map((field) => (
                        <Card
                          size="small"
                          title={`Tenant Information ${field.name + 1}`}
                          key={field.key}
                          extra={
                            <CloseOutlined
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          }
                        >
                          <Row align="middle" style={{ padding: "1rem" }}>
                            <Col span="24">
                              <Form.Item label="Tenant Name" name={[field.name, "name"]}>
                                <Input />
                              </Form.Item>

                              <h3>Invite Admin</h3>

                              <Form.Item label="Admin Email">
                                <Form.List name={[field.name, "list"]}>
                                  {(subFields, subOpt) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        rowGap: 16,
                                      }}
                                    >
                                      {subFields.map((subField) => (
                                        <>
                                          {/* <Space key={subField.key}> */}
                                          <Row align="middle">
                                            <Col span={22}>
                                              <Form.Item noStyle name={[subField.name, "Email"]}>
                                                <Input placeholder="Email" />
                                              </Form.Item>
                                            </Col>
                                            <Col style={{ marginLeft: "12px" }}>
                                              <CloseOutlined
                                                onClick={() => {
                                                  subOpt.remove(subField.name);
                                                }}
                                              />
                                            </Col>
                                          </Row>
                                          {/* </Space> */}
                                        </>
                                      ))}
                                      <Button type="default" onClick={() => subOpt.add()} block>
                                        Add
                                      </Button>
                                    </div>
                                  )}
                                </Form.List>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                      ))}

                      <Button type="dashed" onClick={() => add()} block>
                        + Add Tenant
                      </Button>
                    </div>
                  )}
                </Form.List>
                <Row>
                  <Col></Col>
                </Row>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ float: "right", marginTop: "1rem" }}
                  >
                    Create Tenant
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default CreateTenant;
