import styled, { keyframes } from "styled-components";
import { Button } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden;
`;

export const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  background: #f9f9f9;
`;

export const MessageWrapper = styled.div`
  display: flex;
  justify-content: ${({ isBot }) => (isBot ? "flex-start" : "flex-end")};
  margin-bottom: ${({ isSameSender }) => (isSameSender ? "10px" : "25px")};
`;

export const MessageBubble = styled.div`
  max-width: 65%;
  background: ${({ isBot }) =>
    isBot
      ? "linear-gradient(135deg, #ffffff, #f0f0f0)"
      : "linear-gradient(135deg,#cfeaff,#9cd5ff)"};
  border: ${({ isBot }) => (isBot ? "1px solid #e0e0e0" : "none")};
  color: ${({ isBot }) => (isBot ? "#333333" : "#ffffff")};
  padding: 10px 14px;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  position: relative;
  font-size: 14px;
  line-height: 1.4;
`;

/**
 * A vertical container for the “options” (one button per line).
 */
export const OptionsContainer = styled.div`
  max-width: 65%;
  display: flex;
  flex-direction: column; /* vertical stack */
  align-items: flex-end; /* keep them aligned to the right edge */
  gap: 8px;
  position: relative;
`;

/**
 * Each option is a button. We'll style them simply:
 *  - White background, colored border
 *  - Slightly rounded corners
 *  - Hover effect
 */
export const OptionButton = styled(Button)`
  border: 1px solid #1890ff;
  color: #1890ff;
  background-color: #fff;
  border-radius: 20px;
  padding: 4px 14px;
  font-size: 13px;
  transition: all 0.2s ease;

  &:hover,
  &:focus {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    transform: translateY(-2px);
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin: ${({ isBot }) => (isBot ? "0 8px 0 0" : "0 0 0 8px")};
`;

export const Timestamp = styled.div`
  font-size: 10px;
  position: absolute;
  bottom: -16px;
  color: #999;
  ${({ isBot }) => (isBot ? "left: 8px;" : "right: 8px;")}
`;

export const TypingIndicator = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

export const Dot = styled.div`
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 2px;
  background-color: #aaa;
  border-radius: 50%;
  animation: ${bounce} 1.4s infinite both;

  &:nth-child(1) {
    animation-delay: -0.32s;
  }
  &:nth-child(2) {
    animation-delay: -0.16s;
  }
`;

export const HeaderDropdown = styled(EllipsisOutlined)`
  color: #fff;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    color: #e6f7ff;
  }
`;
