import React, { useState, useEffect } from "react";
import { Card, Form, Select, Button, Row, Col, Table, Tag, message, Modal, Typography } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import countriesList from "./countries";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;
const { Option } = Select;

const getLocalStorageData = (key) => {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error(`Error parsing localStorage key "${key}":`, error);
    return null;
  }
};

const setLocalStorageData = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error setting localStorage key "${key}":`, error);
  }
};

const LocationAccess = ({ onNavigateNext, onCreatePolicy, loadingPolicy }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const [form] = Form.useForm();
  const [locations, setLocations] = useState([]);
  const [editingKey, setEditingKey] = useState(null);
  const [notification, notificationHolder] = message.useMessage();
  const navigate = useNavigate();
  const [policySaved, setPolicySaved] = useState(false);
  const [payload, setPayload] = useState();
  const [loading, setLoading] = useState(false);
  const isEditMode = searchParams.get("edit") === "true";

  useEffect(() => {
    const basicPolicyDraft = getLocalStorageData("policyDraft.basicPolicyInformation");
    if (!basicPolicyDraft) {
      message.error("Primary Information is missing. Please complete it first!");
      return;
    }
    if (basicPolicyDraft.policyJson?.policyType === "GroupAD") {
      if (basicPolicyDraft.policyJson?.dit?.schedule && !basicPolicyDraft.policyJson?.dit?.jit) {
        message.error("For GroupAD policy, if schedule is present, JIT must also be present.");
        return;
      }
    }
    if (basicPolicyDraft) {
      const existingLocations = basicPolicyDraft.policyJson?.dit?.geolocation?.countries || [];
      const parsedLocations = existingLocations.map((countryStr) => {
        const [code] = countryStr.split(".");
        return { key: code, countries: [code] };
      });
      setLocations(parsedLocations);
    } else {
      notification.error({
        content: "Primary Information is missing. Please complete it first!",
      });
    }
  }, [notification]);

  const addOrEditLocation = (values) => {
    const { countries } = values;

    const normalizeArray = (arr) => arr.slice().sort();

    if (countries && countries.length > 0) {
      const isDuplicate = locations.some((loc) => {
        return normalizeArray(loc.countries).join(",") === normalizeArray(countries).join(",");
      });

      if (isDuplicate && editingKey === null) {
        notification.warning({
          content: "This location with the selected countries already exists.",
        });
        return;
      }
    }

    if (editingKey !== null) {
      setLocations((prev) =>
        prev.map((loc) => (loc.key === editingKey ? { ...loc, ...values } : loc))
      );
      notification.success({
        content: "Location updated successfully!",
      });
    } else {
      const newLocation = {
        key: Date.now(),
        ...values,
      };
      setLocations([...locations, newLocation]);
      notification.success({
        content: "Location added successfully!",
      });
    }

    form.resetFields();
    setEditingKey(null);
  };

  const editLocation = (record) => {
    setEditingKey(record.key);
    form.setFieldsValue(record);
  };

  const removeLocation = (key) => {
    Modal.confirm({
      title: "Are you sure you want to remove this location?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        setLocations((prev) => prev.filter((loc) => loc.key !== key));
        notification.info({
          content: "Location removed.",
        });
      },
    });
  };

  const handleSavePolicy = async () => {
    try {
      setLoading(true);

      if (locations.length === 0) {
        notification.error({
          content: "No locations to save.",
        });
        return null;
      }

      const selectedCountries = locations.flatMap((loc) => loc.countries);
      const uniqueCountries = Array.from(new Set(selectedCountries));
      const formattedCountries = uniqueCountries.map((code) => `${code.toUpperCase()}.ALL.ALL`);

      const basicPolicyDraft = getLocalStorageData("policyDraft.basicPolicyInformation");
      if (!basicPolicyDraft) {
        notification.error({
          content: "Primary Information is missing. Please complete it first!",
        });
        return null;
      }

      const basicPolicyData = { ...basicPolicyDraft };
      const policyJson = { ...basicPolicyData.policyJson };
      const dit = { ...policyJson.dit };

      dit.geolocation = {
        ...dit.geolocation,
        countries: formattedCountries,
      };

      policyJson.dit = dit;

      const updatedBasicPolicyData = {
        ...basicPolicyData,
        policyJson: policyJson,
      };

      setLocalStorageData("policyDraft.basicPolicyInformation", updatedBasicPolicyData);

      setPayload(updatedBasicPolicyData);
      setPolicySaved(true);

      // message.success('Location access settings saved successfully!')
      return updatedBasicPolicyData;
    } catch (error) {
      console.error("Failed to save policy:", error);
      message.error("Failed to save policy.");
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleSaveAndNext = async () => {
    const updatedData = await handleSavePolicy();
    if (updatedData) {
      onNavigateNext();
    }
  };

  const handleSaveAndCreate = async () => {
    const updatedData = await handleSavePolicy();
    if (updatedData) {
      onCreatePolicy({
        orgId: updatedData.orgId,
        tenantId: updatedData.tenantId,
        policyName: updatedData.policyName,
        policyType: updatedData.policyType,
        policyJson: updatedData.policyJson,
      });
    }
  };

  const handleSaveOnly = async () => {
    await handleSavePolicy();
  };

  const handleCancel = () => {
    Modal.confirm({
      title: "Are you sure you want to cancel?",
      content: "All unsaved changes will be lost.",
      onOk: () => {
        localStorage.removeItem("policyDraft.basicPolicyInformation");
        localStorage.removeItem("formSessionData");
        localStorage.removeItem("editPolicyData");
        navigate("/pam/policy/v2");
      },
    });
  };

  const columns = [
    {
      title: "Countries",
      dataIndex: "countries",
      key: "countries",
      render: (countries) =>
        countries.map((code) => {
          const country = countriesList.find((c) => c.code === code);
          return (
            <Tag color="blue" key={code}>
              {country ? country.name : code}
            </Tag>
          );
        }),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button type="link" icon={<EditOutlined />} onClick={() => editLocation(record)} />
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => removeLocation(record.key)}
          />
        </>
      ),
    },
  ];

  return (
    <div>
      {notificationHolder}
      <Card>
        <Form form={form} layout="vertical" onFinish={addOrEditLocation}>
          <Form.Item
            name="countries"
            label="Countries"
            rules={[
              {
                required: true,
                message: "Please select at least one country.",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Select countries"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {countriesList.map((country) => (
                <Option key={country.code} value={country.code}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Row justify="end">
            <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
              {editingKey !== null ? "Update Location" : "Add Location"}
            </Button>
          </Row>
        </Form>
        <Row>
          <Col style={{ marginTop: "10px" }}>
            <Text type="secondary">
              "All these fields are optional and managed by Authnull agent".
            </Text>
          </Col>
        </Row>
      </Card>
      {locations.length > 0 && (
        <>
          <Card type="inner" style={{ marginBottom: "24px", marginTop: "24px" }}>
            <Table
              dataSource={locations}
              columns={columns}
              rowKey="key"
              style={{ marginTop: 16 }}
              pagination={false}
            />
            {isEditMode && (
              <Row>
                <Col style={{ marginTop: "10px" }}>
                  <Text type="secondary">"Dont keep this policy field empty"</Text>
                </Col>
              </Row>
            )}
          </Card>

          <Row justify="end" style={{ marginTop: "24px" }}>
            <Col>
              <Button onClick={handleCancel} style={{ marginRight: "16px" }}>
                Cancel
              </Button>
              <Button type="primary" onClick={handleSaveAndNext} style={{ marginRight: "16px" }}>
                {isEditMode ? "Update and Next" : "Save and Next"}
              </Button>
              <Button type="primary" onClick={handleSaveAndCreate} loading={loadingPolicy}>
                {isEditMode ? "Update Policy" : "Create Policy"}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default LocationAccess;
