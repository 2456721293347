import { Col, Row, Select, Table, Input, message } from "antd";
import { AUTH_TOKEN, REACT_APP_API_DID_URL, ORG_ID, DOMAIN_ID } from "../../constants";
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import axios from "axios";
import { logOutUser } from "../../common";
import { useDebounce } from "../../common/debounce";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import { AutoComplete } from "antd";
import { formatTimeByTimezone } from "../../common/formatTimebyTimezone";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const { Option } = Select;
const UserDID = () => {
  const [issuerData, setIssuerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(null);
  const [notification, setNotification] = message.useMessage();
  const [sortModel, setSortModel] = useState();
  const [currentPageModal, setCurrentPageModal] = useState({
    pageSize: 10,
    page: 0,
  });
  const debouncedValue = useDebounce(search, 500);

  const [options, setOptions] = useState([]);

  const handleSearch = (value) => {
    if (!value) {
      setOptions([]);
      return;
    }

    const filteredOptions = issuerData
      .filter((issuer) => issuer.issuerName.toLowerCase().includes(value.toLowerCase()))
      .map((issuer) => ({
        label: issuer.issuerName,
        value: issuer.issuerName,
      }));

    setOptions(filteredOptions);
  };

  useEffect(() => {
    fetchDID();
  }, [filter, debouncedValue, currentPageModal]);

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };
  const fetchDID = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageNumber: currentPageModal?.page + 1,
      pageSize: currentPageModal?.pageSize,
      search: debouncedValue,
      // filter: {
      //   filterBy: filter ? "status" : "",
      //   value: filter ?? "",
      // },
      filter: "User",
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_DID_URL}/did/DIDList`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        let Data = [];
        if (res.data.didList) {
          Data = res.data.didList.map((did, i) => ({
            id: i,
            lastUpdatedAt: did.lastUpdatedAt,
            issuerName: did.issuerName,
            DIDType: did.DIDType,
            did: did.did,
            credentialIssued: did.credentialIssued === 0 ? "No" : "Yes",
            desc: did.desc,
          }));
        }
        setIssuerData(Data);
        console.log(issuerData);
        setTotalCount(res?.data?.count);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch did",
          });
        }
      });
  };
  const columns = [
    {
      headerName: "User Name",
      field: "issuerName",
      key: "issuerName",
      flex: 1,
    },
    {
      headerName: "Description",
      field: "desc",
      key: "desc",
      flex: 1,
    },
    {
      headerName: "Last Updated",
      field: "lastUpdatedAt",
      key: "lastUpdatedAt",
      flex: 1,
      renderCell: (val) => {
        const timeStamp = val?.row?.lastUpdatedAt;
        const timezone = localStorage.getItem("timezone");
        return formatTimeByTimezone(timeStamp, timezone);
      },
    },
  ];
  const onFilterChange = (_, data) => {
    setFilter(data ? data?.value : "");
  };
  const filterOption = [{ key: "Date", value: "date" }];
  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row style={{ marginBottom: "2rem", gap: "2rem" }} className="search-box-container">
                {/* <Col span={6}>
                  <p className="search-label">Filter by</p>
                  <div>
                    <Select
                      showSearch
                      allowClear
                      placeholder="Filter by status"
                      labelInValue={true}
                      optionFilterProp="children"
                      onChange={onFilterChange}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {filterOption.map((option) => (
                        <Option
                          key={option.value}
                          value={option.value}
                          label={option.key}
                        >
                          {option.key}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col> */}
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <AutoComplete
                      placeholder="Search UserName"
                      allowClear
                      onSearch={handleSearch}
                      options={options}
                      onChange={(value) => {
                        setSearch(value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DataGrid
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              noResultsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
            }}
            rows={issuerData}
            columns={columns}
            paginationMode="server"
            rowCount={totalCount}
            page={currentPageModal.page}
            pageSize={currentPageModal.pageSize}
            onPaginationModelChange={(params) => {
              setCurrentPageModal({
                page: params.page,
                pageSize: 10,
              });
            }}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            style={{ border: "none" }}
            loading={isLoading}
          />
        </Col>
      </Row>
    </>
  );
};

export default UserDID;
