import React, { useEffect, useState } from "react";
import { Col, Row, Select, Tag, message, Button, Modal, Input } from "antd";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { CopyOutlined } from "@ant-design/icons";
import axios from "axios";
import { AUTH_TOKEN, REACT_APP_API, ORG_ID, DOMAIN_ID } from "../../constants";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const PAGE_SIZE = 8;

const ConnectionsScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  // Filters
  const [selectedDatabase, setSelectedDatabase] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedHost, setSelectedHost] = useState(null);
  const [selectedPrivilege, setSelectedPrivilege] = useState(null);

  // Filter lists
  const [databaseList, setDatabaseList] = useState([]);
  const [userList, setUserList] = useState([]);

  // Data and notification
  const [connectionData, setConnectionData] = useState([]);
  const [notification, contextHolder] = message.useMessage();

  // Single source of truth for pagination
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState(null);

  useEffect(() => {
    fetchFilterLists();
  }, []);

  useEffect(() => {
    fetchConnectionData();
  }, [selectedDatabase, selectedUser, selectedHost, selectedPrivilege, paginationModel]);

  const fetchFilterLists = async () => {
    try {
      const payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
      };

      // unique database names
      const dbRes = await axios.post(
        `${REACT_APP_API}/api/v1/databaseService/getUniqueDatabasenames`,
        payload,
        {
          headers: { "X-Authorization": AUTH_TOKEN(), withCredentials: true },
        }
      );

      // unique user names
      const userRes = await axios.post(
        `${REACT_APP_API}/api/v1/databaseService/getUniqueUsernames`,
        payload,
        {
          headers: { "X-Authorization": AUTH_TOKEN(), withCredentials: true },
        }
      );

      setDatabaseList(dbRes.data?.db_names || []);
      setUserList(userRes.data?.usernames || []);
    } catch (error) {
      console.error("Error fetching filter lists:", error);
      notification.error({
        content: "Unable to fetch database/user lists",
      });
    }
  };

  const fetchConnectionData = () => {
    const filters = [];
    if (selectedHost) {
      filters.push({ filterParameter: "hostname", filterValue: selectedHost });
    }
    if (selectedPrivilege) {
      filters.push({ filterParameter: "privilege", filterValue: selectedPrivilege });
    }
    if (selectedUser) {
      filters.push({ filterParameter: "user", filterValue: selectedUser });
    }
    if (selectedDatabase) {
      filters.push({ filterParameter: "database_name", filterValue: selectedDatabase });
    }

    const payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      filters,
      requestId: "",

      page_id: paginationModel.page + 1,
      limit: paginationModel.pageSize,
    };

    setIsLoading(true);
    let userId = localStorage.getItem("userId");
    let email = localStorage.getItem("UserName");

    axios
      .post(`${REACT_APP_API}/api/v1/databaseService/listConnections`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          userid: userId,
          withCredentials: true,
          email: email,
        },
      })
      .then((res) => {
        setIsLoading(false);

        setConnectionData(
          (res?.data?.data || []).map((item, index) => ({
            ...item,
            id: item.id + "-" + index,
          }))
        );
        setTotalCount(res?.data?.total_count || 0);
      })
      .catch((err) => {
        setIsLoading(false);
        notification.error({ content: "Unable to fetch connections" });
        console.error(err);
      });
  };

  // Reset the pagination page to 0 whenever a filter changes
  const resetPageAndSetState = (setter) => (value) => {
    setter(value);
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
  };

  // Handle opening the "Connect Now" modal
  const handleConnectNow = (connection) => {
    setSelectedConnection(connection);
    setModalVisible(true);
  };

  // Copy the connection string from the modal
  const handleCopyConnectionString = () => {
    if (selectedConnection) {
      navigator.clipboard.writeText(selectedConnection.connection_string);
      notification.success({ content: "Connection string copied!" });
    }
  };

  // DataGrid columns
  const columns = [
    {
      headerName: "Hostname",
      field: "ip_address",
      flex: 1,
    },
    {
      headerName: "Database",
      field: "database_name",
      flex: 1.5,
    },

    {
      headerName: "Users",
      field: "users",
      flex: 1.5,
      renderCell: (params) => (
        <>
          {params.value.map((user) => (
            <Tag key={user}>{user}</Tag>
          ))}
        </>
      ),
    },
    {
      headerName: "Privileges",
      field: "user_privilege",
      flex: 1.5,
      renderCell: (params) => (
        <>
          {params.value.map((priv) => {
            let color = "blue";
            if (priv === "READ") color = "green";
            else if (priv === "WRITE") color = "orange";
            else if (priv === "EXECUTE") color = "purple";
            return (
              <Tag key={priv} color={color}>
                {priv}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      renderCell: (params) => (
        <Button type="link" onClick={() => handleConnectNow(params.row)}>
          Connect Now
        </Button>
      ),
    },
  ];

  const appliedFilters = [];
  if (selectedDatabase) {
    appliedFilters.push({ key: "Database", display: `Database: ${selectedDatabase}` });
  }
  if (selectedUser) {
    appliedFilters.push({ key: "User", display: `User: ${selectedUser}` });
  }
  if (selectedHost) {
    appliedFilters.push({ key: "Hostname", display: `Hostname: ${selectedHost}` });
  }
  if (selectedPrivilege) {
    appliedFilters.push({ key: "Privilege", display: `Privilege: ${selectedPrivilege}` });
  }

  return (
    <>
      {contextHolder}
      <Row className="content-container">
        <Col span={24}>
          <Row justify="space-between" style={{ marginBottom: 16 }}>
            <Col>
              <h2 className="title">Database Connections</h2>
            </Col>
          </Row>

          {/* Filters Row */}
          <Row style={{ marginBottom: "1rem", marginTop: "1rem" }} gutter={20}>
            <Col span={4}>
              <p className="search-label">Database</p>
              <Select
                showSearch
                allowClear
                placeholder="Select Database"
                style={{ width: "100%" }}
                value={selectedDatabase}
                onChange={resetPageAndSetState(setSelectedDatabase)}
                filterOption={(input, option) =>
                  (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
                }
              >
                {databaseList.map((db) => (
                  <Select.Option key={db} value={db}>
                    {db}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            <Col span={4}>
              <p className="search-label">User</p>
              <Select
                showSearch
                allowClear
                placeholder="Select User"
                style={{ width: "100%" }}
                value={selectedUser}
                onChange={resetPageAndSetState(setSelectedUser)}
                filterOption={(input, option) =>
                  (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
                }
              >
                {userList.map((user) => (
                  <Select.Option key={user} value={user}>
                    {user}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            <Col span={4}>
              <p className="search-label">Hostname</p>
              <Input
                allowClear
                placeholder="Enter Hostname"
                value={selectedHost}
                onChange={(e) => resetPageAndSetState(setSelectedHost)(e.target.value)}
              />
            </Col>

            <Col span={4}>
              <p className="search-label">Privilege</p>
              <Select
                showSearch
                allowClear
                placeholder="Select Privilege"
                style={{ width: "100%" }}
                value={selectedPrivilege}
                onChange={resetPageAndSetState(setSelectedPrivilege)}
                filterOption={(input, option) =>
                  (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
                }
              >
                <Select.Option value="READ">READ</Select.Option>
                <Select.Option value="WRITE">WRITE</Select.Option>
                <Select.Option value="EXECUTE">EXECUTE</Select.Option>
              </Select>
            </Col>
          </Row>

          {/* Applied Filter Tags */}
          {appliedFilters.length > 0 && (
            <Row style={{ marginBottom: 16 }}>
              <Col>
                <p>Applied Filters</p>
                {appliedFilters.map(({ key, display }) => (
                  <Tag
                    key={key}
                    closable
                    onClose={() => {
                      if (key === "Database") {
                        resetPageAndSetState(setSelectedDatabase)(null);
                      } else if (key === "User") {
                        resetPageAndSetState(setSelectedUser)(null);
                      } else if (key === "Hostname") {
                        resetPageAndSetState(setSelectedHost)(null);
                      } else if (key === "Privilege") {
                        resetPageAndSetState(setSelectedPrivilege)(null);
                      }
                    }}
                    style={{ marginBottom: 8, marginRight: 8 }}
                  >
                    {display}
                  </Tag>
                ))}
              </Col>
            </Row>
          )}

          <Row>
            <Col span={24}>
              <DataGrid
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                rows={connectionData}
                columns={columns}
                paginationMode="server"
                rowCount={totalCount}
                loading={isLoading}
                style={{ border: "none" }}
                paginationModel={paginationModel}
                onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        title="Connection Details"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        centered
        width={800}
        footer={[
          <Button
            key="copy"
            icon={<CopyOutlined />}
            type="primary"
            onClick={handleCopyConnectionString}
          >
            Copy
          </Button>,
          <Button key="close" onClick={() => setModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        {selectedConnection && (
          <div style={{ padding: "2rem", background: "#f7f7f7", borderRadius: 8 }}>
            <h3 style={{ marginBottom: "1rem" }}>Instructions</h3>
            <p style={{ marginBottom: "1rem", fontSize: "16px" }}>
              Please copy and paste the following command to establish a connection to the database.
            </p>
            <pre
              style={{
                background: "#eaeaea",
                padding: "1rem",
                borderRadius: 8,
                overflowX: "auto",
                fontSize: "16px",
                lineHeight: "1.5",
              }}
            >
              {selectedConnection.connection_string}
            </pre>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ConnectionsScreen;
