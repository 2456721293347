import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Select,
  Space,
  Steps,
  Tooltip,
  Typography,
  message,
  Divider,
} from "antd";
import {
  GlobalOutlined,
  InfoCircleOutlined,
  DownloadOutlined,
  FileTextOutlined,
  ConsoleSqlOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import JSZip from "jszip";

const { Title, Text } = Typography;
const { Step } = Steps;

const OnboardWindowsDatabase = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [notification, contextHolder] = message.useMessage();

  // State for Public IP, DB Type, and generated agent key.
  const [publicIp, setPublicIp] = useState("");
  const [dbType, setDbType] = useState("mysql"); // Only MySQL is allowed.
  const [agentKey, setAgentKey] = useState("");
  const [isZipLoading, setIsZipLoading] = useState(false);

  useEffect(() => {
    generateAgentKey();
  }, []);

  // Generate a UUID-like key.
  const generateAgentKey = () => {
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const sections = [8, 4, 4, 4, 12];
    sections.forEach((length) => {
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      result += "-";
    });
    result = result.slice(0, -1);
    setAgentKey(result);
  };

  // Return the raw URL for the Windows installation script and default DB port.
  // For now, only MySQL is supported.
  const getWindowsScriptUrlAndPort = () => {
    return {
      scriptUrl:
        "https://raw.githubusercontent.com/authnull0/windows-endpoint/mysql-db-agent/mysql-db-agent-install.ps1",
      dbPort: 3306,
    };
  };

  // Build content for the db.env file.
  const buildEnvContent = () => {
    const orgId = localStorage.getItem("OrgId") || "105";
    const tenantId = localStorage.getItem("DomainId") || "1";
    const { dbPort } = getWindowsScriptUrlAndPort();
    return `ORG_ID=${orgId}
TENANT_ID=${tenantId}
DB_TYPE=${dbType}
DB_PORT=${dbPort}
TIME_INTERVAL=1
API=https://prod.api.authnull.com
KEY=${agentKey}`;
  };

  // Download the ZIP containing both the installation script and db.env file.
  const downloadZipFolder = async () => {
    if (!publicIp) {
      notification.error({ content: "Please enter the Public IP." });
      return;
    }
    if (!dbType) {
      notification.error({ content: "Please select a Database Type." });
      return;
    }
    setIsZipLoading(true);
    const { scriptUrl } = getWindowsScriptUrlAndPort();
    try {
      const response = await fetch(scriptUrl, { mode: "cors" });
      if (!response.ok) {
        throw new Error("Failed to fetch the installation script");
      }
      const psBlob = await response.blob();
      const envContent = buildEnvContent();

      const zip = new JSZip();
      zip.file("db.env", envContent);
      zip.file("windows-db-agent-install.ps1", psBlob);

      const zipBlob = await zip.generateAsync({ type: "blob" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(zipBlob);
      link.download = "windows-db-agent.zip";
      link.click();
      URL.revokeObjectURL(link.href);
      notification.success({ content: "Package downloaded successfully." });
    } catch (error) {
      notification.error({ content: "Error downloading package." });
      console.error(error);
    } finally {
      setIsZipLoading(false);
    }
  };

  // Define the steps.
  const steps = [
    {
      title: "Enter Public IP",
      icon: <GlobalOutlined />,
      content: (
        <Form form={form} layout="vertical" style={{ marginTop: "1rem" }}>
          <Form.Item
            label={
              <Space>
                <span>Public IP</span>
                <Tooltip title="Enter the Public IP of the target Windows machine">
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              </Space>
            }
            name="publicIp"
            rules={[
              { required: true, message: "Please enter the Public IP." },
              {
                pattern: /^((25[0-5]|2[0-4]\d|[01]?\d?\d)\.){3}(25[0-5]|2[0-4]\d|[01]?\d?\d)$/,
                message:
                  "IP address must be in the format x.x.x.x, with each x between 0 and 255.",
              },
            ]}
          >
            <Input placeholder="e.g. 192.168.1.50" onChange={(e) => setPublicIp(e.target.value)} />
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Select DB Type",
      icon: <ConsoleSqlOutlined />,
      content: (
        <Form form={form} layout="vertical" style={{ marginTop: "1rem" }}>
          <Form.Item
            label={
              <Space>
                <span>Database Type</span>
                <Tooltip title="Database type is fixed to MySQL for now">
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              </Space>
            }
            name="dbType"
            rules={[{ required: true, message: "Database Type is required." }]}
          >
            <Select defaultValue="mysql" onChange={(value) => setDbType(value)}>
              <Select.Option value="mysql">MySQL</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Download ZIP Folder",
      icon: <DownloadOutlined />,
      content: (
        <>
          <Space style={{ marginTop: "1rem" }}>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              loading={isZipLoading}
              onClick={downloadZipFolder}
            >
              Download Package
            </Button>
          </Space>
          <Divider style={{ margin: "1rem" }} />
          <Text>
            Download a zipped folder containing the Windows DB agent installation script and the{" "}
            <code>db.env</code> file.
          </Text>
          <br />
          <Text type="secondary">
            The package includes <code>windows-db-agent-install.ps1</code> and a pre-filled{" "}
            <code>db.env</code> file with your configuration details.
          </Text>
        </>
      ),
    },
    {
      title: "Unzip & Copy",
      icon: <FileTextOutlined />,
      content: <Text>Unzip the downloaded folder and copy it to your target Windows machine.</Text>,
    },
    {
      title: "Open PowerShell",
      icon: <FileTextOutlined />,
      content: (
        <>
          <Text>
            Open PowerShell as an Administrator and change the directory to the unzipped folder’s
            path.
          </Text>
          <Divider style={{ marginTop: "1rem" }} />
          <Text type="secondary">
            For example: <code>cd C:\Downloads\windows-db-agent</code>
          </Text>
        </>
      ),
    },
    {
      title: "Run Commands",
      icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
      content: (
        <>
          <Text>Run the following commands to install and configure the DB agent:</Text>
          <Input
            style={{ border: "none", backgroundColor: "#fafafa", marginTop: "1rem" }}
            size="large"
            readOnly
            value="Set-ExecutionPolicy Unrestricted -Scope CurrentUser"
            suffix={
              <Text
                copyable={{
                  text: "Set-ExecutionPolicy Unrestricted -Scope CurrentUser",
                  tooltips: ["Copy Command", "Copied"],
                }}
                style={{ color: "GrayText" }}
              />
            }
          />
          <Divider style={{ marginTop: "1rem" }} />
          <Input
            style={{ border: "none", backgroundColor: "#fafafa" }}
            size="large"
            readOnly
            value={`.\\${dbType || "mysql"}-db-agent-install.ps1 -OutputPath C:\\authnull-db-agent`}
            suffix={
              <Text
                copyable={{
                  text: `.${
                    dbType ? "\\" + dbType : "\\mysql"
                  }-db-agent-install.ps1 -OutputPath C:\\authnull-db-agent`,
                  tooltips: ["Copy Command", "Copied"],
                }}
                style={{ color: "GrayText" }}
              />
            }
          />
          <Divider style={{ marginTop: "1rem" }} />
          <Text type="secondary">
            The <code>-OutputPath</code> parameter specifies where the agent files will be
            installed.
          </Text>
        </>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <div style={{ width: "75%" }}>
        <h2 className="title">Windows DB Agent Setup</h2>
        <Steps direction="vertical" size="small" current={-1} style={{ marginTop: "50px" }}>
          {steps.map((item, index) => (
            <Step
              key={index}
              title={<Text strong>{item.title}</Text>}
              icon={item.icon}
              description={<div style={{ marginTop: "0.5rem" }}>{item.content}</div>}
            />
          ))}
        </Steps>
        <Row justify="end" style={{ marginTop: 25 }}>
          <Space size="middle">
            <Button type="primary" onClick={() => navigate("/pam/databases")}>
              Finish
            </Button>
          </Space>
        </Row>
      </div>
    </>
  );
};

export default OnboardWindowsDatabase;
