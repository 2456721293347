import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  message,
  Card,
  Typography,
  Tooltip,
  Space,
  Modal,
  Select,
  InputNumber,
  Switch,
} from "antd";
import { InfoCircleOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;
const { Option } = Select;

// Example "ToggleField" based on your snippet
export const ToggleField = ({ label, tooltipTitle, name }) => (
  <Form.Item>
    <Row align="middle" justify="space-between" style={{ width: "100%", margin: "0px" }}>
      <Col>
        <Text style={{ fontWeight: "500" }}>
          {label}{" "}
          {tooltipTitle && (
            <Tooltip title={tooltipTitle}>
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </Text>
      </Col>
      <Col>
        <Form.Item name={name} valuePropName="checked" noStyle>
          <Switch />
        </Form.Item>
      </Col>
    </Row>
  </Form.Item>
);

// We can define the comparator options for the fail counts
const comparatorOptions = [
  { label: "Greater Than", value: ">" },
  { label: "Less Than", value: "<" },
  { label: "Equals", value: "==" },
  { label: "Greater or Equals", value: ">=" },
  { label: "Less or Equals", value: "<=" },
];

// localStorage helpers
const getLocalStorageData = (key) => {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error(`Error parsing localStorage key "${key}":`, error);
    return null;
  }
};

const setLocalStorageData = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error setting localStorage key "${key}":`, error);
  }
};

const UserBehaviorAnalytics = ({ onNavigateNext, onCreatePolicy, loadingPolicy }) => {
  const [form] = Form.useForm();
  const [failCountForm] = Form.useForm(); // separate form if you want or keep in single form
  const searchParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [notification, notificationHolder] = message.useMessage();
  const [policySaved, setPolicySaved] = useState(false);
  const [payload, setPayload] = useState();
  const isEditMode = searchParams.get("edit") === "true";

  // We'll store loginFail / mfaFail in state if we want to show them read-only or something
  const [failCounts, setFailCounts] = useState(null);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const basicPolicyDraft = getLocalStorageData("policyDraft.basicPolicyInformation");
    if (!basicPolicyDraft) {
      message.error("Primary Information is missing. Please complete it first!");
      return;
    }
    if (basicPolicyDraft.policyJson?.policyType === "GroupAD") {
      if (basicPolicyDraft.policyJson?.dit?.schedule && !basicPolicyDraft.policyJson?.dit?.jit) {
        message.error("For GroupAD policy, if schedule is present, JIT must also be present.");
        return;
      }
    }
    // 1) Fill toggles
    const behaviour = basicPolicyDraft.policyJson?.dit?.behaviour;
    if (behaviour) {
      form.setFieldsValue({
        is_trusted_network: !!behaviour.is_trusted_network,
        is_trusted_device: !!behaviour.is_trusted_device,
        disable_logon: !!behaviour.disable_logon,
      });
    }

    // 2) Fill login/mfa fail counts from scores
    const scores = basicPolicyDraft.policyJson?.dit?.scores;
    if (scores) {
      const loginFailCount = scores.login_fail_count || { operator: "", value: 0 };
      const mfaFailCount = scores.mfa_fail_count || { operator: "", value: 0 };

      // We'll store them in separate form or same form, up to you.
      failCountForm.setFieldsValue({
        loginFailComparator: loginFailCount.operator,
        loginFailValue: loginFailCount.value,
        mfaFailComparator: mfaFailCount.operator,
        mfaFailValue: mfaFailCount.value,
      });

      setFailCounts({
        loginFailComparator: loginFailCount.operator,
        loginFailValue: loginFailCount.value,
        mfaFailComparator: mfaFailCount.operator,
        mfaFailValue: mfaFailCount.value,
      });
    }
  }, [form, failCountForm, notification]);

  /**
   * Save toggles + fail counts to localStorage
   */
  const handleSavePolicy = async () => {
    try {
      // Validate toggles
      const toggleValues = await form.validateFields();
      // Validate fail counts
      const failValues = await failCountForm.validateFields();

      const basicPolicyDraft = getLocalStorageData("policyDraft.basicPolicyInformation");
      if (!basicPolicyDraft) {
        message.error("Primary Information is missing. Please complete it first!");
        return null;
      }

      const updatedData = { ...basicPolicyDraft };
      if (!updatedData.policyJson) {
        updatedData.policyJson = {};
      }
      if (!updatedData.policyJson.dit) {
        updatedData.policyJson.dit = {};
      }

      // 1) Save toggles
      updatedData.policyJson.dit.behaviour = {
        is_trusted_network: toggleValues.is_trusted_network,
        is_trusted_device: toggleValues.is_trusted_device,
        disable_logon: toggleValues.disable_logon,
      };

      // 2) Save login/mfa fail counts
      // If you want to do range checks, do them here
      const isValidRange = (val) => Number.isInteger(val) && val >= 0 && val <= 10;
      if (!isValidRange(failValues.loginFailValue) || !isValidRange(failValues.mfaFailValue)) {
        message.error("All fail count values must be integers between 0 and 10.");
        return null;
      }

      updatedData.policyJson.dit.scores = {
        ...updatedData.policyJson.dit.scores,
        login_fail_count: {
          operator: failValues.loginFailComparator,
          value: failValues.loginFailValue,
        },
        mfa_fail_count: {
          operator: failValues.mfaFailComparator,
          value: failValues.mfaFailValue,
        },
      };

      setLocalStorageData("policyDraft.basicPolicyInformation", updatedData);
      setPayload(updatedData);
      setPolicySaved(true);
      return updatedData;
    } catch (err) {
      const errorMessage = err.message || "An unexpected error occurred.";
      message.error(errorMessage);
      return null;
    }
  };

  const handleSaveAndNext = async () => {
    const updatedData = await handleSavePolicy();
    if (updatedData) {
      onNavigateNext();
    }
  };

  const handleSaveAndCreate = async () => {
    const updatedData = await handleSavePolicy();
    if (updatedData) {
      onCreatePolicy({
        orgId: updatedData.orgId,
        tenantId: updatedData.tenantId,
        policyName: updatedData.policyName,
        policyType: updatedData.policyType,
        policyJson: updatedData.policyJson,
      });
    }
  };

  const handleCancel = () => {
    Modal.confirm({
      title: "Are you sure you want to cancel?",
      content: "All unsaved changes will be lost.",
      onOk: () => {
        localStorage.removeItem("policyDraft.basicPolicyInformation");
        localStorage.removeItem("formSessionData");
        localStorage.removeItem("editPolicyData");
        navigate("/pam/policy/v2");
      },
    });
  };

  return (
    <div>
      {notificationHolder}

      {/* 1) Form for toggles */}
      <Form form={form} layout="vertical">
        <Card title="User Behavior">
          <ToggleField
            label="Trusted Network"
            tooltipTitle="Enable or disable trusted network detection."
            name="is_trusted_network"
          />
          <ToggleField
            label="Trusted Device"
            tooltipTitle="Enable or disable trusted device detection."
            name="is_trusted_device"
          />
          <ToggleField
            label="Disable Logon"
            tooltipTitle="Disable user logon under certain conditions."
            name="disable_logon"
          />
          <Row>
            <Col>
              <Text type="secondary">
                "All these fields are optional and managed by Authnull agent."
              </Text>
            </Col>
          </Row>
        </Card>
      </Form>

      {/* 2) Separate card for login/mfa fail counts */}
      <Form form={failCountForm} layout="vertical">
        <Card title="Fail Count Thresholds" style={{ marginTop: 24 }}>
          <Form.Item
            label={
              <span>
                Login Fail Count{" "}
                <Tooltip title="Specify login fail count comparator and value.">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="loginFailComparator"
                  noStyle
                  rules={[{ required: true, message: "Please select comparator." }]}
                >
                  <Select placeholder="Comparator" allowClear style={{ width: "100%" }}>
                    {comparatorOptions.map((comp) => (
                      <Option key={comp.value} value={comp.value}>
                        {comp.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="loginFailValue"
                  noStyle
                  rules={[{ required: true, message: "Enter fail count value." }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Enter login fail count"
                    min={0}
                    max={10}
                    step={1}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            label={
              <span>
                MFA Fail Count{" "}
                <Tooltip title="Specify MFA fail count comparator and value.">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="mfaFailComparator"
                  noStyle
                  rules={[{ required: true, message: "Please select comparator." }]}
                >
                  <Select placeholder="Comparator" allowClear style={{ width: "100%" }}>
                    {comparatorOptions.map((comp) => (
                      <Option key={comp.value} value={comp.value}>
                        {comp.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="mfaFailValue"
                  noStyle
                  rules={[{ required: true, message: "Enter fail count value." }]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Enter MFA fail count"
                    min={0}
                    max={10}
                    step={1}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Row>
            <Col>
              <Text type="secondary">
                "All these fields are optional and managed by Authnull agent."
              </Text>
            </Col>
          </Row>
        </Card>
      </Form>

      {/* Bottom Buttons */}
      <Row justify="end" style={{ marginTop: "24px" }}>
        <Space>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSaveAndNext} type="primary">
            {isEditMode ? "Update and Next" : "Save and Next"}
          </Button>
          <Button onClick={handleSaveAndCreate} type="primary" loading={loadingPolicy}>
            {isEditMode ? "Update Policy" : "Create Policy"}
          </Button>
        </Space>
      </Row>
    </div>
  );
};

export default UserBehaviorAnalytics;
