// import "./styles/index.scss";
import { useEffect, useState } from "react";
import { Avatar, Layout, Menu, Popover, Button, Row, Col, Typography, Tag } from "antd";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";

import {
  ControlOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  RadarChartOutlined,
  SolutionOutlined,
  LogoutOutlined,
  IdcardOutlined,
  KeyOutlined,
  DesktopOutlined,
  SettingOutlined,
  RotateRightOutlined,
  PullRequestOutlined,
  CloudServerOutlined,
  FileProtectOutlined,
  ContainerOutlined,
  RadiusSettingOutlined,
  WindowsOutlined,
  PropertySafetyOutlined,
  DatabaseOutlined,
} from "@ant-design/icons/lib/icons";
import { UserOutlined } from "@ant-design/icons";
import Endpoints from "./endUser/Endpoints";
import MyConnections from "./endUser/MyConnections";
import AssignedVc from "./endUser/AssignedVc";
import Groups from "./directory/groups/Groups";
import Devices from "./directory/Devices";
import ActiveDirectory from "./directory/activeDirectory/ActiveDirectory";
import Jumpserver from "./privilegedAccessManagement/jumpServers/Jumpserver";
import AddJumpServer from "./privilegedAccessManagement/jumpServers/AddJumpServer";
import EndpointUsers from "./privilegedAccessManagement/endpointsUsers/EndpointUsers";
import SessionRecordings from "./privilegedAccessManagement/sessionRecordings/SessionRecordings";
import Connection from "./privilegedAccessManagement/connections/Connection";
import CreateConnection from "./privilegedAccessManagement/connections/CreateConnection";
import PAMEndpoint from "./privilegedAccessManagement/endpoints/Endpoints";
import CheckoutUser from "./privilegedAccessManagement/endpointsUsers/CheckoutUser";
import EndpointGroups from "./privilegedAccessManagement/endpointGroups/EndpointGroups";
import ViewCredentials from "./privilegedAccessManagement/endpointsUsers/ViewCredentials";
import CredentialRotationPolicy from "./privilegedAccessManagement/endpointsUsers/CredentialRotationPolicy";
import AddEndpointUser from "./privilegedAccessManagement/endpointsUsers/AddEndpointUser";
import ForceResetCredentials from "./privilegedAccessManagement/endpointsUsers/ForceResetCredentials";
import CredentialRotation from "./privilegedAccessManagement/credentialRotation/CredentialRotation";
import AddPasswordPolicy from "./privilegedAccessManagement/credentialRotation/AddPasswordPolicy";
import AddSshKeyPolicy from "./privilegedAccessManagement/credentialRotation/AddSshKeyPolicy";
import PasswordPolicies from "./privilegedAccessManagement/endpoints/PasswordPolicies";
import DidDashboard from "./did/dashboard";
import DIDS from "./did/dids";
import WalletUsers from "./did/walletUsers";
import Dashboard from "./dashboard";
import Credential from "./did/credentials";
import VerifiableCredential from "./did/verifiableCredential";
import TransactionLog from "./did/transactionLogs";
import EndpointGroupDetails from "./privilegedAccessManagement/endpointGroups/EndpointGroupDetails";
import EndpointDetails from "./privilegedAccessManagement/endpoints/EndpointDetails";
import AddHolderrDID from "./did/addHolderDID";
import AddUserDID from "./did/addUserDID";
import UserDetails from "./directory/UserDetails";
import UserDetailsGroups from "./directory/UserDetailsGroups";
import UserDetailsProfile from "./directory/UserDetailsProfile";
import EndpointUserDetails from "./privilegedAccessManagement/endpointsUsers/EndpointUserDetails";
import AddDirectory from "./directory/activeDirectory/addDirectory";
import ADStep from "./directory/activeDirectory/step";
import ServiceCredentials from "./serviceAccount/ServiceCredentials";
import AddServiceAccount from "./serviceAccount/AddServiceAccount";
import ServiceAccount from "./serviceAccount/ServiceAccount";
import AddWorkloadIdentity from "./workloadIdentity/AddworkloadIdentity";
import WorkloadIdentity from "./workloadIdentity/workloadIdentity";
import WorkloadConditionalAccess from "./workloadIdentity/WorkloadConditionalaccess";
import { REACT_APP_URL } from "../constants";
import WindowsEndpoints from "./windows/windowsPam";
import JitPolicy from "./windows/jitPolicy";
import JitConnections from "./windows/jitConnections";
import EndpointPolicies from "./windows/endpointPolicies";
import CreateWindowsPolicy from "./windows/createWindowsPolicy";
import ApplicationRequest from "./windows/applicationRequest";
import RequestLogs from "./windows/request";
import AuditLogs from "./windows/auditLogs";
import Settings from "./SuperAdmin/settings/Settings";
import AdminDashboard from "./SuperAdmin/Dashboard";
import OktaSetup from "./SuperAdmin/settings/OktaSetup";
import Details from "./SuperAdmin/Details";
import Tenant from "./SuperAdmin/Tenant";
import AddTenant from "./SuperAdmin/AddTenant";
import AuthenticationPolicy from "./authenticationPolicy/request/Request";
import CreateSimplifiedAccessPolicy from "./windows/simplifiedAccessPolicy";
import TenantConfig from "./windows/tenantConfig";
import Tour from "./tour";
import PolicyList from "./authenticationPolicy/policies/Policy";
import Module from "./onboarding/Module";
import Welcome from "./onboarding/Welcome";
import ConfigOnboard from "./onboarding/ConfigOnboard";
import RiskBasedMFA from "./onboarding/setups/RiskBasedMFA";
import PamServiceAccount from "./onboarding/setups/PamServiceAccount";
import PamLocalAccount from "./onboarding/setups/PamLocalAccount";
import Splunk from "./splunk/Splunk";
import Logs from "./authenticationPolicy/logs";
import { OnboardLinuxV2 } from "./privilegedAccessManagement/endpoints/addEndpoint/OnboardLinuxV2";
import { OnboardWindowV2 } from "./privilegedAccessManagement/endpoints/addEndpoint/OnboardWindowV2";
import SecretsList from "./secretsManagement/SecretsList";
import CreatePolicyV3 from "./authenticationPolicy/policies/CreatePolicyV3";
import Policy from "./endUser/Policy";
import Request from "./endUser/Request";
import AddSecret from "./secretsManagement/AddSecret";
import AdUser from "./directory/users/AdUser";
import { UseLogout } from "../common/UseLogout";
import Logout from "./unauthorized/Logout";
import AgentLogs from "./privilegedAccessManagement/endpoints/AgentLogs";
import CreatePolicyDIT from "./dit/createPolicy/createPolicy";
import AllAgentLogs from "./agentLogs/AllLogs";
import EndpointRulesLogs from "./dit/endpointsRules/logs/logs";
import EndpointRulesRequests from "./dit/endpointsRules/requests/requests";
import EndpointRulesPolicies from "./dit/endpointsRules/policies/policies";
import CreatePolicyV4 from "./authenticationPolicy/policies/CreatePolicyV4";
import CreatePolicyBasics from "./policyEngine/createPolicy";
import Policies from "./policyEngine/policies";
import ListRadiusDevices from "./radius/listRadiusDevices";
import OnboardRadiusDevices from "./radius/onboardRadiusDevices";
import Database from "./database/database";
import DBUsers from "./database/users";
import UserPrivilegesScreen from "./database/userPrivileges";
import OnboardWindowDatabase from "./database/addWindowsDatabase";
import OnboardLinuxDatabase from "./database/addLinuxDatabase";
import DatabaseConnections from "./database/databaseConnections";

const { Text } = Typography;
const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

function PrimaryLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userRole] = useState(localStorage.getItem("UserRole"));
  const [userName] = useState(localStorage.getItem("UserName"));
  const [openKeys, setOpenKeys] = useState([]);
  const [mode, setMode] = useState(localStorage.getItem("currentMode") || "pam");
  const [mainMenuSelectedValues, setMainMenuSelectedValues] = useState([]);
  const ENVIRONMENT = process.env.NODE_ENV;
  const logOutUser = UseLogout();
  const currentPath = location.pathname;

  useEffect(() => {
    const modeFromPath = currentPath.split("/")[1] || "pam";
    setMode(modeFromPath);
  }, [currentPath]);

  // Update the sidebar selected key based on the full path.
  useEffect(() => {
    const cleanedPath = currentPath.replace(/^\/+/, "");
    setMainMenuSelectedValues([cleanedPath]);
  }, [currentPath]);

  useEffect(() => {
    if (currentPath.startsWith("/pam/databases")) {
      setOpenKeys(["databases"]);
    } else if (
      currentPath.startsWith("/pam/endpointsUsers") ||
      currentPath.startsWith("/pam/endpointGroups") ||
      currentPath.startsWith("/pam/endpoint")
    ) {
      setOpenKeys(["endpoint"]);
    } else if (currentPath.startsWith("/pam/jumpServer")) {
      setOpenKeys(["jumpServer"]);
    } else if (currentPath.startsWith("/directory/did")) {
      setOpenKeys(["did"]);
    } else {
      setOpenKeys([]);
    }
  }, [currentPath]);

  const handleNavigation = (path) => {
    if (localStorage.getItem("token")) {
      navigate(path);
    } else {
      logOutUser();
    }
  };

  const onOpenChange = (keys) => {
    if (keys.length > 1) {
      setOpenKeys([keys.pop()]);
    } else {
      setOpenKeys(keys);
    }
  };

  const [popoverVisible, setPopoverVisible] = useState(false);
  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };
  const handleOptionSelect = () => {
    setPopoverVisible(false);
  };

  const section = (
    <Row gutter={[16, 16]} align="middle" style={{ width: "305px", marginTop: "-20px" }}>
      <Col span={24}>
        <Row
          gutter={16}
          align="middle"
          style={{
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            marginLeft: "-12px",
            marginRight: "-12px",
            background: "#000",
          }}
        >
          <Col>
            <Avatar size={32} icon={<UserOutlined />} />
          </Col>
          <Col>
            <Text style={{ color: "#fff" }} strong>
              {localStorage.getItem("UserName")}
              {userRole !== "SUPERADMIN" && "(" + localStorage.getItem("Tenantname") + ")"}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Menu mode="inline" defaultSelectedKeys={["1"]} style={{ borderRight: 0 }}>
          {userRole === "ADMIN" && (
            <>
              <Menu.Item
                icon={<ControlOutlined />}
                key="pam"
                onClick={() => {
                  handleOptionSelect();
                  handleNavigation("/pam/endpointsUsers");
                  setMode("pam");
                }}
                style={{ padding: "16px" }}
              >
                PAM
              </Menu.Item>
              <Menu.Item
                icon={<SolutionOutlined />}
                key="directory"
                onClick={() => {
                  handleOptionSelect();
                  handleNavigation("/directory/users");
                  setMode("directory");
                }}
                style={{ padding: "16px" }}
              >
                Directory
              </Menu.Item>
              <Menu.Item
                icon={<RadiusSettingOutlined />}
                key="Config"
                onClick={() => {
                  handleOptionSelect();
                  handleNavigation("/tenantConfig");
                  setMode("tenantConfig");
                }}
                style={{ padding: "16px" }}
              >
                Tenant Config
              </Menu.Item>
              {(REACT_APP_URL === "https://localhost:5173/" || ENVIRONMENT === "testing") && (
                <>
                  <Menu.Item
                    icon={<WindowsOutlined />}
                    key="windows"
                    onClick={() => {
                      handleOptionSelect();
                      handleNavigation("/windows/endpoints");
                      setMode("window");
                    }}
                    style={{ padding: "16px" }}
                  >
                    Windows
                  </Menu.Item>
                </>
              )}
            </>
          )}
          <Menu.Item
            icon={<LogoutOutlined />}
            key="logout"
            onClick={() => {
              logOutUser();
            }}
            style={{ color: "blue", padding: "16px" }}
          >
            Logout
          </Menu.Item>
        </Menu>
      </Col>
    </Row>
  );

  const withoutHeaderPage = ["/onboard", "/onboard/module", "/logout"];

  return (
    <>
      <Layout style={{ minHeight: "100vh" }} className="app-container">
        {!withoutHeaderPage.includes(currentPath) && (
          <Header className="header-container">
            <h1 className="logo-container">
              <RadarChartOutlined />
              Authnull
            </h1>
          </Header>
        )}

        <Layout className="body-container" style={{ flex: 1 }} hasSider>
          {!withoutHeaderPage.includes(currentPath) && (
            <Sider width={280} className="left-menu-container">
              <div className="sidebar__container">
                <div className="sidebar__container__menu">
                  <Menu
                    mode="inline"
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    style={{ borderRight: 0 }}
                    selectedKeys={mainMenuSelectedValues}
                  >
                    {userRole === "ADMIN" ? (
                      <>
                        <Menu.Item
                          icon={<DashboardOutlined />}
                          key=""
                          onClick={() => handleNavigation("/")}
                        >
                          Dashboard
                        </Menu.Item>
                        {ENVIRONMENT === "development" && (
                          <Menu.Item
                            icon={<DashboardOutlined />}
                            key="onboard/config"
                            onClick={() => handleNavigation("/onboard/config")}
                          >
                            Getting Started
                          </Menu.Item>
                        )}
                        {mode === "pam" && (
                          <>
                            <SubMenu key="endpoint" title="Endpoint" icon={<ControlOutlined />}>
                              <Menu.Item
                                key="pam/endpointsUsers"
                                onClick={() => handleNavigation("/pam/endpointsUsers")}
                              >
                                Endpoints Users
                              </Menu.Item>
                              <Menu.Item
                                key="pam/endpointGroups"
                                onClick={() => handleNavigation("/pam/endpointGroups")}
                              >
                                Endpoints Groups
                              </Menu.Item>
                              <Menu.Item
                                key="pam/endpoint"
                                onClick={() => handleNavigation("/pam/endpoint")}
                              >
                                Endpoints
                              </Menu.Item>
                              <Menu.Item
                                key="pam/radius"
                                onClick={() => handleNavigation("/pam/radius")}
                              >
                                Radius
                              </Menu.Item>
                            </SubMenu>
                            <SubMenu
                              key="jumpServer"
                              title="Jump Server"
                              icon={<CloudServerOutlined />}
                            >
                              <Menu.Item
                                key="pam/jumpServer"
                                onClick={() => handleNavigation("/pam/jumpServer")}
                              >
                                Jump Servers
                              </Menu.Item>
                              <Menu.Item
                                key="pam/jumpServer-connections"
                                onClick={() => handleNavigation("/pam/jumpServer-connections")}
                              >
                                Connections
                              </Menu.Item>
                              <Menu.Item
                                key="pam/jumpServer-sessionRecordings"
                                onClick={() =>
                                  handleNavigation("/pam/jumpServer-sessionRecordings")
                                }
                              >
                                Session Recording
                              </Menu.Item>
                            </SubMenu>
                            <Menu.Item
                              key="pam/credentialRotation"
                              icon={<RotateRightOutlined />}
                              onClick={() => handleNavigation("/pam/credentialRotation")}
                            >
                              Credential Rotation
                            </Menu.Item>
                            <Menu.Item
                              key="pam/policies"
                              icon={<PullRequestOutlined />}
                              onClick={() => handleNavigation("/pam/policy/v2")}
                            >
                              Policy Engine
                            </Menu.Item>
                            <Menu.Item
                              key="pam/secrets"
                              icon={<FileProtectOutlined />}
                              onClick={() => handleNavigation("/pam/secrets")}
                            >
                              Secrets Management
                            </Menu.Item>
                            <SubMenu key="databases" icon={<DatabaseOutlined />} title="Databases">
                              <Menu.Item
                                key="pam/databases"
                                onClick={() => handleNavigation("/pam/databases")}
                              >
                                Databases
                              </Menu.Item>
                              <Menu.Item
                                key="pam/databases/users"
                                onClick={() => handleNavigation("/pam/databases/users")}
                              >
                                Users
                              </Menu.Item>
                              <Menu.Item
                                key="pam/databases/userPrivileges"
                                onClick={() => handleNavigation("/pam/databases/userPrivileges")}
                              >
                                User Privileges
                              </Menu.Item>
                              <Menu.Item
                                key="pam/databases/connections"
                                onClick={() => handleNavigation("/pam/databases/connections")}
                              >
                                Connections
                              </Menu.Item>
                            </SubMenu>
                          </>
                        )}
                        {mode === "directory" && (
                          <>
                            <SubMenu key="directory" icon={<SolutionOutlined />} title="Directory">
                              <Menu.Item
                                key="directory/users"
                                onClick={() => handleNavigation("/directory/users")}
                              >
                                Users
                              </Menu.Item>
                              <Menu.Item
                                key="directory/groups"
                                onClick={() => handleNavigation("/directory/groups")}
                              >
                                Groups
                              </Menu.Item>
                              <Menu.Item
                                key="directory/activeDirectory"
                                onClick={() => handleNavigation("/directory/activeDirectory")}
                              >
                                Active Directory
                              </Menu.Item>
                            </SubMenu>
                            <SubMenu key="did" icon={<CreditCardOutlined />} title="DID">
                              <Menu.Item
                                key="directory/did-dashboard"
                                onClick={() => handleNavigation("/directory/did-dashboard")}
                              >
                                Dashboard
                              </Menu.Item>
                              <Menu.Item
                                key="directory/did"
                                onClick={() => handleNavigation("/directory/did")}
                              >
                                DIDs
                              </Menu.Item>
                              <Menu.Item
                                key="directory/did-walletUser"
                                onClick={() => handleNavigation("/directory/did-walletUser")}
                              >
                                Wallet Users
                              </Menu.Item>
                              <Menu.Item
                                key="directory/did-verifiableCredentials"
                                onClick={() =>
                                  handleNavigation("/directory/did-verifiableCredentials")
                                }
                              >
                                Verifiable Credentials
                              </Menu.Item>
                              <Menu.Item
                                key="directory/did-transactionLogs"
                                onClick={() => handleNavigation("/directory/did-transactionLogs")}
                              >
                                Transaction Logs
                              </Menu.Item>
                            </SubMenu>
                          </>
                        )}
                        {mode === "dit" && (
                          <>
                            <SubMenu
                              key="endpointRules"
                              icon={<PullRequestOutlined />}
                              title="Endpoint Rules"
                            >
                              <Menu.Item
                                key="dit/endpointRules"
                                onClick={() => handleNavigation("/dit/endpointRules/requests")}
                              >
                                Requests
                              </Menu.Item>
                              <Menu.Item
                                key="dit/endpointRules/policies"
                                onClick={() => handleNavigation("/dit/endpointRules/policies")}
                              >
                                Policies
                              </Menu.Item>
                              <Menu.Item
                                key="endpointRules/logs"
                                onClick={() => handleNavigation("/dit/endpointRules/logs")}
                              >
                                Authentication Logs
                              </Menu.Item>
                            </SubMenu>
                          </>
                        )}
                        {(REACT_APP_URL === "https://localhost:5173/" ||
                          ENVIRONMENT === "testing") &&
                          mode === "window" && (
                            <SubMenu key="windows" icon={<SolutionOutlined />} title="Windows EPS">
                              <Menu.Item
                                key="windowsEndpoints"
                                onClick={() => handleNavigation("/windows/endpoints")}
                              >
                                Windows Endpoints
                              </Menu.Item>
                              <Menu.Item
                                key="endpointPolicies"
                                onClick={() => handleNavigation("/windows/endpointPolicies")}
                              >
                                Endpoint Policies
                              </Menu.Item>
                              <Menu.Item
                                key="requestLogs"
                                onClick={() => handleNavigation("/windows/requestLogs")}
                              >
                                Request Logs
                              </Menu.Item>
                              <Menu.Item
                                key="auditLogs"
                                onClick={() => handleNavigation("/windows/auditLogs")}
                              >
                                Audit Logs
                              </Menu.Item>
                            </SubMenu>
                          )}

                        {REACT_APP_URL === "http://localhost:5173/" ||
                          (ENVIRONMENT === "testing" && mode === "dpa" && (
                            <SubMenu key="dfa" icon={<SolutionOutlined />} title="DPA PAM">
                              <Menu.Item
                                key="windowsJit"
                                onClick={() => handleNavigation("/windows/jit")}
                              >
                                JIT Access Policies
                              </Menu.Item>
                              <Menu.Item
                                key="windowsJitConnection"
                                onClick={() => handleNavigation("/windows/jitConnections")}
                              >
                                JIT Connections
                              </Menu.Item>
                            </SubMenu>
                          ))}

                        {REACT_APP_URL === "https://localhost:5173/" ||
                          (ENVIRONMENT === "testing" && mode === "pam" && (
                            <SubMenu
                              key="workload"
                              icon={<IdcardOutlined />}
                              title="Workload Identity"
                            >
                              <Menu.Item
                                key="workloadIdentity"
                                onClick={() => handleNavigation("/workloadIdentity")}
                              >
                                Workload Identity
                              </Menu.Item>
                            </SubMenu>
                          ))}

                        {mode === "tenantConfig" && (
                          <Menu.Item
                            key="tenantConfig"
                            icon={<RadiusSettingOutlined />}
                            onClick={() => handleNavigation("/tenantConfig")}
                          >
                            Tenant Config
                          </Menu.Item>
                        )}
                      </>
                    ) : userRole === "SUPERADMIN" ? (
                      <>
                        <Menu.Item
                          icon={<DashboardOutlined />}
                          key="org/dashboard"
                          onClick={() => handleNavigation("/org/dashboard")}
                        >
                          Dashboard
                        </Menu.Item>
                        <Menu.Item
                          icon={<KeyOutlined />}
                          key="tenant"
                          onClick={() => handleNavigation("/tenant")}
                        >
                          Tenant
                        </Menu.Item>
                        <Menu.Item
                          icon={<SettingOutlined />}
                          key="setting"
                          onClick={() => handleNavigation("/setting")}
                        >
                          Settings
                        </Menu.Item>
                      </>
                    ) : (
                      <>
                        <Menu.Item
                          icon={<DesktopOutlined />}
                          key="endUser/endpoints"
                          onClick={() => handleNavigation("/endUser/endpoints")}
                        >
                          Endpoints
                        </Menu.Item>
                        <Menu.Item
                          icon={<DashboardOutlined />}
                          key="endUser/connections"
                          onClick={() => handleNavigation("/endUser/connections")}
                        >
                          Connections
                        </Menu.Item>
                        <Menu.Item
                          icon={<KeyOutlined />}
                          key="endUser/assignedVcs"
                          onClick={() => handleNavigation("/endUser/assignedVcs")}
                        >
                          Assigned VC's
                        </Menu.Item>
                        <Menu.Item
                          key="endUser/request"
                          icon={<PullRequestOutlined />}
                          onClick={() => handleNavigation("/endUser/request")}
                        >
                          Requests
                        </Menu.Item>
                        <Menu.Item
                          key="endUser/policies"
                          icon={<ContainerOutlined />}
                          onClick={() => handleNavigation("/endUser/policies")}
                        >
                          Policies
                        </Menu.Item>
                      </>
                    )}
                  </Menu>
                </div>
                <div className="sidebar__container__footer">
                  <Popover
                    placement="topRight"
                    content={section}
                    trigger="click"
                    visible={popoverVisible}
                    onVisibleChange={handlePopoverVisibleChange}
                  >
                    <Button
                      type="text"
                      icon={
                        mode === "pam" ? (
                          <ControlOutlined />
                        ) : mode === "directory" ? (
                          <SolutionOutlined />
                        ) : mode === "window" ? (
                          <WindowsOutlined />
                        ) : mode === "tenantConfig" ? (
                          <RadiusSettingOutlined />
                        ) : mode === "dit" ? (
                          <PropertySafetyOutlined />
                        ) : (
                          <SettingOutlined />
                        )
                      }
                      onClick={() => setPopoverVisible(!popoverVisible)}
                      style={{
                        color: "#1890ff",
                        paddingLeft: "24px",
                      }}
                    >
                      {userRole === "ADMIN" && mode
                        ? mode === "pam"
                          ? "PAM"
                          : mode === "dit"
                          ? "DIT"
                          : mode === "window"
                          ? "Windows"
                          : mode === "directory"
                          ? "Directory"
                          : mode === "tenantConfig"
                          ? "Tenant Config"
                          : "Switch"
                        : "Switch"}
                    </Button>
                  </Popover>
                </div>
              </div>
            </Sider>
          )}
          <Content
            className={
              withoutHeaderPage.includes(currentPath)
                ? "without-header page-container"
                : "with-header page-container"
            }
          >
            <Routes>
              <Route path="logout" element={<Logout />} />
              {userRole === "ADMIN" ? (
                <>
                  <Route path="" element={<Dashboard />} />
                  <Route path="tour" element={<Tour />} />
                  <Route path="pam/*">
                    <Route path="jumpServer/*">
                      <Route path="" element={<Jumpserver />} />
                      <Route path="add" element={<AddJumpServer />} />
                    </Route>
                    <Route path="jumpServer-connections/*">
                      <Route path="" element={<Connection />} />
                      <Route path=":publicIpAddress" element={<Connection />} />
                      <Route path="createConnection" element={<CreateConnection />} />
                    </Route>
                    <Route path="jumpServer-sessionRecordings/*">
                      <Route path="" element={<SessionRecordings />} />
                    </Route>
                    <Route path="endpoint/*">
                      <Route path="" element={<PAMEndpoint />} />
                      <Route path="addEndpoint/*">
                        <Route path="linux" element={<OnboardLinuxV2 />} />
                        <Route path="windows" element={<OnboardWindowV2 />} />
                      </Route>
                      <Route path="endpointDetails" element={<EndpointDetails />} />
                      <Route path="passwordPolicies" element={<PasswordPolicies />} />
                      <Route path="agentLogs" element={<AgentLogs />} />
                    </Route>
                    <Route path="radius/*">
                      <Route path="" element={<ListRadiusDevices />} />
                      <Route path="onboard" element={<OnboardRadiusDevices />} />
                    </Route>
                    <Route path="endpointsUsers/add" element={<AddEndpointUser />} />
                    <Route path="endpointsUsers" element={<EndpointUsers />} />
                    <Route path="endpointuserDetails" element={<EndpointUserDetails />} />
                    <Route path="endpointsUsers/checkoutUser" element={<CheckoutUser />} />
                    <Route
                      path="rotation/:instanceId/:epmUserId"
                      element={<CredentialRotationPolicy />}
                    />
                    <Route
                      path="endpointsUsers/reset-credentials"
                      element={<ForceResetCredentials />}
                    />
                    <Route
                      path="view-credentials/:instanceId/:epmUserId"
                      element={<ViewCredentials />}
                    />
                    <Route path="endpointGroups/*">
                      <Route path="" element={<EndpointGroups />} />
                      <Route path="endpointGroupDetails" element={<EndpointGroupDetails />} />
                    </Route>
                    <Route path="sessionRecordings/:id" element={<SessionRecordings />} />
                    <Route path="credentialRotation/*">
                      <Route path="" element={<CredentialRotation />} />
                      <Route path="addPassword" element={<AddPasswordPolicy />} />
                      <Route path="addSshKeys" element={<AddSshKeyPolicy />} />
                    </Route>
                    <Route path="policy/*">
                      <Route path="" element={<PolicyList />} />
                      <Route path="createEndpointAccessPolicy" element={<CreatePolicyV3 />} />
                      <Route path="createEndpointAccessPolicyV4" element={<CreatePolicyV4 />} />
                      <Route path="v2" element={<Policies />} />
                      <Route path="v2/createPolicy" element={<CreatePolicyBasics />} />
                    </Route>
                    <Route path="policy-request" element={<AuthenticationPolicy />} />
                    <Route path="policy-logs" element={<Logs />} />
                    <Route path="secrets/*">
                      <Route path="" element={<SecretsList />} />
                      <Route path="add" element={<AddSecret />} />
                    </Route>
                    <Route path="databases/*">
                      <Route path="" element={<Database />} />
                      <Route path="users" element={<DBUsers />} />
                      <Route path="userPrivileges" element={<UserPrivilegesScreen />} />
                      <Route path="add/windows" element={<OnboardWindowDatabase />} />
                      <Route path="add/linux" element={<OnboardLinuxDatabase />} />
                      <Route path="connections" element={<DatabaseConnections />} />
                    </Route>
                  </Route>
                  <Route path="windows/*">
                    <Route path="endpoints" element={<WindowsEndpoints />} />
                    <Route path="jit" element={<JitPolicy />} />
                    <Route path="jitConnections" element={<JitConnections />} />
                    <Route path="createWindowPolicy" element={<CreateWindowsPolicy />} />
                    <Route
                      path="createSimplifiedAccessPolicy"
                      element={<CreateSimplifiedAccessPolicy />}
                    />
                    <Route path="tenantConfig" element={<TenantConfig />} />
                    <Route path="applicationRequest" element={<ApplicationRequest />} />
                    <Route path="auditLogs" element={<AuditLogs />} />
                    <Route path="endpointPolicies" element={<EndpointPolicies />} />
                    <Route path="requestLogs" element={<RequestLogs />} />
                  </Route>
                  <Route path="directory/*">
                    <Route path="users" element={<AdUser />} />
                    <Route path="groups" element={<Groups />} />
                    <Route path="devices" element={<Devices />} />
                    <Route path="activeDirectory/addDirectory/step" element={<ADStep />} />
                    <Route path="activeDirectory/addDirectory" element={<AddDirectory />} />
                    <Route path="activeDirectory" element={<ActiveDirectory />} />
                    <Route path="userDetails" element={<UserDetails />} />
                    <Route path="userDetailsGroups" element={<UserDetailsGroups />} />
                    <Route path="userDetailsProfile" element={<UserDetailsProfile />} />
                    <Route path="did/*">
                      <Route path="" element={<DIDS />} />
                      <Route path="addIssuerDID" element={<AddUserDID />} />
                      <Route path="addHolderDID" element={<AddHolderrDID />} />
                    </Route>
                    <Route path="did-dashboard" element={<DidDashboard />} />
                    <Route path="did-walletUser" element={<WalletUsers />} />
                    <Route path="did-walletUser/view-credentials" element={<Credential />} />
                    <Route path="did-verifiableCredentials" element={<VerifiableCredential />} />
                    <Route path="did-transactionLogs" element={<TransactionLog />} />
                  </Route>
                  <Route path="tenantConfig" element={<TenantConfig />} />
                  <Route path="serviceAccount/*">
                    <Route path="" element={<ServiceAccount />} />
                    <Route path="addServiceAccount" element={<AddServiceAccount />} />
                  </Route>
                  <Route path="serviceCredentials" element={<ServiceCredentials />} />
                  <Route path="workloadIdentity/*">
                    <Route path="" element={<WorkloadIdentity />} />
                    <Route path="addWorkloadIdentity" element={<AddWorkloadIdentity />} />
                    <Route
                      path="workloadConditionalaccess"
                      element={<WorkloadConditionalAccess />}
                    />
                  </Route>
                  <Route path="onboard/*">
                    <Route path="" element={<Welcome />} />
                    <Route path="module" element={<Module />} />
                    <Route path="config" element={<ConfigOnboard />} />
                    <Route path="setup/*">
                      <Route path="mfa" element={<RiskBasedMFA />} />
                      <Route path="serviceAccount" element={<PamServiceAccount />} />
                      <Route path="localAccount" element={<PamLocalAccount />} />
                      <Route path="tenantConfig" element={<TenantConfig />} />
                    </Route>
                  </Route>
                  <Route path="splunk" element={<Splunk />} />
                  <Route path="dit/*">
                    <Route path="createPolicy" element={<CreatePolicyDIT />} />
                    <Route path="endpointRules/logs" element={<EndpointRulesLogs />} />
                    <Route path="endpointRules/requests" element={<EndpointRulesRequests />} />
                    <Route path="endpointRules/policies" element={<EndpointRulesPolicies />} />
                  </Route>
                  <Route path="logs" element={<AllAgentLogs />} />
                </>
              ) : userRole === "SUPERADMIN" ? (
                <>
                  <Route path="tenant/*">
                    <Route path="" element={<Tenant />} />
                    <Route path="add" element={<AddTenant />} />
                  </Route>
                  <Route path="setting/*">
                    <Route path="" element={<Settings />} />
                    <Route path="okta" element={<OktaSetup />} />
                  </Route>
                  <Route path="org/dashboard/*">
                    <Route path="" element={<AdminDashboard />} />
                    <Route path="details" element={<Details />} />
                  </Route>
                </>
              ) : (
                <>
                  <Route path="endUser/*">
                    <Route path="endpoints" element={<Endpoints />} />
                    <Route path="endpointUsers" element={<EndpointUsers />} />
                    <Route path="connections" element={<MyConnections />} />
                    <Route path="assignedVcs" element={<AssignedVc />} />
                    <Route path="policies" element={<Policy />} />
                    <Route path="request" element={<Request />} />
                  </Route>
                </>
              )}
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default PrimaryLayout;
