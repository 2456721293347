import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Typography, message, Modal, Card } from "antd";
import { useNavigate } from "react-router-dom";
import { ToggleField } from "./UserBehavioralAnalytics";

const { Text } = Typography;

const getLocalStorageData = (key) => {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error(`Error parsing localStorage key "${key}":`, error);
    return null;
  }
};

const setLocalStorageData = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error setting localStorage key "${key}":`, error);
  }
};

const PolicyApprovalWorkflowPage = ({ onNavigateNext, onCreatePolicy, loadingPolicy }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // local states for toggles
  const [isActivationApprovalRequired, setActivationApprovalRequired] = useState(false);
  const [mustProvideJustification, setMustProvideJustification] = useState(false);
  const [mustProvideTicket, setMustProvideTicket] = useState(false);

  const [loading, setLoading] = useState(false);
  const [notification, notificationHolder] = message.useMessage();
  const [policySaved, setPolicySaved] = useState(false);
  const [payload, setPayload] = useState();
  const isEditMode = searchParams.get("edit") === "true";

  useEffect(() => {
    const basicPolicyDraft = getLocalStorageData("policyDraft.basicPolicyInformation");
    if (!basicPolicyDraft) {
      message.error("Primary Information is missing. Please complete it first!");
      return;
    }
    if (basicPolicyDraft.policyJson?.policyType === "GroupAD") {
      if (basicPolicyDraft.policyJson?.dit?.schedule && !basicPolicyDraft.policyJson?.dit?.jit) {
        message.error("For GroupAD policy, if schedule is present, JIT must also be present.");
        return;
      }
    }

    if (basicPolicyDraft) {
      const { policyJson } = basicPolicyDraft;
      const dit = policyJson?.dit || {};
      const workflow = dit.workflow || {};

      setActivationApprovalRequired(workflow.request_to_approve || false);
      setMustProvideJustification(workflow.need_justification || false);
      setMustProvideTicket(workflow.required_ticket || false);

      // Populate the AntD Form
      form.setFieldsValue({
        isActivationApprovalRequired: workflow.request_to_approve || false,
        mustProvideJustification: workflow.need_justification || false,
        mustProvideTicket: workflow.required_ticket || false,
      });
    } else {
      notification.error("Primary Information is missing. Please complete it first!");
    }
  }, [form, notification]);

  const handleSaveWorkflow = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      const basicPolicyData = getLocalStorageData("policyDraft.basicPolicyInformation");
      if (!basicPolicyData) {
        notification.error("Primary Information is missing. Please complete it first!");
        setLoading(false);
        return null;
      }

      const updatedBasicPolicyData = { ...basicPolicyData };
      if (!updatedBasicPolicyData.policyJson) {
        updatedBasicPolicyData.policyJson = {};
      }
      if (!updatedBasicPolicyData.policyJson.dit) {
        updatedBasicPolicyData.policyJson.dit = {};
      }

      updatedBasicPolicyData.policyJson.dit.workflow = {
        request_to_approve: values.isActivationApprovalRequired,
        need_justification: values.mustProvideJustification,
        required_ticket: values.mustProvideTicket,
      };

      setLocalStorageData("policyDraft.basicPolicyInformation", updatedBasicPolicyData);
      setPayload(updatedBasicPolicyData);
      setPolicySaved(true);

      return updatedBasicPolicyData;
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo);
      message.error("Please fix the validation errors before saving.");
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleSaveAndNext = async () => {
    const updatedData = await handleSaveWorkflow();
    if (updatedData) {
      onNavigateNext();
    }
  };

  const handleSaveAndCreate = async () => {
    const updatedData = await handleSaveWorkflow();
    if (updatedData) {
      onCreatePolicy({
        orgId: updatedData.orgId,
        tenantId: updatedData.tenantId,
        policyName: updatedData.policyName,
        policyType: updatedData.policyType,
        policyJson: updatedData.policyJson,
      });
    }
  };

  const handleSaveOnly = async () => {
    await handleSaveWorkflow();
  };

  const handleCancel = () => {
    Modal.confirm({
      title: "Are you sure you want to cancel?",
      content: "All unsaved changes will be lost.",
      onOk: () => {
        localStorage.removeItem("policyDraft.basicPolicyInformation");
        localStorage.removeItem("formSessionData");
        localStorage.removeItem("editPolicyData");
        navigate("/pam/policy/v2");
      },
    });
  };

  return (
    <div>
      {notificationHolder}

      <Card style={{ marginBottom: "24px" }}>
        <Form form={form} layout="vertical">
          <ToggleField
            label="Require Activation Approval"
            name="isActivationApprovalRequired"
            checked={isActivationApprovalRequired}
            onChange={setActivationApprovalRequired}
          />

          <ToggleField
            label="Require Justification"
            name="mustProvideJustification"
            checked={mustProvideJustification}
            onChange={setMustProvideJustification}
          />

          <ToggleField
            label="Require Ticket"
            name="mustProvideTicket"
            checked={mustProvideTicket}
            onChange={setMustProvideTicket}
          />

          <Row>
            <Col>
              <Text type="secondary">
                "All these fields are optional and managed by Authnull agent".
              </Text>
            </Col>
          </Row>
        </Form>
      </Card>

      <Row justify="end">
        <Button onClick={handleCancel} style={{ marginRight: "16px" }}>
          Cancel
        </Button>

        <Button
          type="primary"
          onClick={handleSaveAndNext}
          loading={loading}
          style={{ marginRight: "16px" }}
        >
          {isEditMode ? "Update and Next" : "Save and Next"}
        </Button>

        <Button type="primary" onClick={handleSaveAndCreate} loading={loadingPolicy}>
          {isEditMode ? "Update Policy" : "Create Policy"}
        </Button>
      </Row>
    </div>
  );
};

export default PolicyApprovalWorkflowPage;
