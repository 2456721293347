import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  message,
  Typography,
  Select,
  Menu,
  Dropdown,
  Modal,
  AutoComplete,
} from "antd";
import { MoreOutlined, InboxOutlined } from "@ant-design/icons";
import { DataGrid, GridOverlay, useGridApiRef } from "@mui/x-data-grid";
import axios from "axios";
import { AUTH_TOKEN, DOMAIN_ID, ORG_ID, REACT_APP_PAM_API } from "../../../constants";
import { logOutUser } from "../../../common";
import { useDebounce } from "../../../common/debounce";

// Constants & Helper Components
const STATUS_FILTER_OPTIONS = [
  { label: "Active", value: "active" },
  { label: "In Progress", value: "In progress" },
];

const PAGESIZE = 10;

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const Jumpserver = () => {
  const apiRef = useGridApiRef();
  const { Text } = Typography;
  const [messageApi, contextHolder] = message.useMessage();

  const [jumpServerData, setJumpServerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [search, setSearch] = useState("");
  const debouncedValue = useDebounce(search, 500);
  const [sortModel, setSortModel] = useState([]);
  const [filterBy, setFilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [options, setOptions] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedJumpServer, setSelectedJumpServer] = useState(null);
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });

  useEffect(() => {
    fetchJumpserver();
  }, [currentPage, debouncedValue, filteredValue, messageApi]);

  // For searching server names (for AutoComplete)
  const handleSearch = (value) => {
    if (!value) {
      setOptions([]);
      return;
    }
    const filteredOptions = jumpServerData
      .filter((server) => server.serverName.toLowerCase().includes(value.toLowerCase()))
      .map((server) => ({
        label: server.serverName,
        value: server.serverName,
      }));
    setOptions(filteredOptions);
  };

  const handleFilter = (value) => {
    if (value) {
      setFilterBy("status");
      setFilteredValue(value);
    } else {
      setFilterBy("");
      setFilteredValue("");
    }
    setCurrentPage(0);
  };

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const showDeleteModal = (row) => {
    setSelectedJumpServer(row);
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirmed = async () => {
    if (!selectedJumpServer) return;
    const payload = {
      id: selectedJumpServer.id,
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };

    try {
      await axios.post(`${REACT_APP_PAM_API}/jumpserver/deleteJumpServer`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      });
      messageApi.open({
        type: "success",
        content: "Jump server deleted successfully!",
      });
      fetchJumpserver();
    } catch (error) {
      if (
        error?.response?.data?.message === "Invalid credentials" ||
        error?.response?.status === 401
      ) {
        logOutUser();
      } else {
        messageApi.open({
          type: "error",
          content: "Unable to delete jump server",
        });
      }
    } finally {
      setDeleteModalVisible(false);
      setSelectedJumpServer(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setSelectedJumpServer(null);
  };

  const columns = [
    {
      headerName: "Server Name",
      field: "serverName",
      flex: 1,
    },
    {
      headerName: "Jump Host Address",
      field: "publicIpAddress",
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      renderCell: (params) => {
        const status = params.row.status.toLowerCase();
        const statusVal =
          status === "active" ? "Active" : status === "in progress" ? "In progress" : "Inactive";
        const type =
          status === "active" ? "success" : status === "in progress" ? "warning" : "danger";
        return <Text type={type}>{statusVal}</Text>;
      },
    },
    {
      headerName: "View Connections",
      field: "viewConnections",
      flex: 1,
      renderCell: (params) => (
        <Link
          to={`/pam/jumpServer-connections/${params.row.publicIpAddress}?hostname=${params.row.serverName}`}
        >
          Show
        </Link>
      ),
    },
    {
      headerName: "Action",
      field: "action",
      flex: 1,
      renderCell: (params) => {
        const menu = (
          <Menu>
            <Menu.Item key="assign">
              <Link
                to={`/pam/jumpServer-connections/createConnection?serverName=${params.row.serverName}&id=${params.row.id}&ip=${params.row.publicIpAddress}`}
              >
                Assign Connection
              </Link>
            </Menu.Item>
            <Menu.Item key="delete" onClick={() => showDeleteModal(params.row)}>
              Delete
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button type="text" shape="circle" icon={<MoreOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  const fetchJumpserver = () => {
    const pageDetails = {
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      search: debouncedValue,
      filter: {
        filterBy,
        value: filteredValue,
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
      domainId: DOMAIN_ID(),
    };

    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/jumpserver/listJumpServer`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIsLoading(false);
        const servers = res?.data?.jumpServers
          ? res.data.jumpServers.map((data, i) => ({
              id: i + 1,
              ...data,
            }))
          : [];
        setJumpServerData(servers);
        setTotalCount(res?.data?.total);
        setCurrentPageModel({
          pageSize: PAGESIZE,
          page: currentPage,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          messageApi.open({
            type: "error",
            content: "Unable to fetch jump server",
          });
        }
      });
  };

  return (
    <>
      {contextHolder}
      <Row className="content-container">
        <Col span={24}>
          <Row justify="space-between" style={{ marginBottom: "1rem" }}>
            <Col>
              <h2 className="title">Jump Server</h2>
            </Col>
            <Col>
              <Link to="/pam/jumpServer/add">
                <Button type="primary">Add Jump Server</Button>
              </Link>
            </Col>
          </Row>

          <Row gutter={[20]} style={{ marginBottom: "2rem" }}>
            <Col span={6}>
              <p className="search-label">Status</p>
              <Select
                placeholder="Select Status"
                allowClear
                onChange={handleFilter}
                options={STATUS_FILTER_OPTIONS}
              />
            </Col>
            <Col span={6}>
              <p className="search-label">Search</p>
              <AutoComplete
                placeholder="Search Server Name"
                allowClear
                onSearch={handleSearch}
                options={options}
                onChange={setSearch}
              />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <DataGrid
                apiRef={apiRef}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                rows={jumpServerData}
                columns={columns}
                paginationMode="server"
                rowCount={totalCount}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                loading={isLoading}
                paginationModel={currentPageModel}
                page={currentPage}
                onPaginationModelChange={(params) => {
                  setCurrentPage(params.page);
                }}
                style={{ border: "none" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        title="Delete Jump Server"
        visible={deleteModalVisible}
        onOk={handleDeleteConfirmed}
        onCancel={handleDeleteCancel}
        okText="Delete"
        cancelText="Cancel"
      >
        <p>
          By deleting{" "}
          <strong style={{ color: "#1890ff" }}>
            {selectedJumpServer?.serverName || "this jump server"}
          </strong>
          , all related connections will be removed.
        </p>
      </Modal>
    </>
  );
};

export default Jumpserver;
