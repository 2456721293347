import {
  Col,
  Row,
  Dropdown,
  Button,
  Input,
  message,
  Badge,
  Tooltip,
  Cascader,
  Typography,
} from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  AUTH_TOKEN,
  REACT_APP_PAM_API,
  ORG_ID,
  DOMAIN_ID,
  REACT_APP_API_TENANTS_URL,
} from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";
import { MoreOutlined } from "@ant-design/icons";
import AssignUser from "./AssignUser";
import AuthFlow from "./AuthFlow";
import { useDebounce } from "../../../common/debounce";
import moment from "moment-timezone";
import { DataGrid, GridOverlay, useGridApiRef } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import AssignEndpointToWallet from "./AssignEndpointToWallet";
import { AutoComplete } from "antd";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const Endpoints = () => {
  const apiRef = useGridApiRef();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [endpointData, setEndpointData] = useState([]);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [isAssignUser, setIsAssignUser] = useState(false);
  const [notification, setNotification] = message.useMessage();
  const [selectedEndpoints, setSelectedEndpoints] = useState(null);
  const [isAuthFlow, setIsAuthFlow] = useState(false);
  const [isAssignWallet, setIsAssignWallet] = useState(false);
  const [osList, setOsList] = useState([]);
  const [isTabChange, setIsTabChange] = useState(false);
  const [sortModel, setSortModel] = useState();
  const debouncedValue = useDebounce(search, 500);

  //pagination
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });
  const { Text } = Typography;
  const [options, setOptions] = useState([]);

  const handleSearch = (value) => {
    if (!value) {
      setOptions([]);
      return;
    }
    const filteredOptions = endpointData
      .filter((endpointdata) => endpointdata.hostName.toLowerCase().includes(value.toLowerCase()))
      .map((endpointdata) => ({
        label: endpointdata.hostName,
        value: endpointdata.hostName,
      }));

    setOptions(filteredOptions);
  };

  useEffect(() => {
    fetchInstance();
  }, [debouncedValue, filteredValue, paginationParams, currentPage]);

  const EndpointStatus = (lastUpdated) => {
    const timezone = localStorage.getItem("timezone");
    const lastActive = moment.unix(lastUpdated).tz(timezone).format("YYYY-MM-DD HH:mm:ss");
    const diff = moment().diff(lastActive, "minutes");
    let hours = Math.floor(diff / 60);
    let days = Math.floor(hours / 24);
    let minutes = diff % 60;

    let message;

    if (days > 0) {
      message = `Last Active: ${days} days ago`;
    } else if (hours > 0) {
      message = `Last Active: ${hours} hours${
        minutes > 0 ? ` and ${minutes} minutes ago` : " hours ago"
      }`;
    } else {
      message = `Last Active: ${diff} minutes ago`;
    }

    if (diff > 20) {
      return (
        <Tooltip title={message}>
          <Badge status="error" text="Offline" />
        </Tooltip>
      );
    } else if (diff > 10) {
      return (
        <Tooltip title={message}>
          <Badge status="warning" text="Offline" />
        </Tooltip>
      );
    }
    return <Badge status="success" text="Online" />;
  };

  const handleStatus = async (record) => {
    console.log(record);
    let status = record.status.toLowerCase();
    let data = {
      domainId: DOMAIN_ID(),
      status: status === "active" ? "Inactive" : status === "inactive" && "Active",
      instanceIds: [record.instanceId],
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    let url = `${REACT_APP_PAM_API}/instances/updateInstanceStatus`;
    try {
      await axios.put(url, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      await fetchInstance();
      notification.open({
        type: "success",
        content: `${record.hostName} is ${
          record.status === "Active" ? "deactivated" : "activated"
        } successfull`,
      });
    } catch (err) {
      if (err.response.data.message === "Invalid credentials" || err?.response?.status === 401) {
        notification.open({
          type: "error",
          content: "Credentials are invalid",
        });
        logOutUser();
      } else if (err.response.status === 401) {
        logOutUser();
      } else {
        notification.open({
          type: "error",
          content: "Unable to update status",
        });
      }
    }
  };

  const columns = [
    {
      headerName: "Host Name",
      field: "hostName",
      key: "hostName",
      width: 100,
      renderCell: (val) => (
        <Link
          disabled={val.row.hostName === 0 ? true : false}
          state={{
            hostAddress: val.row.hostName,
            instanceId: val.row.instanceId,
          }}
          to={`/pam/endpoint/endpointDetails?defaultTab=hostname`}
          onClick={() => {
            localStorage.removeItem("details");
            let values = {
              hostAddress: val.row.hostName,
              instanceId: val.row.instanceId,
            };
            localStorage.setItem("details", JSON.stringify(values));
          }}
        >
          {val.row.hostName}
        </Link>
      ),
    },
    { headerName: "OS", field: "osname", key: "osname" },
    {
      headerName: "User Count",
      field: "countOfUsers",
      key: "countOfUsers",
      width: 150,
      renderCell: (val) => {
        const isDisabled = val?.row?.countOfUsers === 0;
        return (
          <>
            {isDisabled ? (
              <span style={{ cursor: "not-allowed" }}>{val?.row?.countOfUsers}</span>
            ) : (
              <Link
                to={`/pam/endpoint/endpointDetails?defaultTab=usercount`}
                state={{
                  hostAddress: val.row.hostName,
                  instanceId: val.row.instanceId,
                }}
                onClick={() => {
                  localStorage.removeItem("details");
                  let values = {
                    hostAddress: val.row.hostName,
                    instanceId: val.row.instanceId,
                  };
                  localStorage.setItem("details", JSON.stringify(values));
                }}
              >
                {val?.row?.countOfUsers}
              </Link>
            )}
          </>
        );
      },
    },

    {
      headerName: "Public IP",
      field: "publicIp",
      key: "publicIp",
    },
    {
      headerName: "Group Count",
      field: "countOfGroups",
      key: "countOfGroups",
      width: 150,
      renderCell: (val) => {
        const isDisabled = val?.row?.countOfGroups === 0;
        return (
          <>
            {isDisabled ? (
              <span style={{ cursor: "not-allowed" }}>{val?.row?.countOfGroups}</span>
            ) : (
              <Link
                state={{
                  hostAddress: val.row.hostName,
                  instanceId: val.row.instanceId,
                }}
                onClick={() => {
                  localStorage.removeItem("details");
                  let values = {
                    hostAddress: val.row.hostName,
                    instanceId: val.row.instanceId,
                  };
                  localStorage.setItem("details", JSON.stringify(values));
                }}
                to={`/pam/endpoint/endpointDetails?tab=${isTabChange}&defaultTab=groupcount`}
              >
                {val?.row?.countOfGroups}
              </Link>
            )}
          </>
        );
      },
    },

    {
      headerName: "State",
      field: "status",
      key: "status",
      width: 120,
      renderCell: (val) => {
        let stateVal = val.row.status.toLowerCase() === "active" ? "Active" : "Inactive";
        return (
          <>
            <Text type={stateVal === "Active" ? "success" : "danger"}> {stateVal}</Text>
          </>
        );
      },
    },
    {
      headerName: "Agent Status",
      field: "AgentStatus",
      key: "status",
      renderCell: (val) => EndpointStatus(val.row.lastActive),
    },
    {
      headerName: "Action",
      field: "action",
      key: "action",
      renderCell: (val) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              // {
              //   key: "assign",
              //   label: (
              //     <Link
              //       onClick={() => {
              //         setSelectedEndpoints(val.row);
              //         setIsAssignUser(true);
              //       }}
              //     >
              //       Assign Users
              //     </Link>
              //   ),
              // },
              {
                key: "Agent Logs",
                label: (
                  <Link to={`/pam/endpoint/agentLogs?hostName=${val.row.hostName}`}>
                    Agent Logs
                  </Link>
                ),
              },
              {
                key: "assign",
                key: " assignWallet",
                label: (
                  <Link
                    onClick={() => {
                      setIsAssignWallet(true);
                      setSelectedEndpoints(val.row);
                    }}
                  >
                    Assign to Wallet
                  </Link>
                ),
              },

              {
                key: "authFlow",
                label: (
                  <Link
                    onClick={() => {
                      setIsAuthFlow(true);
                      setSelectedEndpoints(val.row);
                    }}
                  >
                    Auth Flow
                  </Link>
                ),
              },

              {
                key: "Password Policies",
                label: (
                  <Link to={`/pam/endpoint/passwordPolicies?hostaddress=${val.row.hostName}`}>
                    Password Policies
                  </Link>
                ),
              },
              {
                key: "state",
                label: (
                  <Link onClick={() => handleStatus(val.row)}>
                    {val.row.status.toLowerCase() === "active" ? "Deactivate" : "Activate"}
                  </Link>
                ),
                danger: val.row.status.toLowerCase() === "active" ? true : false,
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const calculateColumnWidths = (data, columnsToAutoSize, includeHeaders = false) => {
    const columnWidths = {};

    columnsToAutoSize.forEach((col) => {
      let maxWidth = includeHeaders ? col.length * 13 : 0;

      data.forEach((row) => {
        const value = row[col];
        const width = value ? value.toString().length * 13 : 0;
        if (width > maxWidth) {
          maxWidth = width;
        }
      });

      columnWidths[col] = maxWidth;
    });

    return columnWidths;
  };

  // Calculate column widths
  const columnWidths = calculateColumnWidths(
    endpointData,
    [
      "hostName",
      "status",
      "totalUsers",
      "privateIp",
      "publicIp",
      "totalGroups",
      "action",
      "AgentStatus",
    ],
    true
  );

  // Apply the calculated widths to the columns
  const adjustedColumns = columns.map((col) => ({
    ...col,
    width: columnWidths[col.field] || col.width,
  }));

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const fetchInstance = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      search: debouncedValue,
      filter: {
        filterBy: filterBy,
        value: filteredValue,
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
      MachineType: "endpoint",
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        const originalEpm = res?.data?.instances;

        const epmWithId = originalEpm.map((epm, index) => ({
          ...epm,
          id: index + 1,
          countOfUsers: 0,
          countOfGroups: 0,
          osname: epm.osName,
        }));
        setEndpointData(epmWithId ?? []);
        setTotalCount(res?.data?.totalCount);
        setCurrentPageModel({
          pageSize: 10,
          page: currentPage,
        });

        const fetchInstanceOsType = () => {
          let pageDetails = {
            tenantId: DOMAIN_ID(),
            orgId: ORG_ID(),
          };
          axios
            .post(`${REACT_APP_API_TENANTS_URL}/getOsList`, pageDetails, {
              headers: {
                withCredentials: true,
                "X-Authorization": AUTH_TOKEN(),
              },
            })
            .then(({ data }) => {
              const osData = data?.osList.map((os) => ({
                value: os.id.toString(),
                label:
                  os.osName.toLowerCase() === "windows"
                    ? "Windows"
                    : os.osName.toLowerCase() === "linux"
                    ? "Linux"
                    : os.osName,
              }));
              setOsList(osData ?? []);

              const dataWithOs = epmWithId.map((epm) => {
                const osItem = osData.find((os) => os.value === epm.osName);
                return {
                  ...epm,
                  osname: osItem ? osItem.label : epm.osName,
                };
              });
              setEndpointData(dataWithOs);
              const ids = originalEpm.map((item) => item.instanceId);

              const childApiCalls = ids.map((id) => {
                return Promise.all([fetchUserCount(id), fetchGroupCount(id)]);
              });

              // Wait for all child API calls to resolve
              Promise.all(childApiCalls)
                .then((results) => {
                  console.log("All child API calls completed", results);

                  // Update the epmWithId with the results from both user and group count
                  let updatedEpm = dataWithOs.map((epm, index) => {
                    const userCountData = results[index][0]?.data; // First item of Promise.all() result
                    const groupCountData = results[index][1]?.data; // Second item of Promise.all() result

                    return {
                      ...epm,
                      countOfUsers: userCountData?.count || 0,
                      countOfGroups: groupCountData?.count || 0,
                    };
                  });

                  setEndpointData(updatedEpm);
                })
                .catch((error) => {
                  console.error("Error in child API calls", error);
                });
            })
            .catch((err) => {
              if (
                err.response.data.message === "Invalid credentials" ||
                err?.response?.status === 401
              ) {
                notification.open({
                  type: "error",
                  content: "Credentials are invalid",
                });
                logOutUser();
              } else if (err.response.status === 401) {
                logOutUser();
              } else {
                notification.open({
                  type: "error",
                  content: "Unable to fetch OS",
                });
              }
            });
        };
        fetchInstanceOsType();
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  const fetchUserCount = (id) => {
    let userCountRequest = {
      tenantId: DOMAIN_ID(),
      machineId: id,
      orgId: ORG_ID(),
      uuid: "",
    };

    return axios
      .post(`${REACT_APP_API_TENANTS_URL}/getCountofEpmUserByEndpoint`, userCountRequest, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((childData) => {
        console.log(childData);
        return childData; // Return the child data to be used in Promise.all
      })
      .catch((error) => {
        console.error("Error in child API call for ID", id, ":", error);
      });
  };
  const fetchGroupCount = (id) => {
    let userCountRequest = {
      tenantId: DOMAIN_ID(),
      machineId: id,
      orgId: ORG_ID(),
      uuid: "",
    };

    return axios
      .post(`${REACT_APP_API_TENANTS_URL}/getCountofEpmUserGroupByEndpoint`, userCountRequest, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((childData) => {
        console.log(childData);
        return childData;
      })
      .catch((error) => {
        console.error("Error in child API call for ID", id, ":", error);
      });
  };

  const displayRender = (labels) => labels[labels.length - 1];

  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const handleFilter = (_, data) => {
    console.log(data);
    setfilterBy(data ? data[0]?.value : "");
    setFilteredValue(data ? data[1]?.value : "");
    // setPaginationParams({ current: 1, pageSize: 10 });
    setCurrentPage(0);
  };

  const option = [
    {
      value: "State",
      label: "State",
      children: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
      ],
    },
    {
      value: "osname",
      label: "OS",
      children: osList,
    },
  ];

  const items = [
    {
      key: "1",

      label: (
        <Link
          to={"/pam/endpoint/addEndpoint/windows"}
          onClick={() => {
            // setIsAddWindows(true);
            // setIsOnboarding(false);
          }}
        >
          Windows
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          to={"/pam/endpoint/addEndpoint/linux"}
          onClick={() => {
            // setIsAddLinux(true);
            // setIsOnboarding(false);
          }}
        >
          Linux
        </Link>
      ),
    },
  ];

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Endpoints</h2>
            </Col>
            <Col>
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottom"
                arrow={{
                  pointAtCenter: true,
                }}
              >
                <Button type="primary">Add Endpoints</Button>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={6}>
                      <p className="search-label">Filter by</p>

                      <Cascader
                        options={option}
                        expandTrigger="hover"
                        displayRender={displayRender}
                        onChange={handleFilter}
                        placeholder="Search to select"
                        showSearch={{
                          filter,
                        }}
                        onClear={() => {
                          setfilterBy("");
                          setFilteredValue("");
                          setCurrentPage(0);
                        }}
                      />
                    </Col>
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <AutoComplete
                          placeholder="Search HostName"
                          allowClear
                          onSearch={handleSearch}
                          options={options}
                          onChange={(value) => {
                            setSearch(value);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DataGrid
                apiRef={apiRef}
                loading={isLoading}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                getRowId={(row) => row.instanceId}
                rows={endpointData}
                paginationMode="server"
                columns={adjustedColumns}
                rowCount={totalCount}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
                page={currentPage}
                paginationModel={currentPageModel}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
                onPaginationModelChange={(params) => {
                  setCurrentPage(params.page);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {isAssignUser && (
        <AssignUser
          isAssignUser={isAssignUser}
          setIsAssignUser={setIsAssignUser}
          selectedEndpoints={selectedEndpoints}
        />
      )}
      {isAssignWallet && (
        <AssignEndpointToWallet
          isAssignWallet={isAssignWallet}
          setIsAssignWallet={setIsAssignWallet}
          selectedEndpoints={selectedEndpoints}
          fetchInstances={fetchInstance}
        />
      )}
      {isAuthFlow && (
        <AuthFlow
          isAuthFlow={isAuthFlow}
          setIsAuthFlow={setIsAuthFlow}
          selectedEndpoints={selectedEndpoints}
        />
      )}
    </>
  );
};

export default Endpoints;
