import React, { useEffect } from "react";
import { Form, Row, Col, Select, InputNumber, Tooltip, notification } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

// Comparator options for numeric fields
const comparatorOptions = [
  { label: "Greater Than", value: ">" },
  { label: "Less Than", value: "<" },
  { label: "Equals", value: "==" },
  { label: "Greater or Equals", value: ">=" },
  { label: "Less or Equals", value: "<=" },
];

// Options for boolean fields
const booleanFilterOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const UserBehaviourAnalyticsFilterFields = ({ form, filters, setFilters, logout }) => {
  // Synchronize form values with filters state on mount and when filters change
  useEffect(() => {
    form.setFieldsValue(filters);
  }, [filters, form]);

  // Handle form value changes and synchronize with filters state
  const handleValuesChange = (changedValues, allValues) => {
    setFilters(allValues);
  };

  return (
    <Form form={form} onValuesChange={handleValuesChange} initialValues={filters} layout="vertical">
      {/* Trusted Network Filter */}
      <Form.Item label="Trusted Network" name={["behaviour", "is_trusted_network"]}>
        <Select placeholder="Select an option" allowClear>
          {booleanFilterOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {/* Trusted Device Filter */}
      <Form.Item label="Trusted Device" name={["behaviour", "is_trusted_device"]}>
        <Select placeholder="Select an option" allowClear>
          {booleanFilterOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {/* Login Fail Count Filter */}
      <Form.Item
        label="Login Fail Count"
        name={["scores", "login_fail_count"]}
        rules={[
          {
            validator: (_, value) => {
              if (value) {
                const { operator, value: val } = value;
                if (!operator || val === undefined) {
                  return Promise.reject(
                    new Error("Both operator and value are required for Login Fail Count.")
                  );
                }
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name={["scores", "login_fail_count", "operator"]}
              noStyle
              rules={[{ required: true, message: "Comparator is required." }]}
            >
              <Select placeholder="Comparator" allowClear style={{ width: "100%" }}>
                {comparatorOptions.map((comp) => (
                  <Option key={comp.value} value={comp.value}>
                    {comp.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={["scores", "login_fail_count", "value"]}
              noStyle
              rules={[
                { required: true, message: "Value is required." },
                {
                  type: "number",
                  min: 0,
                  message: "Value must be a positive number.",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Value" min={0} step={1} />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      {/* MFA Fail Count Filter */}
      <Form.Item
        label="MFA Fail Count"
        name={["scores", "mfa_fail_count"]}
        rules={[
          {
            validator: (_, value) => {
              if (value) {
                const { operator, value: val } = value;
                if (!operator || val === undefined) {
                  return Promise.reject(
                    new Error("Both operator and value are required for MFA Fail Count.")
                  );
                }
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name={["scores", "mfa_fail_count", "operator"]}
              noStyle
              rules={[{ required: true, message: "Comparator is required." }]}
            >
              <Select placeholder="Comparator" allowClear style={{ width: "100%" }}>
                {comparatorOptions.map((comp) => (
                  <Option key={comp.value} value={comp.value}>
                    {comp.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={["scores", "mfa_fail_count", "value"]}
              noStyle
              rules={[
                { required: true, message: "Value is required." },
                {
                  type: "number",
                  min: 0,
                  message: "Value must be a positive number.",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Value" min={0} step={1} />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default UserBehaviourAnalyticsFilterFields;
