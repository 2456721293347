import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export default function VerifyEmailSuccess() {
  return (
    <>
      <div style={styles.contentContainer}>
        <CheckCircleOutlined style={styles.icon} />
        <h1 style={styles.title}>Email Verified Successfully</h1>
        <p style={styles.description}>
          Your tenant account is being prepared and will be shared via email shortly. We're setting
          up your Authnull authentication environment.
        </p>

        <Link to="/tenant/login" style={{ textDecoration: "none", marginTop: "15px" }}>
          <Button type="primary">Proceed to Login</Button>
        </Link>
      </div>

      <Row
        style={{ padding: "20px", width: "70%", margin: "auto", paddingBottom: "50px" }}
        gutter={20}
      >
        <Col span={24}>
          <h1 style={styles.subTitle}>Resources</h1>
        </Col>
        <Col span={12}>
          <Card>
            <Card.Meta
              title="Documentation"
              description="Explore our documentation to learn more about Authnull and how to integrate it into your application."
            />
            <Link
              to="https://help.authnull.com/docs/introduction/"
              style={{ textDecoration: "none", marginTop: "15px" }}
            >
              Learn more
            </Link>
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Card.Meta
              title="Blogs"
              description="Stay updated with the latest news and updates from Authnull. Read our blogs to learn more about authentication and security."
            />
            <Link
              to="https://authnull.com/blog"
              style={{ textDecoration: "none", marginTop: "15px" }}
            >
              Learn more
            </Link>
          </Card>
        </Col>
      </Row>
    </>
  );
}

const styles = {
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "60vh",
    textAlign: "center",
    background:
      "linear-gradient(to bottom right, rgb(251, 253, 255), rgb(243, 251, 255), rgb(221, 242, 253))",
  },
  icon: {
    fontSize: "4.5rem",
    color: "#4CAF50",
  },
  title: {
    fontSize: "2rem",
    color: "#232424",
    marginBottom: "1rem",
  },
  description: {
    maxWidth: "500px",
    fontSize: "16px",
    color: "#666",
  },
  subTitle: {
    fontSize: "1.2rem",
    marginBottom: "1rem",
  },
};
