import { Col, Input, Row, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { REACT_APP_API_TENANTS_URL, AUTH_TOKEN, AUTH_TOKEN_ORG } from "../../constants";
import axios from "axios";
import moment from "moment-timezone";
import Tenant from "./Tenant";

const Details = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [endpointList, setEndpointList] = useState([]);
  const location = useLocation();
  const { dataType } = location.state;

  useEffect(() => {
    fetchEndpoint();
    fetchUser();
  }, []);

  const fetchUser = () => {
    let data = {
      email: localStorage.getItem("UserName"),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/userlist`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN_ORG(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setUserList(res?.data?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const usersColumns = [
    {
      title: "Email",
      dataIndex: "EmailAddress",
      key: "EmailAddress",
      render: (tags, record) => (
        <span>
          <Tag color={record.Status.toLowerCase() === "active" ? "green" : "red"} key={tags}>
            {tags}
          </Tag>
        </span>
      ),
    },
    {
      title: "First Name",
      dataIndex: "FirstName",
      key: "FirstName",
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
      key: "LastName",
    },
    { title: "Organization Name", dataIndex: "Organization", key: "Organization" },
    {
      title: "Dn",
      dataIndex: "Dn",
      key: "Dn",
    },

    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (val) => (val?.toLowerCase() === "active" ? "Active" : "Inactive"),
    },
  ];
  const fetchEndpoint = () => {
    let data = {
      email: localStorage.getItem("UserName"),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/endpointlist`, data, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN_ORG(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setEndpointList(res?.data?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const endpointsColumns = [
    {
      title: "Host Name",
      dataIndex: "Hostname",
      key: "Hostname",
    },
    { title: "Machine Key", dataIndex: "MachineKey", key: "MachineKey" },

    {
      title: "Private IP",
      dataIndex: "PrivateIpAddress",
      key: "PrivateIpAddress",
    },
    {
      title: "Public IP",
      dataIndex: "PublicIpAddress",
      key: "PublicIpAddress",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (val) => (val?.toLowerCase() === "active" ? "Active" : "Inactive"),
    },
  ];

  return (
    <>
      {dataType === "TENANT" ? (
        <Tenant />
      ) : dataType === "USER" ? (
        <Row className="content-conatiner">
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <h2 className="title">Users</h2>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Row
                      style={{ marginBottom: "2rem", gap: "2rem" }}
                      className="search-box-container"
                    >
                      <Col span={6}>
                        <p className="search-label">Search</p>
                        <div>
                          <Input placeholder="Search" allowClear />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table loading={isLoading} columns={usersColumns} dataSource={userList} />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row className="content-conatiner">
          <Col span={24}>
            <Row justify="space-between">
              <Col>
                <h2 className="title">Endpoints</h2>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Row
                      style={{ marginBottom: "2rem", gap: "2rem" }}
                      className="search-box-container"
                    >
                      <Col span={6}>
                        <p className="search-label">Search</p>
                        <div>
                          <Input placeholder="Search" allowClear />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table loading={isLoading} columns={endpointsColumns} dataSource={endpointList} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Details;
