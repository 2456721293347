import React from "react";
import { Form, Select } from "antd";

const { Option } = Select;

const OfflineAccessFilterFields = ({ form, filters, setFilters }) => {
  return (
    <Form.Item label="Enable Offline Access" name="OfflineAccess">
      <Select
        placeholder="Select an option"
        allowClear
        onChange={(value) => {

          setFilters({
            ...filters,
            OfflineAccess: value,
          });
        }}
      >
        <Option value={true}>Yes</Option>
        <Option value={false}>No</Option>
      </Select>
    </Form.Item>
  );
};

export default OfflineAccessFilterFields;
