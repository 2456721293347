import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Tag,
  message,
  Drawer,
  Tooltip,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_AI_API_URL,
  REACT_APP_API,
} from "../../constants";
import { useDebounce } from "../../common/debounce";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { EditOutlined, InboxOutlined, InfoCircleOutlined, MoreOutlined } from "@ant-design/icons";
import ReactJson from "@microlink/react-json-view";
import AIAgent from "./AIAgent/AIAgent";
import AdvancedFilters from "./advancedFilters/advancedFilters";
import PolicyImpact from "./PolicyImpact";
import DitPolicyViewer from "./ditPolicyViewer";
import { formatTimeByTimezone } from "../../common/formatTimebyTimezone";

const capitalize = (text) => {
  if (typeof text === "string" || text instanceof String) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  } else if (typeof text === "object") {
    return JSON.stringify(text);
  } else {
    return String(text);
  }
};

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const statusOptions = [
  { label: "Approved", value: "Approved" },
  { label: "Pending", value: "Pending" },
];

const policyTypesOptions = [
  { label: "Active Directory", value: "AD" },
  { label: "AD Groups", value: "GroupAD" },
  { label: "Service Account", value: "ServiceAccount" },
  { label: "Endpoints", value: "endpoints" },
  { label: "RADIUS", value: "radius" },
  { label: "Database", value: "database" },
];

const JsonCellRenderer = ({ value }) => (
  <ReactJson
    src={value}
    name={false}
    collapsed={true}
    theme={"rjv-default"}
    enableClipboard={false}
    enableEdit={false}
    enableAdd={false}
    enableDelete={false}
    displayDataTypes={false}
    displayObjectSize={false}
    displayArrayKey={false}
  />
);

const cleanDITFilters = (obj) => {
  if (Array.isArray(obj)) {
    // Recursively clean each element in the array and filter out empty ones
    const cleanedArray = obj
      .map(cleanDITFilters)
      .filter((item) => item !== null && item !== undefined);
    return cleanedArray.length > 0 ? cleanedArray : null;
  } else if (typeof obj === "object" && obj !== null) {
    // Recursively clean each key-value pair in the object
    const cleanedObject = Object.keys(obj).reduce((acc, key) => {
      const cleanedValue = cleanDITFilters(obj[key]);
      if (cleanedValue !== null && cleanedValue !== undefined) {
        acc[key] = cleanedValue;
      }
      return acc;
    }, {});
    return Object.keys(cleanedObject).length > 0 ? cleanedObject : null;
  } else {
    // Return primitive values as-is
    return obj !== undefined && obj !== null ? obj : null;
  }
};

/**
 * Safely produce an array of { key, display } lines from a DITFilters object.
 * Returns [] if DIT filters are empty or null.
 */
export function flattenMasterDITFilters(obj) {
  if (!obj || typeof obj !== "object") {
    return [];
  }

  const items = [];

  // 1) WORKFLOW
  if (obj.workflow) {
    if (obj.workflow.request_to_approve !== undefined) {
      items.push({
        key: "workflow.request_to_approve",
        display: `Request to Approve: ${obj.workflow.request_to_approve ? "Yes" : "No"}`,
      });
    }
    if (obj.workflow.need_justification !== undefined) {
      items.push({
        key: "workflow.need_justification",
        display: `Need Justification: ${obj.workflow.need_justification ? "Yes" : "No"}`,
      });
    }
    if (obj.workflow.required_ticket !== undefined) {
      items.push({
        key: "workflow.required_ticket",
        display: `Required Ticket: ${obj.workflow.required_ticket ? "Yes" : "No"}`,
      });
    }
  }

  // 2) OFFLINE_ACCESS
  if (obj.offline_Access) {
    items.push({
      key: "offline_Access",
      display: `Offline Access: ${obj.offline_Access}`,
    });
  }

  // 3) GEOLOCATION (countries, ip_ranges)
  if (obj.geolocation) {
    // Countries
    if (Array.isArray(obj.geolocation.countries) && obj.geolocation.countries.length) {
      items.push({
        key: "geolocation.countries",
        display: `Countries: ${obj.geolocation.countries
          .map((item) => item.split(".")[0])
          .join(", ")}`,
      });
    }
    // IP Ranges
    if (Array.isArray(obj.geolocation.ip_ranges) && obj.geolocation.ip_ranges.length) {
      obj.geolocation.ip_ranges.forEach((range, idx) => {
        if (range.start && range.end) {
          items.push({
            key: `geolocation.ip_ranges[${idx}]`,
            display: `IP Range: ${range.start} - ${range.end}`,
          });
        }
      });
    }
  }

  // 4) SCHEDULE
  if (obj.schedule?.type) {
    items.push({
      key: "schedule.type",
      display: `Schedule Type: ${obj.schedule.type === "jit" ? "JIT" : "Recurring"}`,
    });
  }

  // 5) SCORES => persona, confidence, risk, etc.
  if (obj.scores) {
    // persona
    if (obj.scores.persona?.comparator && obj.scores.persona?.value !== undefined) {
      items.push({
        key: "scores.persona",
        display: `Persona Score ${obj.scores.persona.comparator} ${obj.scores.persona.value}`,
      });
    }
    // confidence
    if (obj.scores.confidence?.comparator && obj.scores.confidence?.value !== undefined) {
      items.push({
        key: "scores.confidence",
        display: `Confidence Score ${obj.scores.confidence.comparator} ${obj.scores.confidence.value}`,
      });
    }
    // risk
    if (obj.scores.risk?.comparator && obj.scores.risk?.value !== undefined) {
      items.push({
        key: "scores.risk",
        display: `Risk Score ${obj.scores.risk.comparator} ${obj.scores.risk.value}`,
      });
    }
    // login_fail_count
    if (obj.scores.login_fail_count?.operator && obj.scores.login_fail_count?.value !== undefined) {
      items.push({
        key: "scores.login_fail_count",
        display: `Login Fail Count ${obj.scores.login_fail_count.operator} ${obj.scores.login_fail_count.value}`,
      });
    }
    // mfa_fail_count
    if (obj.scores.mfa_fail_count?.operator && obj.scores.mfa_fail_count?.value !== undefined) {
      items.push({
        key: "scores.mfa_fail_count",
        display: `MFA Fail Count ${obj.scores.mfa_fail_count.operator} ${obj.scores.mfa_fail_count.value}`,
      });
    }
  }

  // 6) offlineAccess (lowercase "o")
  if (obj.offlineAccess) {
    items.push({
      key: "offlineAccess",
      display: `Offline Access: ${obj.offlineAccess}`,
    });
  }

  // 7) BEHAVIOUR
  if (obj.behaviour) {
    if (obj.behaviour.is_trusted_network !== undefined) {
      items.push({
        key: "behaviour.is_trusted_network",
        display: `Trusted Network: ${obj.behaviour.is_trusted_network ? "Yes" : "No"}`,
      });
    }
    if (obj.behaviour.is_trusted_device !== undefined) {
      items.push({
        key: "behaviour.is_trusted_device",
        display: `Trusted Device: ${obj.behaviour.is_trusted_device ? "Yes" : "No"}`,
      });
    }
    if (obj.behaviour.disable_logon !== undefined) {
      items.push({
        key: "behaviour.disable_logon",
        display: `Disable Logon: ${obj.behaviour.disable_logon ? "Yes" : "No"}`,
      });
    }
  }

  // 8) DEVICE POLICIES
  if (Array.isArray(obj.devicePolicies)) {
    obj.devicePolicies.forEach((policy, index) => {
      const { type, condition, options } = policy;
      const condLabel = condition ? ` (${condition})` : "";
      if (type === "deviceOwnership") {
        items.push({
          key: `devicePolicies[${index}]`,
          display: `Device Ownership${condLabel}: ${options?.join(", ") || "N/A"}`,
        });
      } else if (type === "manufacturer") {
        items.push({
          key: `devicePolicies[${index}]`,
          display: `Manufacturer${condLabel}: ${options?.join(", ") || "N/A"}`,
        });
      } else if (type === "model") {
        items.push({
          key: `devicePolicies[${index}]`,
          display: `Model${condLabel}: ${options?.join(", ") || "N/A"}`,
        });
      } else if (type === "operatingSystem") {
        items.push({
          key: `devicePolicies[${index}]`,
          display: `Operating System${condLabel}: ${options?.join(", ") || "N/A"}`,
        });
      } else {
        items.push({
          key: `devicePolicies[${index}]`,
          display: `${type || "Unknown"}${condLabel}: ${options?.join(", ") || "N/A"}`,
        });
      }
    });
  }

  return items;
}

export default function Policies() {
  const [notification, notificationHolder] = message.useMessage();
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({ policyType: "AD" });
  const [search, setSearch] = useState("");
  const debouncedValue = useDebounce(search, 500);
  const [viewPolicyDrawer, setViewPolicyDrawer] = useState(false);
  const [policyJsonData, setPolicyJsonData] = useState();
  const [selectedPolicyType, setSelectedPolicyType] = useState("AD");
  const [openApprovePolicyModal, setOpenApprovePolicyModal] = useState(false);
  const [selectedPolicyRow, setSelectedPolicyRow] = useState(null);
  const [openDeletePolicyModal, setOpenDeletePolicyModal] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  // Policy Impact example
  const [showPolicyImpact, setShowPolicyImpact] = useState(false);
  const [impactTable, setImpactTable] = useState([]);
  const [impactPolicyName, setImpactPolicyName] = useState("");
  const [riskLevel, setRiskLevel] = useState();
  const [decisionStatus, setDecisionStatus] = useState();

  const [DITFilters, setDITFilters] = useState({});
  const [walletUsers, setWalletUsers] = useState([]);

  // Pagination
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: 10,
    page: 0,
  });

  // SINGLE useEffect for data fetching (no extra calls on mount)

  useEffect(() => {
    fetchPolicyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters, debouncedValue, currentPage, DITFilters]);

  useEffect(() => {
    fetchWalletUsers();
  }, []);

  const fetchWalletUsers = async () => {
    try {
      let payload = {
        orgId: ORG_ID(),
        domainId: DOMAIN_ID(),
        pageNumber: 1,
        pageSize: 100,
        search: "",
        filter: "",
      };
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/walletService/walletUserList`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      const tempData = res?.data?.users?.map((user, index) => {
        return {
          id: index + 1,
          value: user.emailAddress,
          label: user.emailAddress,
        };
      });
      setWalletUsers(tempData || []);
    } catch (error) {
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  async function fetchPolicyData() {
    const sanitizedDIT = cleanDITFilters(DITFilters);

    const payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      requestId: "",
      page: currentPage + 1,
      limit: currentPageModel.pageSize,
      search: debouncedValue || "",
      DITFilters: sanitizedDIT,
      ...(Object.keys(appliedFilters).length > 0 && {
        filters: Object.entries(appliedFilters).map(([key, value]) => ({
          filterParameter: key,
          filterValue: value,
        })),
      }),
    };

    setIsLoading(true);
    try {
      // Example: call the real endpoint
      const res = await axios.post(`${REACT_APP_API}/api/v1/policyService/ListPolicy`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });

      let fetchedPolicies = res?.data?.data || [];
      let total = res?.data?.total_pages || 0;

      setPolicies(fetchedPolicies);
      setTotalCount(total);
      setCurrentPageModel({
        pageSize: 10,
        page: currentPage,
      });
    } catch (error) {
      notification.open({
        type: "error",
        content: "Failed to load policies",
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const ADColumnsData = [
    {
      headerName: "AD Users",
      field: "adUsers",
      flex: 2,
      renderCell: (params) => {
        const adUsers = params.row.policyJson?.ad?.users || [];
        if (!adUsers.length) return <span>N/A</span>;
        return adUsers.map((user, index) => <Tag key={index}>{user}</Tag>);
      },
    },
  ];

  // RADIUS
  const RadiusColumnsData = [
    {
      headerName: "RADIUS Endpoints",
      field: "radiusEndpoints",
      flex: 1,
      renderCell: (params) => {
        const endpoints = params.row.policyJson?.networks?.endpoints || [];
        if (!endpoints.length) return <span>N/A</span>;
        return endpoints.map((ep, idx) => <Tag key={idx}>{ep}</Tag>);
      },
    },
  ];

  // Endpoints
  const EndpointsColumnsData = [
    {
      headerName: "Endpoints Groups",
      field: "endpointGroups",
      flex: 2,
      renderCell: (params) => {
        const groupsObj = params.row.policyJson?.endpoints?.groups || {};
        const groupKeys = Object.keys(groupsObj);
        if (!groupKeys.length) return <span>N/A</span>;
        return groupKeys.map((group, index) => <Tag key={index}>{group}</Tag>);
      },
    },
    {
      headerName: "Endpoints",
      field: "endpoints",
      flex: 2,
      renderCell: (params) => {
        const groupsObj = params.row.policyJson?.endpoints?.groups || {};
        const endpoints = Object.values(groupsObj).flat();
        if (!endpoints.length) return <span>N/A</span>;
        return endpoints.map((endpoint, index) => (
          <Tag color="blue" key={index}>
            {endpoint}
          </Tag>
        ));
      },
    },
    {
      headerName: "Endpoints Users",
      field: "endpointUsers",
      flex: 2,
      renderCell: (params) => {
        const endpointUsers = params.row.policyJson?.endpoints?.users || [];
        if (!endpointUsers.length) return <span>N/A</span>;
        return endpointUsers.map((u, idx) => <Tag key={idx}>{u}</Tag>);
      },
    },
  ];

  // ServiceAccount
  const ServiceColumnsData = [
    {
      headerName: "Source Endpoint",
      field: "serviceEndpoints",
      flex: 2,
      renderCell: (params) => {
        const source = params.row.policyJson?.serviceaccount?.source_endpoint || [];
        if (!source.length) return <span>N/A</span>;
        return source.map((s, idx) => <Tag key={idx}>{s}</Tag>);
      },
    },
    {
      headerName: "Destination Endpoint",
      field: "destinationEndpoints",
      flex: 2,
      renderCell: (params) => {
        const dest = params.row.policyJson?.serviceaccount?.destination_endpoint || [];
        if (!dest.length) return <span>N/A</span>;
        return dest.map((d, idx) => <Tag key={idx}>{d}</Tag>);
      },
    },
    {
      headerName: "Service Account Users",
      field: "serviceAccountUsers",
      flex: 2,
      renderCell: (params) => {
        const svcUsers = params.row.policyJson?.serviceaccount?.users || [];
        if (!svcUsers.length) return <span>N/A</span>;
        return svcUsers.map((u, idx) => <Tag key={idx}>{u}</Tag>);
      },
    },
  ];

  // AD Groups
  const GroupADColumnsData = [
    {
      headerName: "AD Group(s)",
      field: "groupADGroups",
      flex: 2,
      renderCell: (params) => {
        const adGroups = params.row.policyJson?.infra?.ad?.ad_groups || [];
        if (!adGroups.length) {
          return <span>N/A</span>;
        }
        return adGroups.map((grp, idx) => <Tag key={idx}>{grp}</Tag>);
      },
    },
  ];

  // Database
  const DatabaseColumnsData = [
    {
      headerName: "Database Name",
      field: "databaseName",
      flex: 1.5,
      renderCell: (params) => {
        const dbName = params.row.policyJson?.database?.database_name;
        return dbName ? <span>{dbName}</span> : <span>N/A</span>;
      },
    },
    {
      headerName: "Tables",
      field: "databaseTables",
      flex: 2,
      renderCell: (params) => {
        const tables = params.row.policyJson?.database?.tables || [];
        if (!tables.length) return <span>N/A</span>;
        return tables.map((table, idx) => <Tag key={idx}>{table}</Tag>);
      },
    },
    {
      headerName: "Masked fields",
      field: "fieldMasking",
      flex: 2,
      renderCell: (params) => {
        const fieldMasking = params.row.policyJson?.database?.field_masking || {};
        const tableKeys = Object.keys(fieldMasking);
        if (!tableKeys.length) {
          return <span>N/A</span>;
        }
        return tableKeys.map((tableKey, idx) => {
          const fields = fieldMasking[tableKey];
          return (
            <Tag key={idx}>
              {tableKey}: ({fields.join(", ")})
            </Tag>
          );
        });
      },
    },
    {
      headerName: "Database User",
      field: "dbUser",
      flex: 1,
      renderCell: (params) => {
        const dbUser = params.row.policyJson?.database?.user;
        if (!dbUser) return <span>N/A</span>;
        return <Tag>{dbUser}</Tag>;
      },
    },
    {
      headerName: "Privilege",
      field: "privilege",
      flex: 1,
      renderCell: (params) => {
        const privilege = params.row.policyJson?.database?.privilege || [];
        if (!privilege.length) return <span>N/A</span>;
        return privilege.map((p, idx) => (
          <Tag color="blue" key={idx}>
            {p}
          </Tag>
        ));
      },
    },
  ];

  // Combine all columns
  const columns = [
    {
      headerName: "",
      field: "updatePolicy",
      flex: 0.2,
      renderCell: (val) => (
        <Link
          disabled={val.row.status !== "Approved"}
          to={val.row.status === "Approved" ? "/pam/policy/v2/createPolicy?edit=true" : undefined}
          onClick={() => {
            if (val.row.status === "Approved") {
              localStorage.setItem("editPolicyData", JSON.stringify(val.row));
              localStorage.setItem("storedDataId", val.row.id);
            }
          }}
        >
          <EditOutlined />
        </Link>
      ),
    },
    {
      headerName: "Policy Name",
      field: "policyName",
      flex: 1.5,
      renderCell: (val) => {
        const name = val?.row?.policyName;
        return name ? (
          <a
            onClick={() => {
              setViewPolicyDrawer(true);
              setPolicyJsonData(val?.row?.policyJson);
            }}
          >
            {name}
          </a>
        ) : (
          <span>N/A</span>
        );
      },
    },
    {
      headerName: "Type",
      field: "policyType",
      flex: 1,
      renderCell: (val) => {
        const type = val?.row?.policyType;
        if (!type) return <Tag>N/A</Tag>;
        let displayType = type;
        if (type === "radius") displayType = "RADIUS";
        else if (type === "GroupAD") displayType = "AD Groups";
        else if (type === "ServiceAccount") displayType = "Service Account";
        else if (type === "database") displayType = "Database";
        return <Tag>{displayType}</Tag>;
      },
    },
    {
      headerName: "IAM Users",
      field: "iamUsers",
      flex: 2.3,
      filterable: false,
      renderCell: (params) => {
        const iamUsers = params.row.policyJson?.permissions?.iam_users || [];
        if (!iamUsers.length) {
          return <span>N/A</span>;
        }
        return iamUsers.map((user, index) => (
          <Tag
            key={index}
            onClick={(val) => applyFilter("iamUser", [user])}
            style={{ cursor: "pointer" }}
          >
            {user}
          </Tag>
        ));
      },
    },
    // Conditionally add columns based on policyType
    ...(selectedPolicyType === "AD"
      ? ADColumnsData
      : selectedPolicyType === "GroupAD"
      ? GroupADColumnsData
      : selectedPolicyType === "radius"
      ? RadiusColumnsData
      : selectedPolicyType === "ServiceAccount"
      ? ServiceColumnsData
      : selectedPolicyType === "endpoints"
      ? EndpointsColumnsData
      : selectedPolicyType === "database"
      ? DatabaseColumnsData
      : []),
    {
      headerName: "Policy Action",
      field: "allowed",
      flex: 1.2,
      renderCell: (params) => {
        const allowed = params.row.policyJson?.permissions?.allowed;
        return <Tag color={allowed ? "green" : "red"}>{allowed ? "Allowed" : "Not Allowed"}</Tag>;
      },
    },
    {
      headerName: (
        <div>
          <span style={{ marginRight: ".5rem" }}>Status</span>
          <Tooltip title="Status of the policy can be pending, Active, Inactive, or Deprecated">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      field: "status",
      flex: 1,
      renderCell: (val) => {
        const status = val?.row?.status;
        let color = "default";
        if (status === "Approved") color = "green";
        else if (status === "Pending") color = "orange";
        else if (status === "Revoked") color = "red";
        else if (status === "Deprecated") color = "gray";
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      headerName: "Updated At",
      field: "updatedAt",
      flex: 2,
      // renderCell: (val) => {
      //   const updatedAt = val?.row?.updatedAt;
      //   if (!updatedAt) return <span>N/A</span>;
      //   return moment(updatedAt).tz("America/Los_Angeles").format("DD-MM-YYYY HH:mm:ss [PST]");
      // },
      renderCell: (val) => {
        const updatedAt = val?.row?.updatedAt;
        const timezone = localStorage.getItem("timezone");
        return formatTimeByTimezone(updatedAt, timezone);
      },
    },
    {
      headerName: "Action",
      field: "action",
      flex: 1,
      renderCell: (val) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">
                <a
                  onClick={() => {
                    setViewPolicyDrawer(true);
                    setPolicyJsonData(val?.row?.policyJson);
                  }}
                >
                  Conditional Access
                </a>
              </Menu.Item>
              <Menu.Item key="2">
                <a
                  onClick={() => {
                    setOpenApprovePolicyModal(true);
                    setSelectedPolicyRow(val.row);
                  }}
                >
                  {val?.row?.status === "Approved" ? "Revoke" : "Approve"}
                </a>
              </Menu.Item>
              <Menu.Item key="3">
                <a onClick={() => {}}>History</a>
              </Menu.Item>
              <Menu.Item key="4">
                <a
                  onClick={() => {
                    setSelectedPolicyRow(val.row);
                    setOpenDeletePolicyModal(true);
                  }}
                >
                  Delete
                </a>
              </Menu.Item>
              {val?.row?.generatedBy === "AI" && (
                <Menu.Item key="5">
                  <a onClick={() => handleShowPolicyImpact(val?.row?.id, val?.row?.policyName)}>
                    Show Impact
                  </a>
                </Menu.Item>
              )}
            </Menu>
          }
          placement="bottomLeft"
        >
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ].filter(Boolean);

  const handleModalClose = () => {
    setSelectedPolicyRow(null);
    setOpenApprovePolicyModal(false);
  };

  const handlePolicyApproval = () => {
    fetchPolicyData();
  };

  function applyFilter(type, value) {
    setAppliedFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
    setCurrentPage(0);
  }

  function getAppliedFilterValue(type) {
    return appliedFilters[type] ?? null;
  }

  // -------------------------------------------
  //  Row selection
  // -------------------------------------------
  function handleRowSelectionModelChange(newRowSelectionModel) {
    setRowSelectionModel(newRowSelectionModel);
  }

  // For multi-policy actions
  async function handleMultiplePolicyAction(policyActionType, policyAction) {
    const payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      action: {
        actionType: policyActionType,
        actionIds: rowSelectionModel,
        actionValue: policyAction,
      },
    };
    setIsLoading(true);
    try {
      await axios.post(`${REACT_APP_API}/api/v1/policyService/PolicyAction`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      notification.open({
        type: "success",
        content: `Policies ${
          policyAction === "Deleted"
            ? "Deleted"
            : policyAction === "Allow"
            ? "Action Allowed"
            : policyAction === "Deny"
            ? "Action Denied"
            : policyAction === "Approved"
            ? "Status Approved"
            : "Status Deactivated"
        } Successfully.`,
      });
      setIsLoading(false);
      fetchPolicyData();
    } catch (error) {
      setIsLoading(false);
      notification.open({
        type: "error",
        content: "Failed to perform policy action.",
      });
      console.error(error);
    }
  }

  // Function to remove a specific DITFilter based on the key
  // Helpers to remove filters
  const removeDITFilter = (filterKey) => {
    const keys = filterKey.split(".");
    setDITFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      let current = updatedFilters;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) return updatedFilters;
        current = current[keys[i]];
      }
      const lastKey = keys[keys.length - 1].split("[")[0];
      delete current[lastKey];
      return updatedFilters;
    });
  };

  const removeDITFilterArrayElement = (filterKey) => {
    const arrayRegex = /(.+)\[(\d+)\]\.(.+)/;
    const match = filterKey.match(arrayRegex);
    if (match) {
      const [, arrayKey, index, subKey] = match;
      setDITFilters((prevFilters) => {
        const updatedFilters = { ...prevFilters };
        if (
          updatedFilters[arrayKey] &&
          Array.isArray(updatedFilters[arrayKey]) &&
          updatedFilters[arrayKey][index]
        ) {
          delete updatedFilters[arrayKey][index][subKey];
          if (
            updatedFilters[arrayKey][index] &&
            Object.keys(updatedFilters[arrayKey][index]).length === 0
          ) {
            updatedFilters[arrayKey].splice(index, 1);
          }
        }
        return updatedFilters;
      });
    }
  };

  const flattenedDITFilters = flattenMasterDITFilters(cleanDITFilters(DITFilters));

  // Combine normal filters + DIT filters
  const combinedFilters = [
    ...Object.entries(appliedFilters)
      .filter(([key]) => key !== "policyType" && key !== "generatedBy")
      .flatMap(([key, value]) => {
        const displayKey = key === "iamUser" ? "IAM User" : capitalize(key);
        if (Array.isArray(value)) {
          // Create a tag for each value in the array
          return value.map((v) => ({
            key: `${key}-${v}`,
            display: `${displayKey}: ${v}`,
            type: "applied",
          }));
        }
        return {
          key,
          display: `${displayKey}: ${capitalize(value)}`,
          type: "applied",
        };
      }),
    ...(appliedFilters.policyType
      ? [
          {
            key: "policyType",
            display: `Policy Type: ${
              appliedFilters.policyType === "AD"
                ? "AD"
                : appliedFilters.policyType === "radius"
                ? "RADIUS"
                : appliedFilters.policyType === "GroupAD"
                ? "AD Groups"
                : appliedFilters.policyType === "ServiceAccount"
                ? "Service Account"
                : appliedFilters.policyType === "database"
                ? "Database"
                : capitalize(appliedFilters.policyType)
            }`,
            type: "applied",
          },
        ]
      : []),
    ...(appliedFilters.generatedBy !== undefined
      ? [
          {
            key: "generatedBy",
            display: `Generated By: ${appliedFilters.generatedBy || "All"}`,
            type: "applied",
          },
        ]
      : []),
    ...flattenedDITFilters.map(({ key, display }) => ({
      key,
      display,
      type: "DIT",
    })),
  ];

  function handleClearFilters() {
    // Keep the default policyType if you want
    setAppliedFilters({ policyType: appliedFilters.policyType });
    setDITFilters({});
    setSearch("");
  }

  // function to show policy impact
  const handleShowPolicyImpact = async (policyId, policyName) => {
    try {
      setIsLoading(true);
      let payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        policyId: policyId,
      };
      const res = await axios.post(`${REACT_APP_AI_API_URL}/api/v1/ai/impact-analysis`, payload);
      console.log(res.data);
      setImpactTable(res?.data?.impact?.impactTable ?? []);
      setImpactPolicyName(policyName);
      setRiskLevel(res?.data?.impact?.riskLevel);
      setDecisionStatus(res?.data?.decision?.decisionStatus);
      setShowPolicyImpact(true);
      setIsLoading(false);
      // return res.data ?? null;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return null;
    }
  };

  return (
    <>
      {notificationHolder}
      <Row className="content-container" style={{ padding: "1rem" }}>
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              {showPolicyImpact ? (
                <h2 className="title">
                  Policy Impact - <span style={{ color: "blue" }}>{impactPolicyName}</span>{" "}
                </h2>
              ) : (
                <h2 className="title">Policies</h2>
              )}
            </Col>
            <Col>
              <Row gutter={16}>
                <Col>
                  <AIAgent
                    fetchPolicyData={fetchPolicyData}
                    applyFilter={applyFilter}
                    setShowPolicyImpact={setShowPolicyImpact}
                    setImpactTable={setImpactTable}
                    setImpactPolicyName={setImpactPolicyName}
                  />
                </Col>
                <Col>
                  <Link to={"/pam/policy/v2/createPolicy"}>
                    <Button type="primary">Add Policy</Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>

          {showPolicyImpact ? (
            <Row>
              <PolicyImpact
                showPolicyImpact={showPolicyImpact}
                setShowPolicyImpact={setShowPolicyImpact}
                impactTable={impactTable}
                riskLevel={riskLevel}
                decisionStatus={decisionStatus}
              />
            </Row>
          ) : (
            <>
              {rowSelectionModel.length === 0 ? (
                <>
                  <Row style={{ marginBottom: "1rem", marginTop: "1rem" }} gutter={20}>
                    <Col span={4}>
                      <p className="search-label">Search</p>
                      <Input
                        type="text"
                        placeholder="Search policy"
                        onChange={(e) => setSearch(e.target.value)}
                        allowClear
                        value={search}
                      />
                    </Col>
                    <Col span={4}>
                      <p className="search-label">Policy Type</p>
                      <Select
                        placeholder="Select policy type"
                        onChange={(val) => {
                          applyFilter("policyType", val);
                          setSelectedPolicyType(val);
                        }}
                        value={getAppliedFilterValue("policyType")} // always has a default
                      >
                        {policyTypesOptions.map((type) => (
                          <Select.Option key={type.value} value={type.value}>
                            {type.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>

                    <Col span={4}>
                      <p className="search-label">IAM Users</p>
                      <Select
                        mode="multiple"
                        placeholder="Select users"
                        onChange={(val) => applyFilter("iamUser", val)}
                        value={getAppliedFilterValue("iamUser")}
                        allowClear
                      >
                        {walletUsers.map((user) => (
                          <Select.Option key={user.id} value={user.value}>
                            {user.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>

                    <Col span={4}>
                      <p className="search-label">Status</p>
                      <Select
                        placeholder="Select status"
                        onChange={(val) => applyFilter("status", val)}
                        value={getAppliedFilterValue("status")}
                        allowClear
                      >
                        {statusOptions.map((status) => (
                          <Select.Option key={status.value} value={status.value}>
                            {status.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>

                    <Col span={4}>
                      <p className="search-label">Generated by</p>
                      <Select
                        placeholder="Select generated by"
                        onChange={(val) => applyFilter("generatedBy", val)}
                        value={getAppliedFilterValue("generatedBy")}
                        allowClear
                      >
                        <Select.Option value="Auto">Auto</Select.Option>
                        <Select.Option value="AI">AI</Select.Option>
                        <Select.Option value="Manual">Manual</Select.Option>
                      </Select>
                    </Col>

                    <Col>
                      <p className="search-label" style={{ marginTop: "35px" }}></p>
                      <AdvancedFilters onSetFilters={setDITFilters} />
                    </Col>
                  </Row>

                  {/* Applied filters */}
                  {combinedFilters.length > 0 && (
                    <Row style={{ marginBottom: "2rem" }}>
                      <Col>
                        <p className="mb-2">
                          Applied Filters:{" "}
                          <Link
                            href="#"
                            onClick={handleClearFilters}
                            style={{ marginLeft: "1rem", color: "#1890ff" }}
                          >
                            Clear filters
                          </Link>
                        </p>
                        {combinedFilters.map(({ key, display, type }) => (
                          <Tag
                            style={{ marginBottom: ".25rem" }}
                            key={key}
                            // policyType is mandatory => not closable
                            closable={key !== "policyType"}
                            onClose={() => {
                              if (type === "applied") {
                                setAppliedFilters((prevFilters) => {
                                  const temp = { ...prevFilters };
                              
                                  // Handle multi-value filters like iamUser
                                  const [filterType, value] = key.split("-");
                              
                                  if (Array.isArray(temp[filterType])) {
                                    const newArr = temp[filterType].filter((item) => item !== value);
                                    if (newArr.length === 0) {
                                      delete temp[filterType];
                                    } else {
                                      temp[filterType] = newArr;
                                    }
                                  } else {
                                    delete temp[filterType];
                                  }
                              
                                  return temp;
                                });
                              } else if (type === "DIT") {
                                if (key.includes("[")) {
                                  removeDITFilterArrayElement(key);
                                } else {
                                  removeDITFilter(key);
                                }
                              }
                            }}
                          >
                            {display}
                          </Tag>
                        ))}
                      </Col>
                    </Row>
                  )}
                </>
              ) : (
                // If row selection is non-empty, show multi-action
                <>
                  <Row style={{ marginBottom: "2rem" }} gutter={24} align="middle" justify="start">
                    <Col span={4}>
                      <p className="search-label">Change Status</p>
                      <Select
                        style={{ width: 170 }}
                        placeholder="Select Status"
                        onSelect={(value) => {
                          if (value === "activate") {
                            handleMultiplePolicyAction("status", "Approved");
                          } else if (value === "deactivate") {
                            handleMultiplePolicyAction("status", "Suspended");
                          } else if (value === "delete") {
                            handleMultiplePolicyAction("status", "Deleted");
                          }
                        }}
                      >
                        <Select.Option value="activate">Approve</Select.Option>
                        <Select.Option value="deactivate">Deactivate</Select.Option>
                        <Select.Option value="delete">Delete</Select.Option>
                      </Select>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "2rem" }}>
                    <Col>
                      <p className="mb-2">
                        Applied Filters:{" "}
                        <Link
                          href="#"
                          onClick={handleClearFilters}
                          style={{ marginLeft: "1rem", color: "#1890ff" }}
                        >
                          Clear filters
                        </Link>
                      </p>
                      {combinedFilters.map(({ key, display, type }) => (
                        <Tag
                          key={key}
                          closable={key !== "policyType"}
                          onClose={() => {
                            if (type === "applied") {
                              setAppliedFilters((prevFilters) => {
                                const temp = { ...prevFilters };
                            
                                // Handle multi-value filters like iamUser
                                const [filterType, value] = key.split("-");
                            
                                if (Array.isArray(temp[filterType])) {
                                  const newArr = temp[filterType].filter((item) => item !== value);
                                  if (newArr.length === 0) {
                                    delete temp[filterType];
                                  } else {
                                    temp[filterType] = newArr;
                                  }
                                } else {
                                  delete temp[filterType];
                                }
                            
                                return temp;
                              });
                            }
                            else if (type === "DIT") {
                              if (key.includes("[")) {
                                removeDITFilterArrayElement(key);
                              } else {
                                removeDITFilter(key);
                              }
                            }
                          }}
                        >
                          {display}
                        </Tag>
                      ))}
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <DataGrid
                  checkboxSelection
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={handleRowSelectionModelChange}
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                    noResultsOverlay: CustomNoRowsOverlay,
                  }}
                  rows={policies}
                  rowCount={totalCount}
                  columns={columns}
                  loading={isLoading}
                  page={currentPage}
                  paginationMode="server"
                  paginationModel={currentPageModel}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 10, page: 0 },
                    },
                  }}
                  onPaginationModelChange={(params) => {
                    setCurrentPage(params.page);
                  }}
                  style={{ border: "none", width: "100%", overflow: "auto" }}
                  sx={{
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": { display: "none" },
                  }}
                />
              </Row>
            </>
          )}
        </Col>
      </Row>

      {/* Drawer for conditional access JSON */}
      <Drawer
        title="Conditional Access"
        onClose={() => setViewPolicyDrawer(false)}
        open={viewPolicyDrawer}
        width={450}
      >
        <DitPolicyViewer data={policyJsonData} />
      </Drawer>

      <ApprovePolicyModal
        isModelOpen={openApprovePolicyModal}
        handleModalClose={handleModalClose}
        onPolicyApproved={handlePolicyApproval}
        selectedPolicyRow={selectedPolicyRow}
      />

      <DeletePolicyModal
        isModelOpen={openDeletePolicyModal}
        handleModalClose={() => setOpenDeletePolicyModal(false)}
        policyId={selectedPolicyRow?.id}
        onPolicyApproved={handlePolicyApproval}
      />
    </>
  );
}

// -------------------------------------------
//  ApprovePolicyModal
// -------------------------------------------
function ApprovePolicyModal({
  isModelOpen,
  handleModalClose,
  onPolicyApproved,
  selectedPolicyRow,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleApprove = async () => {
    setIsLoading(true);
    const payload = {
      policyId: selectedPolicyRow?.id,
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };
    axios
      .post(`${REACT_APP_API}/api/v1/policyService/approvePolicyJSON`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(() => {
        message.success("Policy approved successfully");
        onPolicyApproved();
        handleModalClose();
        setIsLoading(false);
      })
      .catch((error) => {
        message.error("Failed to approve policy");
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleRevoke = async () => {
    setIsLoading(true);
    const payload = {
      policyId: selectedPolicyRow?.id,
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };
    axios
      .post(`${REACT_APP_API}/api/v1/policyService/revokePolicyJSON`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(() => {
        message.success("Policy revoked successfully");
        onPolicyApproved();
        handleModalClose();
        setIsLoading(false);
      })
      .catch((error) => {
        message.error("Failed to revoke policy");
        console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <Modal
      open={isModelOpen}
      onCancel={handleModalClose}
      title={selectedPolicyRow?.status === "Approved" ? "Revoke Policy" : "Approve Policy"}
      okText={selectedPolicyRow?.status === "Approved" ? "Revoke" : "Approve"}
      onOk={selectedPolicyRow?.status === "Approved" ? handleRevoke : handleApprove}
      width={800}
      okButtonProps={{ loading: isLoading }}
    >
      <Row gutter={16} style={{ marginBottom: "1rem" }}>
        <Col span={12}>
          <p style={{ marginBottom: "0.3rem" }}>Policy Name</p>
          <Input value={selectedPolicyRow?.policyName || "N/A"} disabled />
        </Col>
        <Col span={12}>
          <p style={{ marginBottom: "0.3rem" }}>Policy Type</p>
          <Input value={selectedPolicyRow?.policyType || "N/A"} disabled />
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "1rem" }}>
        <Col span={12}>
          <p style={{ marginBottom: "0.3rem" }}>IAM Users</p>
          <Select
            value={selectedPolicyRow?.policyJson?.permissions?.iam_users || []}
            mode="multiple"
            disabled
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={12}>
          <p style={{ marginBottom: "0.3rem" }}>Status</p>
          <Input value={selectedPolicyRow?.status || "N/A"} disabled />
        </Col>
      </Row>

      <Col span={24}>
        <p style={{ marginBottom: "0.3rem" }}>Policy JSON</p>
        <JsonCellRenderer value={selectedPolicyRow?.policyJson || {}} />
      </Col>
    </Modal>
  );
}

const DeletePolicyModal = ({ isModelOpen, handleModalClose, onPolicyApproved, policyId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    const payload = {
      policyId,
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };

    axios
      .post(`${REACT_APP_API}/api/v1/policyService/deletePolicyJSON`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(() => {
        message.success("Policy deleted successfully");
        onPolicyApproved();
        handleModalClose();
        setIsLoading(false);
      })
      .catch((error) => {
        message.error("Failed to delete policy");
        console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <Modal
      open={isModelOpen}
      onCancel={handleModalClose}
      title="Delete Policy"
      okText="Delete"
      width={500}
      onOk={handleDelete}
      okButtonProps={{ loading: isLoading }}
    >
      <p>Are you sure you want to delete this policy?</p>
    </Modal>
  );
};
