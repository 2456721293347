import React, { useState, useEffect } from "react";
import { Col, Row, Table, Input, Tabs, Breadcrumb, Tag, Spin } from "antd";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { AUTH_TOKEN, REACT_APP_API_DID_URL, ORG_ID, DOMAIN_ID } from "../../../constants";
import { logOutUser } from "../../../common";
import axios from "axios";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { useDebounce } from "../../../common/debounce";
import moment from "moment-timezone";
import { formatTimeByTimezone } from "../../../common/formatTimebyTimezone";

const Context = React.createContext({
  name: "Default",
});
const { TabPane } = Tabs;

const EndpointUserDetails = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("0");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCredRecord, settotalCredRecord] = useState(1);
  const [CredData, setCredData] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { epmUsername, epmUserId, PublicIp, HostName } = location.state || {};
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const Storage = localStorage.getItem("details");
  const storedValue = JSON.parse(Storage);
  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationParams(pagination);

    if (pagination.pageSize !== paginationParams.pageSize) {
      setCredData([]);
    }
  };
  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    fetchVerifiableCredential();
  }, [debouncedValue, currentPage]);

  const fetchVerifiableCredential = (e) => {
    setIsLoading(true);
    let vcRequest = {
      epmUserId: Number(epmUserId ? epmUserId : storedValue.epmUserId),
      pageNumber: paginationParams.current,
      pageSize: paginationParams.pageSize,
      filter: search,
      orgId: ORG_ID(),
      domainId: DOMAIN_ID(),
    };

    axios
      .post(`${REACT_APP_API_DID_URL}/credential/getCredentialByEpmUser`, vcRequest, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        settotalCredRecord(res.data.count);
        if (res?.data?.data) {
          let credentialData = res.data.data.map((cred) => ({
            id: cred.id,
            createDate: cred.created_at,
            userName: cred.credential_name,
            issuer: cred.issuer_name,
            email_address: cred.email_address,
            schema: cred.user_source,
            status: cred.status,
          }));
          setIsLoading(false);

          setCredData(credentialData);
        }
      })

      .catch((err) => {
        if (err.response.data.message === "Invalid credentials") {
          logOutUser();
        } else if (err.response.status === 401) {
          logOutUser();
        }
      });
  };

  const tab2columns = [
    {
      title: "Credentials",
      dataIndex: "userName",
      key: "userName",
    },

    {
      title: "Wallet User",
      dataIndex: "email_address",
      key: "email_address",
      render: (email_address, record) => {
        return (
          <Link
            to={`/directory/did-walletUser`}
            state={{ email_address: record?.email_address }}
            onClick={() => {
              localStorage.removeItem("wallet");
              let values = {
                email_address: record?.email_address,
              };
              localStorage.setItem("wallet", JSON.stringify(values));
            }}
          >
            <Tag color="blue">{email_address}</Tag>
          </Link>
        );
      },
    },
    {
      title: "Schema",
      dataIndex: "schema",
      key: "schema",
    },
    {
      title: "Created Date",
      dataIndex: "createDate",
      key: "createDate",
      render: (val) => {
        const timestamp = val?.createDate;
        const timezone = localStorage.getItem("timezone");
        return formatTimeByTimezone(timestamp, timezone);
      },
    },
    {
      title: "Issuer",
      dataIndex: "issuer",
      key: "issuer",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row style={{ marginBottom: "1rem" }}>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Link to="/">
                      <HomeOutlined />
                    </Link>
                  ),
                },
                {
                  title: (
                    <>
                      <UserOutlined />
                      <Link onClick={() => navigate(-1)}>
                        <span>Endpoints Users</span>
                      </Link>
                    </>
                  ),
                },
                {
                  title: "Credentials",
                },
              ]}
            />
          </Row>

          <Row justify="space-between">
            <Col>
              <h2 className="title">Credentials</h2>
            </Col>
          </Row>
          <Row>
            <Col span={21}>
              <div>
                <span className="search-label">Endpoint:</span>
                <span style={{ marginLeft: "4px" }}>{`${
                  HostName ? HostName : storedValue.HostName
                }`}</span>
                <span style={{ marginLeft: "4px" }} className="search-label">
                  ({`${PublicIp ? PublicIp : storedValue.PublicIp}`})
                </span>
              </div>
              <div style={{ marginTop: "1rem" }}>
                <span className="search-label">Username:</span>
                <span style={{ marginLeft: "4px" }}>
                  {`${epmUsername ? epmUsername : storedValue.epmUsername}`}
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="tab-container">
                <Tabs activeKey={selectedTab}>
                  <TabPane style={{ width: "65rem" }} tab="Credentials" key="0">
                    <Col span={24}>
                      <Row
                        style={{ marginBottom: "2rem", gap: "2rem" }}
                        className="search-box-container"
                      >
                        <Col span={6}>
                          <p className="search-label">Search</p>
                          <div>
                            <Input
                              style={{ width: "100%" }}
                              placeholder="Search"
                              allowClear
                              onChange={(e) => {
                                setSearch(e.target.value);
                                if (paginationParams.current !== 1) {
                                  setPaginationParams({
                                    current: 1,
                                    pageSize: 10,
                                  });
                                }
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Table
                      rowKey="id"
                      dataSource={CredData}
                      columns={tab2columns}
                      pagination={{
                        showTotal: (total) => `Total ${total} items`,
                        ...paginationParams,
                        total: totalCredRecord,
                      }}
                      onChange={handleTableChange}
                      loading={isLoading}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EndpointUserDetails;
