import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Select,
  Space,
  Steps,
  Tooltip,
  Typography,
  message,
  Col,
  Divider,
} from "antd";
import {
  InfoCircleOutlined,
  DownloadOutlined,
  FileTextOutlined,
  ConsoleSqlOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import JSZip from "jszip";

const { Title, Text } = Typography;
const { Step } = Steps;

const OnboardLinuxDatabase = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [notification, contextHolder] = message.useMessage();

  // Only MySQL option for now.
  const [dbType, setDbType] = useState(null);
  const [agentKey, setAgentKey] = useState("");
  const [isZipLoading, setIsZipLoading] = useState(false);

  useEffect(() => {
    generateAgentKey();
  }, []);

  // Generate a UUID-like key.
  const generateAgentKey = () => {
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const sections = [8, 4, 4, 4, 12];
    sections.forEach((length) => {
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      result += "-";
    });
    result = result.slice(0, -1);
    setAgentKey(result);
  };

  // Return the raw URL for the Linux installation script and default DB port.
  // For now, only MySQL is available.
  const getLinuxScriptUrlAndPort = (type) => {
    if (type === "mysql") {
      return {
        scriptUrl:
          "https://raw.githubusercontent.com/authnull0/windows-endpoint/mysql-db-agent/agent/linux-build/run_agent.sh",
        dbPort: 3306,
      };
    }
    return { scriptUrl: "<script_url>", dbPort: "" };
  };

  // Build content for the db.env file.
  const buildEnvContent = () => {
    const orgId = localStorage.getItem("OrgId") || "105";
    const tenantId = localStorage.getItem("DomainId") || "1";
    const { dbPort } = getLinuxScriptUrlAndPort(dbType);
    return `ORG_ID=${orgId}
TENANT_ID=${tenantId}
DB_TYPE=${dbType}
DB_PORT=${dbPort}
TIME_INTERVAL=1
API=https://prod.api.authnull.com
KEY=${agentKey}`;
  };

  // Download only the db.env file.
  const downloadEnvFile = () => {
    if (!dbType) {
      notification.error({ content: "Please select a Database Type." });
      return;
    }
    const content = buildEnvContent();
    const blob = new Blob([content], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "db.env";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    notification.success({ content: "db.env downloaded successfully." });
  };

  // Define the steps for the Linux DB Agent onboarding process.
  const steps = [
    {
      title: "Select DB Type",
      icon: <ConsoleSqlOutlined />,
      content: (
        <Form form={form} layout="vertical" style={{ marginTop: "1rem" }}>
          <Form.Item
            label={
              <Space>
                <span>Database Type</span>
                <Tooltip title="Select the database type for this agent">
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              </Space>
            }
            name="dbType"
            rules={[{ required: true, message: "Please select a database type." }]}
          >
            <Select placeholder="Select Database Type" onChange={(value) => setDbType(value)}>
              <Select.Option value="mysql">MySQL</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Download Installation Script",
      icon: <DownloadOutlined />,
      content: (
        <>
          <Text>Run the following command to download the installation script:</Text>
          <Input
            style={{ border: "none", backgroundColor: "#fafafa", marginTop: "1rem" }}
            size="large"
            readOnly
            value={`$ sudo curl -L -o run_agent.sh ${getLinuxScriptUrlAndPort(dbType).scriptUrl}`}
            suffix={
              <Text
                copyable={{
                  text: `$ sudo curl -L -o run_agent.sh ${
                    getLinuxScriptUrlAndPort(dbType).scriptUrl
                  }`,
                  tooltips: ["Copy Command", "Copied"],
                }}
                style={{ color: "GrayText" }}
              />
            }
          />
        </>
      ),
    },
    {
      title: "Make Script Executable",
      icon: <FileTextOutlined />,
      content: (
        <>
          <Text>Make the script executable:</Text>
          <Input
            style={{ border: "none", backgroundColor: "#fafafa", marginTop: "1rem" }}
            size="large"
            readOnly
            value="$ sudo chmod +x run_agent.sh"
            suffix={
              <Text
                copyable={{
                  text: "$ sudo chmod +x run_agent.sh",
                  tooltips: ["Copy Command", "Copied"],
                }}
                style={{ color: "GrayText" }}
              />
            }
          />
        </>
      ),
    },
    {
      title: "Run the Script",
      icon: <FileTextOutlined />,
      content: (
        <>
          <Text>Run the script:</Text>
          <Input
            style={{ border: "none", backgroundColor: "#fafafa", marginTop: "1rem" }}
            size="large"
            readOnly
            value="$ sudo ./run_agent.sh"
            suffix={
              <Text
                copyable={{
                  text: "$ sudo ./run_agent.sh",
                  tooltips: ["Copy Command", "Copied"],
                }}
                style={{ color: "GrayText" }}
              />
            }
          />
        </>
      ),
    },
    {
      title: "Download db.env",
      icon: <DownloadOutlined />,
      content: (
        <>
          <Space style={{ marginTop: "1rem" }}>
            <Button type="primary" onClick={downloadEnvFile} icon={<DownloadOutlined />}>
              Download db.env
            </Button>
          </Space>
          <Divider style={{ margin: "1rem" }} />
          <Text type="secondary">The file contains your configuration details.</Text>
        </>
      ),
    },
    {
      title: "Check Logs",
      icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
      content: (
        <>
          <Text>Once the agent is installed, view logs with the command:</Text>
          <Input
            style={{ border: "none", backgroundColor: "#fafafa", marginTop: "1rem" }}
            size="large"
            readOnly
            value="$ tail -f /var/log/authnull-db-agent.log"
            suffix={
              <Text
                copyable={{
                  text: "$ tail -f /var/log/authnull-db-agent.log",
                  tooltips: ["Copy Command", "Copied"],
                }}
                style={{ color: "GrayText" }}
              />
            }
          />
        </>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <div style={{ width: "75%" }}>
        <h2 className="title">Linux DB Agent Setup</h2>
        <Steps direction="vertical" size="small" current={-1} style={{ marginTop: "50px" }}>
          {steps.map((item, index) => (
            <Step
              key={index}
              title={<Text strong>{item.title}</Text>}
              icon={item.icon}
              description={<div style={{ marginTop: "0.5rem" }}>{item.content}</div>}
            />
          ))}
        </Steps>
        <Row justify="end" style={{ marginTop: 25 }}>
          <Space size="middle">
            <Button type="primary" onClick={() => navigate("/pam/databases")}>
              Finish
            </Button>
          </Space>
        </Row>
      </div>
    </>
  );
};

export default OnboardLinuxDatabase;
