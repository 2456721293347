import { Col, Input, Row, Tag, message, Button, Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import { logOutUser } from "../../../common";
import {
  AUTH_TOKEN,
  REACT_APP_PAM_API,
  ORG_ID,
  DOMAIN_ID,
  REACT_APP_API_DID_URL,
} from "../../../constants";
import axios from "axios";
import moment from "moment-timezone";
import { useLocation, Link } from "react-router-dom";
import { MoreOutlined } from "@ant-design/icons";
import { useDebounce } from "../../../common/debounce";
import AssignEndpointUser from "../endpointsUsers/AssignEndpointUser";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const EndpointDetailsUser = ({ stateHostAddress }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [epmUser, setEpmUser] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hostAddress = queryParams.get("hostAddress");
  const isTabChange = queryParams.get("tab");
  // const isInstanceId = queryParams.get("instanceId");

  const [selectedRecords, setSelectedRecords] = useState([]);
  const [notification, setNotification] = message.useMessage();
  const [endpoint, setEndpoint] = useState([]);
  const [Isassignwallet, setIsassignwallet] = useState(false);
  const [sortModel, setSortModel] = useState();

  //pagination
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });

  const debouncedValue = useDebounce(search, 500);
  const { instanceId } = location.state || {};

  console.log(stateHostAddress.instanceId, "instanceId");

  useEffect(() => {
    fetchEPMUsers();
  }, [debouncedValue, currentPage, paginationParams]);

  const fetchEPMUsers = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      token: AUTH_TOKEN(),
      instanceIds: [Number(stateHostAddress?.instanceId || instanceId)],
      filter: {
        filterBy: "",
        value: "",
      },
      search: search,
      orgId: ORG_ID(),
    };
    setIsLoading(true);

    axios
      .post(`${REACT_APP_PAM_API}/endpointUsers/listAllEpmUsers`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);

        const originalEpmUsers = res?.data?.epmUsers;

        const epmUsersWithId = originalEpmUsers.map((user, index) => ({
          ...user,
          id: index + 1,
          countOffCredentials: 0,
          lastActive: null,
        }));
        setEpmUser(epmUsersWithId);
        setTotalCount(res?.data?.totalCount);
        setCurrentPageModel({
          pageSize: 10,
          page: currentPage,
        });

        // Assuming the parent API returns an array of items, each with an 'id'
        const ids = originalEpmUsers.map((item) => item.userId); // Extract ids from the response

        const uniqueInstanceIds = Array.from(
          new Set(originalEpmUsers.map((item) => item.epmMachineId))
        ); // Ensure uniqueness

        // Make child API calls for each ID
        const childApiCalls1 = ids.map((id) => fetchCredentialCount(id));
        const childApiCalls2 = uniqueInstanceIds.map((id) => fetchInstanceTime(id));

        Promise.all([Promise.all(childApiCalls1), Promise.all(childApiCalls2)])
          .then(([allChildResponses1, allChildResponses2]) => {
            // Update countOffCredentials based on first child API calls
            let updatedUsers = epmUsersWithId.map((user, index) => ({
              ...user,
              countOffCredentials: allChildResponses1[index]?.data?.count || 0,
            }));

            // Create a map of machineId to lastActive from the second child API responses
            const lastActiveMap = new Map(
              allChildResponses2.map((response) => [
                response.machineId,
                response.data?.lastActive || null,
              ])
            );
            // Update lastActive based on the map
            updatedUsers = updatedUsers.map((user) => {
              const lastActive = lastActiveMap.get(user.epmMachineId) || null;
              return {
                ...user,
                lastActive: lastActive,
              };
            });
            // Update the state with the final users data
            setEpmUser(updatedUsers);
            console.log(updatedUsers, "vv");
          })
          .catch((error) => {
            console.error("Error in child API calls:", error);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoint users",
          });
        }
      });
  };

  const fetchCredentialCount = (id) => {
    let vcRequest = {
      epmUserId: Number(id),
      orgId: ORG_ID(),
      domainId: DOMAIN_ID(),
    };

    return axios
      .post(`${REACT_APP_API_DID_URL}/credential/getCredentialCountByEpmUser`, vcRequest, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((childData) => {
        // Process the child data if needed
        return childData; // Return the child data to be used in Promise.all
      })
      .catch((error) => {
        console.error("Error in child API call for ID", id, ":", error);
      });
  };

  const fetchInstanceTime = (id) => {
    let timeRequest = {
      uuid: "",
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      endpointId: Number(id),
    };

    // Return the axios post promise
    return axios
      .post(`${REACT_APP_API_DID_URL}/getLastActiveOfEndpointMachine`, timeRequest, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((response) => {
        return {
          machineId: id,
          data: response.data,
        };
      })
      .catch((err) => {
        console.log("Error in child API call for instance time:", err);
      });
  };

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const EndpointStatus = (lastUpdated) => {
    const timezone = localStorage.getItem("timezone");
    const lastActive = moment.unix(lastUpdated).tz(timezone).format("YYYY-MM-DD HH:mm:ss");
    const diff = moment().diff(lastActive, "minutes");
    let hours = Math.floor(diff / 60);
    let days = Math.floor(hours / 24);
    let minutes = diff % 60;

    let message;

    if (days > 0) {
      message = `Last Active: ${days} days ago`;
    } else if (hours > 0) {
      message = `Last Active: ${hours} hours${
        minutes > 0 ? ` and ${minutes} minutes ago` : " hours ago"
      }`;
    } else {
      message = `Last Active: ${diff} minutes ago`;
    }

    if (diff > 20) {
      return true;
    } else if (diff > 10) {
      return false;
    }
    return false;
  };

  const tab1columns = [
    {
      headerName: "User Name",
      field: "userName",
      key: "userName",
      flex: 1,
    },
    {
      field: "epmMachineName",
      headerName: "Endpoints",
      key: "epmMachineName",
      flex: 1,
      renderCell: (val) => {
        const timezone = localStorage.getItem("timezone");
        const statusOfMachine = moment
          .unix(val?.row?.lastActive)
          .tz(timezone)
          .format("YYYY-MM-DD HH:mm:ss");
        const diff = moment().diff(statusOfMachine, "minutes");
        console.log(diff);

        return (
          <Tag
            color={
              val?.row?.lastActive === null
                ? "default"
                : diff > 20
                ? "red"
                : diff > 10
                ? "orange"
                : "green"
            }
            key={val?.row?.epmMachineName}
          >
            {val?.row?.epmMachineName}
          </Tag>
        );
      },
    },
    {
      headerName: "Credentials",
      field: "countOffCredentials",
      key: "countOffCredentials",
      flex: 1,
      renderCell: (val) => {
        const isDisabled = val?.row?.countOffCredentials === 0;
        return (
          <>
            {isDisabled ? (
              <span style={{ cursor: "not-allowed" }}>{val?.row?.countOffCredentials}</span>
            ) : (
              <Link
                to={`/pam/endpointuserDetails`}
                state={{
                  epmUsername: val?.row?.userName,
                  epmUserId: val?.row?.userId,
                  PublicIp: val?.row?.epmMachinePublicIp,
                  HostName: val?.row?.epmMachineName,
                }}
                onClick={() => {
                  localStorage.removeItem("details");
                  let values = {
                    epmUsername: val?.row?.userName,
                    epmUserId: val?.row?.userId,
                    PublicIp: val?.row?.epmMachinePublicIp,
                    HostName: val?.row?.epmMachineName,
                  };
                  localStorage.setItem("details", JSON.stringify(values));
                }}
              >
                {val?.row?.countOffCredentials}
              </Link>
            )}
          </>
        );
      },
    },
    {
      headerName: "User Type",
      field: "userType",
      key: "userType",
      flex: 1,
    },
    {
      headerName: "Action",
      key: "action",
      flex: 1,
      renderCell: (val) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              {
                key: "assign",
                label: (
                  <Link
                    state={{
                      PublicIp: val?.row?.epmMachinePublicIp,
                      HostName: val?.row?.epmMachineName,
                    }}
                    onClick={() => {
                      localStorage.removeItem("details");
                      let values = {
                        PublicIp: val?.row?.epmMachinePublicIp,
                        HostName: val?.row?.epmMachineName,
                      };
                      localStorage.setItem("details", JSON.stringify(values));
                      setSelectedRecords(val.row);
                      setIsassignwallet(true);
                    }}
                  >
                    Assign to Wallets
                  </Link>
                ),
              },
              {
                key: "view-session-recording",
                label: (
                  <Link to={`/pam/jumpServer-sessionRecordings?epmUser=${val.row.userName}`}>
                    View Session Recordings
                  </Link>
                ),
              },
              {
                key: "checkout-user",
                label: (
                  <Link
                    to={`/pam/endpointsUsers/checkoutUser`}
                    state={{
                      epmUserId: val.row.userId,
                      instanceName: val.row.epmMachineName,
                      instanceId: JSON.stringify(val?.row?.epmMachineId),
                      epmUsername: val?.row?.userName,
                      os: val?.row?.instances[0]?.os,
                    }}
                    onClick={() => {
                      let values = {
                        epmUserId: val.row.userId,
                        instanceName: val.row.epmMachineName,
                        instanceId: val.row.epmMachineId,
                        epmUsername: val?.row?.userName,
                        os: val?.row?.instances[0]?.os,
                      };
                      localStorage.setItem("epmUser", JSON.stringify(values));
                    }}
                  >
                    Checkout User
                  </Link>
                ),
                disabled: EndpointStatus(val?.row?.lastActive),
              },

              {
                key: "reset-credential",
                label: (
                  <Link
                    to={`/pam/endpointsUsers/reset-credentials?epmuser=${val.row.userName}&epmuserId=${val.row.userId}`}
                  >
                    Force Reset Credentials
                  </Link>
                ),
                danger: true,
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationParams(pagination);
    console.log(isTabChange);
    if (pagination.pageSize !== paginationParams.pageSize) {
      setEndpoint([]);
    }
    fetchEPMUsers();
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Row className="search-box-container">
                <Col span={6}>
                  <p className="search-label">Search</p>
                  <div>
                    <Input
                      placeholder="Search"
                      allowClear
                      onChange={(e) => {
                        setSearch(e.target.value);
                        if (paginationParams.current !== 1) {
                          setPaginationParams({ current: 1, pageSize: 10 });
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <DataGrid
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                style={{ border: "none" }}
                loading={isLoading}
                rows={epmUser}
                columns={tab1columns}
                paginationMode="server"
                rowCount={totalCount}
                page={currentPage}
                paginationModel={currentPageModel}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
                onPaginationModelChange={(params) => {
                  setCurrentPage(params.page);
                }}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {Isassignwallet && (
        <AssignEndpointUser
          Isassignwallet={Isassignwallet}
          setIsassignwallet={setIsassignwallet}
          selectedRecords={selectedRecords}
        />
      )}
    </>
  );
};

export default EndpointDetailsUser;
