import { DesktopOutlined, UserAddOutlined } from "@ant-design/icons";
import { Button, Col, Progress, Row, Select, Table } from "antd";
import React, { useEffect, useState } from "react";

const ImpactCard = ({ title, description, impactScore }) => {
  const getBorderTopColor = (impactScore) => {
    if (impactScore >= 61) {
      return "4px solid #5c2d35";
    } else if (impactScore >= 31) {
      return "4px solid #7f8541";
    } else {
      return "4px solid #417d3d";
    }
  };

  return (
    <Col
      span={11}
      style={{
        border: "1px solid #efefef",
        borderTop: getBorderTopColor(impactScore),
        padding: "15px",
        borderRadius: "8px",
        // backgroundColor: impactScore >= 61 ? "#fff2f2" : impactScore >= 31 ? "#f7f6f2" : "#f4f7f2",
        margin: "16px",
      }}
    >
      <Row justify="space-between">
        <h2 style={{ margin: "0px" }}>{title}</h2>

        <span
          style={{
            backgroundColor:
              impactScore >= 61 ? "#5c2d35" : impactScore >= 31 ? "#7f8541" : "#417d3d",
            color: "white",
            padding: "4px 10px",
            borderRadius: "8px",
          }}
        >
          Score: {impactScore / 10}
        </span>
      </Row>

      <div>
        <Row justify="space-between">
          <p style={{ marginBottom: "0px", fontWeight: "600" }}>Impact Score</p>
          <p style={{ marginBottom: "0px" }}>{impactScore / 10}/10</p>
        </Row>
        <Progress
          percent={impactScore}
          status="active"
          showInfo={false}
          strokeColor={impactScore >= 61 ? "#5c2d35" : impactScore >= 31 ? "#7f8541" : "#417d3d"}
        />
      </div>
      <p>{description}</p>
    </Col>
  );
};

export default function PolicyImpact({
  impactTable,
  setShowPolicyImpact,
  riskLevel,
  decisionStatus,
}) {
  const [policyImpactData, setPolicyImpactData] = useState(impactTable);

  const handleFilter = (val) => {
    let filteredData;

    switch (val) {
      case "High":
        filteredData = impactTable.filter((item) => item.score >= 7);
        break;
      case "Medium":
        filteredData = impactTable.filter((item) => item.score > 3 && item.score <= 6);
        break;
      case "Low":
        filteredData = impactTable.filter((item) => item.score <= 3);
        break;
      case "all":
        filteredData = impactTable;
        break;
      default:
        // Handle unexpected filter values (optional)
        filteredData = impactTable;
        break;
    }

    setPolicyImpactData(filteredData);
  };

  // if (policyImpactData.length === 0) {
  //   return (
  //     <Col span={24}>
  //       <Row
  //         style={{
  //           border: "1px solid #bababa",
  //           borderRadius: "8px",
  //           padding: "20px",
  //           marginBottom: "20px",
  //           backgroundColor: "#f1f0fc",
  //         }}
  //         justify="space-between"
  //       >
  //         <Col span={18}>
  //           <p style={{ margin: "0px", fontWeight: "700", fontSize: "16px" }}>
  //             No policy impact found.
  //           </p>
  //         </Col>
  //         <Col span={6}>
  //           <Button onClick={() => setShowPolicyImpact(false)}>Close Policy Impact</Button>
  //         </Col>
  //       </Row>
  //     </Col>
  //   );
  // }

  return (
    <Col span={24}>
      <Row justify="space-between" style={{ marginBottom: "30px" }}>
        <Col span={6}>
          <p className="search-label">Risk Level</p>
          <Select
            placeholder="Select Risk Level"
            onChange={(val) => {
              handleFilter(val);
            }}
          >
            <Select.Option value="all">All</Select.Option>
            <Select.Option value="High">High</Select.Option>
            <Select.Option value="Medium">Medium</Select.Option>
            <Select.Option value="Low">Low</Select.Option>
          </Select>
        </Col>
      </Row>

      {policyImpactData.length === 0 ? (
        <Col span={24}>
          <Row
            style={{
              border: "1px solid #bababa",
              borderRadius: "8px",
              padding: "20px",
              marginBottom: "20px",
              backgroundColor: "#f1f0fc",
            }}
            justify="space-between"
          >
            <Col span={18}>
              <p style={{ margin: "0px", fontWeight: "700", fontSize: "16px" }}>
                No policy impact found.
              </p>
            </Col>
            <Col span={6}>
              <Button
                onClick={() => setShowPolicyImpact(false)}
                style={{
                  width: "100%",
                }}
              >
                Close Policy Impact
              </Button>
            </Col>
          </Row>
        </Col>
      ) : (
        <Row
          style={{
            border: "1px solid #bababa",
            borderRadius: "8px",
            padding: "20px",
            marginBottom: "20px",
            backgroundColor: "#f1f0fc",
          }}
          justify="space-between"
        >
          <Col span={18}>
            <p style={{ margin: "0px", fontWeight: "700", fontSize: "16px" }}>
              Recommended Decision by the AI Agent
            </p>
            <div>
              <p>
                <span style={{ fontWeight: 600 }}>Risk Level:</span> {riskLevel}
              </p>
              <p>
                <span style={{ fontWeight: 600 }}>Decision Status:</span> {decisionStatus}
              </p>
            </div>
          </Col>
          <Col span={6}>
            {/* <Button
            type="primary"
            style={{
              border: "none",
              // borderRadius: "4px",
              padding: "8px 16px",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            Approve Policy
          </Button> */}
            <Button
              onClick={() => setShowPolicyImpact(false)}
              style={{
                // border: "none",
                // // borderRadius: "4px",
                // padding: "8px 16px",
                width: "100%",
              }}
            >
              Close Policy Impact
            </Button>
          </Col>
        </Row>
      )}

      <Row gutter={16}>
        {policyImpactData?.map((item, index) => (
          <ImpactCard
            key={index}
            title={item?.category}
            description={item?.reason}
            impactScore={item?.score * 10}
          />
        ))}
      </Row>
    </Col>
  );
}
