import React from "react";
import { Form, Select, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import countriesList from "../../ditConditions/countries";

const { Option } = Select;

const LocationAccessFields = ({ form, filters, setFilters }) => {
  return (
    <>
      <Form.Item name={["geolocation", "countries"]} label="Allowed Countries">
        <Select
          mode="multiple"
          placeholder="Select countries"
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          value={filters.geolocation?.countries || []}
          onChange={(value) => {
            setFilters({
              ...filters,
              geolocation: {
                ...filters.geolocation,
                countries: value,
              },
            });
          }}
        >
          {countriesList.map((country) => (
            <Option key={`${country.code}.ALL.ALL`} value={`${country.code}.ALL.ALL`}>
              {country.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default LocationAccessFields;
