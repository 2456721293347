import React, { useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import axios from "axios";
import { REACT_APP_API_TENANTS_URL } from "../../../constants";
import { Link, useLocation, useNavigate } from "react-router-dom";

export const TenantForgotPassword = () => {
  const [notification, setNotification] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const userToken = queryParams.get("token");
  const [currentURL, setCurrentURL] = useState("");

  useEffect(() => {
    const currentURL = localStorage.getItem("URL");
    setCurrentURL(currentURL);
  }, []);

  const handleFindUser = async (values) => {
    let payload = {
      email: values.email,
      url: `${currentURL}/tenant/forgot-password`,
    };
    try {
      setIsLoading(true);
      await axios.post(`${REACT_APP_API_TENANTS_URL}/checkUserandSendMail`, payload);
      notification.success("Password reset link sent to your email");
      setIsLoading(false);
    } catch (error) {
      console.log("error", error.response.data.message);
      notification.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  const handleUpdatePassword = async (values) => {
    let payload = {
      token: userToken,
      email: values.email,
      newpassword: values.newPassword,
      confirmpassword: values.confirmPassword,
      url: `${currentURL}/tenant/login`,
    };
    try {
      setIsLoading(true);
      await axios.post(`${REACT_APP_API_TENANTS_URL}/forgotPassword`, payload);
      notification.success("Password updated successfully");
      setIsLoading(false);
      setTimeout(() => {
        navigate("/tenant/login");
      }, "1500");
    } catch (error) {
      console.log("error", error.message);
      notification.error(error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      {setNotification}

      {userToken ? (
        <div className="login-container">
          <div className="login-box">
            <h2 className="title">Change Your Password</h2>
            <Form
              form={form}
              name="updatePassword"
              onFinish={handleUpdatePassword}
              className="login-form"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input placeholder="Email" size="large" />
              </Form.Item>

              <Form.Item
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder="New Password" size="large" />
              </Form.Item>

              <Form.Item
                name="confirmPassword"
                dependencies={["newPassword"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("The new password that you entered do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" size="large" />
              </Form.Item>

              <div className="login-form-button">
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={isLoading}
                  >
                    Change Password
                  </Button>
                  <br />
                </Form.Item>
                <span>Or</span> <br />
                <br />
                <Link to="/tenant/login">Back to Login</Link>
              </div>
            </Form>
          </div>
        </div>
      ) : (
        <div className="login-container">
          <div className="login-box">
            <h2 className="title">Forgot Your Password?</h2>
            <p style={{ color: "#494a49", fontSize: "145x", marginBottom: "20px" }}>
              Enter your email address, and we’ll send you a link to reset your password.
            </p>

            <Form
              form={form}
              name="forgotPassword"
              onFinish={handleFindUser}
              className="login-form"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input placeholder="Email" size="large" />
              </Form.Item>

              <div className="login-form-button">
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={isLoading}
                  >
                    Send Reset Link
                  </Button>
                  <br />
                </Form.Item>
                <span>Or</span> <br />
                <br />
                <Link to="/tenant/login">Back to Login</Link>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};
