import { CheckAuthnMethod } from "./index";
import { useNavigate } from "react-router-dom";

export const UseLogout = () => {
  const navigate = useNavigate();

  const logOutUser = () => {
    let CheckUrl = localStorage.getItem("URL");
    // localStorage.removeItem("token");
    navigate("/logout");
    if (CheckUrl === "app.authnull.com" || CheckUrl === "dev.authnull.com") {
      localStorage.clear();
      window.location.replace(`https://${CheckUrl}/tenant/login`);
    } else {
      CheckAuthnMethod();
    }
  };
  return logOutUser;
};
